import React, { useEffect } from 'react';
import { NavigationBarResponsive, WorkInProgressResponsive, BlogFooterResponsive }  from '../ui-components';

const BlogPage = () => {
    useEffect(() => {
        document.title = 'Blog - Liam McCormick';
    }, []);

    return (
        <div>
            <NavigationBarResponsive width={"100%"}/>
            <WorkInProgressResponsive width={"100%"}/>
            <BlogFooterResponsive width={"100%"}/>
        </div>
    );
}

export default BlogPage;