import React, { useEffect } from 'react';
import { NavigationBarResponsive, AchievementPresidentBidenResponsive, BidenFooterResponsive }  from '../ui-components';

const BidenPage = () => {
    useEffect(() => {
        document.title = 'President Biden - Liam McCormick';
    }, []);

    return (
        <div>
            <NavigationBarResponsive width={"100%"}/>
            <AchievementPresidentBidenResponsive width={"100%"} />
            <BidenFooterResponsive width={"100%"}/>
        </div>
    );
}

export default BidenPage;