/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
  useNavigateAction,
} from "@aws-amplify/ui-react/internal";
import { Flex, Image, Text, useBreakpointValue } from "@aws-amplify/ui-react";
export default function AchievementPresidentBidenResponsive(props) {
  const { overrides: overridesProp, ...restProp } = props;
  const variants = [
    {
      variantValues: { breakpoint: "xxl" },
      overrides: {
        "Invitation to President Biden\u2019s Address": {
          fontSize: "48px",
          lineHeight: "45px",
        },
        TitleFrame: { padding: "20px 240px 20px 240px" },
        image: { height: "913.3px" },
        "Frame 449": { padding: "90px 0px 90px 0px" },
        "It was an honour to have been invited to President Biden\u2019s speech in Belfast, which marked 25 years since the Good Friday Agreement was signed.":
          { fontSize: "16px", letterSpacing: "0.01px" },
        "The President placed an emphasis on the next generation of leaders in Northern Ireland and the valuable work that\u2019s being done in the push for further progress.":
          { fontSize: "16px", letterSpacing: "0.01px" },
        "Frame 458": {},
        "Frame 136": { width: "850px" },
        "biden-wide-shot": { width: "850px", height: "1275.22px" },
        "Frame 450": { padding: "90px 0px 90px 0px" },
        "\u201C": { fontSize: "64px", letterSpacing: "0.49px" },
        "And young people in Northern Ireland are on the cutting edge of sectors that are going to define so much of the future: cyber, technology, clean energy, life sciences.":
          { fontSize: "32px", lineHeight: "34px", letterSpacing: "0.17px" },
        "\u201D": { fontSize: "64px", letterSpacing: "0.49px" },
        "Frame 459": {},
        'The President also praised Northern Ireland\'s young people, saying they are at the "cutting edge" of its future - with a special focus on technology, cyber security and green energy.':
          { fontSize: "16px", letterSpacing: "0.01px" },
        "President Biden\u2019s message was one of tremendous hope and opportunity for the future. This was a very inspiring event to be apart of and I was delighted to be invited, as NI celebrates the progress that\u2019s been made and now looks ahead to the next 25 years.":
          { fontSize: "16px", letterSpacing: "0.01px" },
        "Frame 460": {},
        "Read more:": { fontSize: "24px", letterSpacing: "0.09px" },
        "BBC Article Link": { width: "850px", height: "592.5px" },
        "Frame 461": { gap: "32px", padding: "50px 0px 0px 0px" },
        "Frame 451": { width: "850px" },
        AchievementPresidentBidenResponsive: {
          width: "1536px",
          padding: "120px 0px 120px 0px",
        },
      },
    },
    {
      variantValues: { breakpoint: "xl" },
      overrides: {
        "Invitation to President Biden\u2019s Address": {
          fontSize: "48px",
          lineHeight: "45px",
        },
        TitleFrame: { padding: "20px 240px 20px 240px" },
        image: { height: "761.08px" },
        "Frame 449": { padding: "80px 0px 80px 0px" },
        "It was an honour to have been invited to President Biden\u2019s speech in Belfast, which marked 25 years since the Good Friday Agreement was signed.":
          { fontSize: "16px", letterSpacing: "0.01px" },
        "The President placed an emphasis on the next generation of leaders in Northern Ireland and the valuable work that\u2019s being done in the push for further progress.":
          { fontSize: "16px", letterSpacing: "0.01px" },
        "Frame 458": {},
        "Frame 136": { width: "800px" },
        "biden-wide-shot": { width: "800px", height: "1200.21px" },
        "Frame 450": { padding: "80px 0px 80px 0px" },
        "\u201C": { fontSize: "64px", letterSpacing: "0.49px" },
        "And young people in Northern Ireland are on the cutting edge of sectors that are going to define so much of the future: cyber, technology, clean energy, life sciences.":
          { fontSize: "32px", lineHeight: "34px", letterSpacing: "0.17px" },
        "\u201D": { fontSize: "64px", letterSpacing: "0.49px" },
        "Frame 459": {},
        'The President also praised Northern Ireland\'s young people, saying they are at the "cutting edge" of its future - with a special focus on technology, cyber security and green energy.':
          { fontSize: "16px", letterSpacing: "0.01px" },
        "President Biden\u2019s message was one of tremendous hope and opportunity for the future. This was a very inspiring event to be apart of and I was delighted to be invited, as NI celebrates the progress that\u2019s been made and now looks ahead to the next 25 years.":
          { fontSize: "16px", letterSpacing: "0.01px" },
        "Frame 460": {},
        "Read more:": { fontSize: "24px", letterSpacing: "0.09px" },
        "BBC Article Link": { width: "800px", height: "557.65px" },
        "Frame 461": { gap: "32px", padding: "50px 0px 0px 0px" },
        "Frame 451": { width: "800px" },
        AchievementPresidentBidenResponsive: {
          width: "1280px",
          padding: "120px 0px 120px 0px",
        },
      },
    },
    {
      variantValues: { breakpoint: "large" },
      overrides: {
        "Invitation to President Biden\u2019s Address": {
          fontSize: "40px",
          lineHeight: "40px",
        },
        TitleFrame: { padding: "20px 150px 20px 150px" },
        image: { height: "589.84px" },
        "Frame 449": { padding: "70px 0px 70px 0px" },
        "It was an honour to have been invited to President Biden\u2019s speech in Belfast, which marked 25 years since the Good Friday Agreement was signed.":
          { fontSize: "14px" },
        "The President placed an emphasis on the next generation of leaders in Northern Ireland and the valuable work that\u2019s being done in the push for further progress.":
          { fontSize: "14px" },
        "Frame 458": {},
        "Frame 136": { width: "650px" },
        "biden-wide-shot": { width: "650px", height: "975.17px" },
        "Frame 450": { padding: "70px 0px 70px 0px" },
        "\u201C": { fontSize: "48px", letterSpacing: "0.33px" },
        "And young people in Northern Ireland are on the cutting edge of sectors that are going to define so much of the future: cyber, technology, clean energy, life sciences.":
          { lineHeight: "28px" },
        "\u201D": { fontSize: "48px", letterSpacing: "0.33px" },
        "Frame 459": {},
        'The President also praised Northern Ireland\'s young people, saying they are at the "cutting edge" of its future - with a special focus on technology, cyber security and green energy.':
          { fontSize: "14px" },
        "President Biden\u2019s message was one of tremendous hope and opportunity for the future. This was a very inspiring event to be apart of and I was delighted to be invited, as NI celebrates the progress that\u2019s been made and now looks ahead to the next 25 years.":
          { fontSize: "14px" },
        "Frame 460": {},
        "Read more:": { fontSize: "24px", letterSpacing: "0.09px" },
        "BBC Article Link": { width: "650px", height: "453.09px" },
        "Frame 461": { gap: "32px", padding: "50px 0px 0px 0px" },
        "Frame 451": { width: "650px" },
        AchievementPresidentBidenResponsive: {
          width: "992px",
          padding: "100px 0px 100px 0px",
        },
      },
    },
    {
      variantValues: { breakpoint: "medium" },
      overrides: {
        "Invitation to President Biden\u2019s Address": { fontSize: "36px" },
        TitleFrame: { padding: "20px 100px 20px 100px" },
        image: { height: "456.65px" },
        "Frame 449": {},
        "It was an honour to have been invited to President Biden\u2019s speech in Belfast, which marked 25 years since the Good Friday Agreement was signed.":
          { fontSize: "14px" },
        "The President placed an emphasis on the next generation of leaders in Northern Ireland and the valuable work that\u2019s being done in the push for further progress.":
          { fontSize: "14px" },
        "Frame 458": {},
        "Frame 136": { width: "520px" },
        "biden-wide-shot": { width: "520px", height: "780.13px" },
        "Frame 450": {},
        "\u201C": { fontSize: "48px", letterSpacing: "0.33px" },
        "And young people in Northern Ireland are on the cutting edge of sectors that are going to define so much of the future: cyber, technology, clean energy, life sciences.":
          { lineHeight: "26px" },
        "\u201D": { fontSize: "48px", letterSpacing: "0.33px" },
        "Frame 459": {},
        'The President also praised Northern Ireland\'s young people, saying they are at the "cutting edge" of its future - with a special focus on technology, cyber security and green energy.':
          { fontSize: "14px" },
        "President Biden\u2019s message was one of tremendous hope and opportunity for the future. This was a very inspiring event to be apart of and I was delighted to be invited, as NI celebrates the progress that\u2019s been made and now looks ahead to the next 25 years.":
          { fontSize: "14px" },
        "Frame 460": {},
        "Read more:": { fontSize: "24px", letterSpacing: "0.09px" },
        "BBC Article Link": { width: "520px", height: "362.47px" },
        "Frame 461": { gap: "24px", padding: "50px 0px 0px 0px" },
        "Frame 451": { width: "520px" },
        AchievementPresidentBidenResponsive: { width: "768px" },
      },
    },
    {
      variantValues: { breakpoint: "small" },
      overrides: {
        "Invitation to President Biden\u2019s Address": { fontSize: "36px" },
        TitleFrame: { padding: "20px 50px 20px 50px" },
        image: { height: "285.41px" },
        "Frame 449": {},
        "It was an honour to have been invited to President Biden\u2019s speech in Belfast, which marked 25 years since the Good Friday Agreement was signed.":
          {},
        "The President placed an emphasis on the next generation of leaders in Northern Ireland and the valuable work that\u2019s being done in the push for further progress.":
          {},
        "Frame 458": {},
        "Frame 136": { width: "380px" },
        "biden-wide-shot": { width: "380px", height: "570.1px" },
        "Frame 450": {},
        "\u201C": {},
        "And young people in Northern Ireland are on the cutting edge of sectors that are going to define so much of the future: cyber, technology, clean energy, life sciences.":
          { lineHeight: "26px" },
        "\u201D": {},
        "Frame 459": {},
        'The President also praised Northern Ireland\'s young people, saying they are at the "cutting edge" of its future - with a special focus on technology, cyber security and green energy.':
          {},
        "President Biden\u2019s message was one of tremendous hope and opportunity for the future. This was a very inspiring event to be apart of and I was delighted to be invited, as NI celebrates the progress that\u2019s been made and now looks ahead to the next 25 years.":
          {},
        "Frame 460": {},
        "Read more:": { fontSize: "20px", letterSpacing: "0.05px" },
        "BBC Article Link": { width: "380px", height: "264.88px" },
        "Frame 461": { gap: "24px" },
        "Frame 451": { width: "380px" },
        AchievementPresidentBidenResponsive: { width: "480px" },
      },
    },
    {
      variantValues: { breakpoint: "base" },
      overrides: {
        "Invitation to President Biden\u2019s Address": {},
        TitleFrame: {},
        image: {},
        "Frame 449": {},
        "It was an honour to have been invited to President Biden\u2019s speech in Belfast, which marked 25 years since the Good Friday Agreement was signed.":
          {},
        "The President placed an emphasis on the next generation of leaders in Northern Ireland and the valuable work that\u2019s being done in the push for further progress.":
          {},
        "Frame 458": {},
        "Frame 136": {},
        "biden-wide-shot": {},
        "Frame 450": {},
        "\u201C": {},
        "And young people in Northern Ireland are on the cutting edge of sectors that are going to define so much of the future: cyber, technology, clean energy, life sciences.":
          {},
        "\u201D": {},
        "Frame 459": {},
        'The President also praised Northern Ireland\'s young people, saying they are at the "cutting edge" of its future - with a special focus on technology, cyber security and green energy.':
          {},
        "President Biden\u2019s message was one of tremendous hope and opportunity for the future. This was a very inspiring event to be apart of and I was delighted to be invited, as NI celebrates the progress that\u2019s been made and now looks ahead to the next 25 years.":
          {},
        "Frame 460": {},
        "Read more:": {},
        "BBC Article Link": {},
        "Frame 461": {},
        "Frame 451": {},
        AchievementPresidentBidenResponsive: {},
      },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "base",
    small: "small",
    medium: "medium",
    large: "large",
    xl: "xl",
    xxl: "xxl",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  const bBCArticleLinkOnClick = useNavigateAction({
    type: "url",
    url: "https://www.bbc.co.uk/news/uk-northern-ireland-65208880",
    target: "_blank",
  });
  return (
    <Flex
      gap="0"
      direction="column"
      width="380px"
      height="unset"
      justifyContent="center"
      alignItems="center"
      position="relative"
      padding="80px 0px 80px 0px"
      backgroundColor="rgba(245,245,247,1)"
      display="flex"
      {...getOverrideProps(overrides, "AchievementPresidentBidenResponsive")}
      {...rest}
    >
      <Flex
        gap="10px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 50px 0px 50px"
        display="flex"
        {...getOverrideProps(overrides, "TitleFrame")}
      >
        <Text
          fontFamily="Inter"
          fontSize="32px"
          fontWeight="600"
          color="rgba(0,0,0,1)"
          lineHeight="35px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Invitation to President Biden’s Address"
          {...getOverrideProps(
            overrides,
            "Invitation to President Biden\u2019s Address"
          )}
        ></Text>
      </Flex>
      <Flex
        gap="10px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="50px 0px 50px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Frame 449")}
      >
        <Image
          width="unset"
          height="225.95px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          objectFit="cover"
          src="https://personal-website-liam.s3.eu-west-1.amazonaws.com/public/liam-president-biden-shoulder.jpg"
          {...getOverrideProps(overrides, "image")}
        ></Image>
      </Flex>
      <Flex
        gap="24px"
        direction="column"
        width="300px"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Frame 136")}
      >
        <Flex
          gap="16px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 458")}
        >
          <Text
            fontFamily="Inter"
            fontSize="13px"
            fontWeight="400"
            color="rgba(0,0,0,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="It was an honour to have been invited to President Biden’s speech in Belfast, which marked 25 years since the Good Friday Agreement was signed."
            {...getOverrideProps(
              overrides,
              "It was an honour to have been invited to President Biden\u2019s speech in Belfast, which marked 25 years since the Good Friday Agreement was signed."
            )}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="13px"
            fontWeight="400"
            color="rgba(0,0,0,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="The President placed an emphasis on the next generation of leaders in Northern Ireland and the valuable work that’s being done in the push for further progress."
            {...getOverrideProps(
              overrides,
              "The President placed an emphasis on the next generation of leaders in Northern Ireland and the valuable work that\u2019s being done in the push for further progress."
            )}
          ></Text>
        </Flex>
      </Flex>
      <Flex
        gap="10px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="50px 0px 50px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Frame 450")}
      >
        <Image
          width="300px"
          height="450.08px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          borderRadius="20px"
          padding="0px 0px 0px 0px"
          objectFit="cover"
          src="https://personal-website-liam.s3.eu-west-1.amazonaws.com/public/biden-wide-shot.jpg"
          {...getOverrideProps(overrides, "biden-wide-shot")}
        ></Image>
      </Flex>
      <Flex
        gap="24px"
        direction="column"
        width="300px"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Frame 451")}
      >
        <Flex
          gap="0"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 459")}
        >
          <Text
            fontFamily="Tiro Bangla"
            fontSize="40px"
            fontWeight="400"
            color="rgba(15,35,81,1)"
            fontStyle="italic"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0.25px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="“"
            {...getOverrideProps(overrides, "\u201C")}
          ></Text>
          <Text
            fontFamily="Tiro Bangla"
            fontSize="24px"
            fontWeight="400"
            color="rgba(15,35,81,1)"
            fontStyle="italic"
            lineHeight="30px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0.09px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="And young people in Northern Ireland are on the cutting edge of sectors that are going to define so much of the future: cyber, technology, clean energy, life sciences."
            {...getOverrideProps(
              overrides,
              "And young people in Northern Ireland are on the cutting edge of sectors that are going to define so much of the future: cyber, technology, clean energy, life sciences."
            )}
          ></Text>
          <Text
            fontFamily="Tiro Bangla"
            fontSize="40px"
            fontWeight="400"
            color="rgba(15,35,81,1)"
            fontStyle="italic"
            lineHeight="24px"
            textAlign="right"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0.25px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="”"
            {...getOverrideProps(overrides, "\u201D")}
          ></Text>
        </Flex>
        <Flex
          gap="16px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 460")}
        >
          <Text
            fontFamily="Inter"
            fontSize="13px"
            fontWeight="400"
            color="rgba(0,0,0,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children='The President also praised Northern Ireland&apos;s young people, saying they are at the "cutting edge" of its future - with a special focus on technology, cyber security and green energy.'
            {...getOverrideProps(
              overrides,
              'The President also praised Northern Ireland\'s young people, saying they are at the "cutting edge" of its future - with a special focus on technology, cyber security and green energy.'
            )}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="13px"
            fontWeight="400"
            color="rgba(0,0,0,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="President Biden’s message was one of tremendous hope and opportunity for the future. This was a very inspiring event to be apart of and I was delighted to be invited, as NI celebrates the progress that’s been made and now looks ahead to the next 25 years."
            {...getOverrideProps(
              overrides,
              "President Biden\u2019s message was one of tremendous hope and opportunity for the future. This was a very inspiring event to be apart of and I was delighted to be invited, as NI celebrates the progress that\u2019s been made and now looks ahead to the next 25 years."
            )}
          ></Text>
        </Flex>
        <Flex
          gap="16px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="30px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 461")}
        >
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="700"
            color="rgba(0,0,0,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0.01px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Read more:"
            {...getOverrideProps(overrides, "Read more:")}
          ></Text>
          <Image
            width="300px"
            height="209.12px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            borderRadius="20px"
            padding="0px 0px 0px 0px"
            objectFit="cover"
            src="https://personal-website-liam.s3.eu-west-1.amazonaws.com/public/biden-bbc-article-link.png"
            onClick={() => {
              bBCArticleLinkOnClick();
            }}
            {...getOverrideProps(overrides, "BBC Article Link")}
          ></Image>
        </Flex>
      </Flex>
    </Flex>
  );
}
