/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
  useNavigateAction,
} from "@aws-amplify/ui-react/internal";
import { Flex, Image, Text, useBreakpointValue } from "@aws-amplify/ui-react";
export default function UniAwardResponsive(props) {
  const { overrides: overridesProp, ...restProp } = props;
  const variants = [
    {
      variantValues: { breakpoint: "xxl" },
      overrides: {
        "Award for \u201CBest Mobile Technology Project\u201D": {
          fontSize: "48px",
        },
        TitleFrame: { padding: "20px 50px 20px 50px" },
        "liam-uu-awards-cropped": { height: "985.39px" },
        "Frame 449": {},
        "I was delighted to pick up my award for \u201CBest Mobile Technology Project\u201D at Ulster University, after the awards ceremony was delayed due to Covid.":
          { fontSize: "16px", letterSpacing: "0.01px" },
        "This project will allow Search & Rescue teams in Derry to better organise their public searches for missing people along the River Foyle.":
          { fontSize: "16px", letterSpacing: "0.01px" },
        "The city of Derry is currently facing a profound mental health crisis. On a daily basis, local volunteers are alerted to a person who has gone missing near the River Foyle.":
          { fontSize: "16px", letterSpacing: "0.01px" },
        "As a result, Search and Rescue operations in the river and the surrounding areas are very common. In these situations, the rescue teams will ask the public for help them search a larger area.":
          { fontSize: "16px", letterSpacing: "0.01px" },
        "Currently, rescue teams will allocate members of the public to specific areas - where they will then search for the missing person.":
          { fontSize: "16px", letterSpacing: "0.01px" },
        "This is a slow process and something I thought could be drastically improved.":
          { fontSize: "16px", letterSpacing: "0.01px" },
        "uu-app-home-screen": {
          width: "400px",
          height: "826.04px",
          borderRadius: "50px",
        },
        "Frame 476": { padding: "40px 0px 40px 0px" },
        "I developed a cross-platform app (iOS and Android), which Search and Rescue teams can use to quickly share information with the public when there\u2019s an emergency.":
          { fontSize: "16px", letterSpacing: "0.01px" },
        "Members of the public are shown details about the missing person, such as their appearance and last known position - along with the area they are being asked to search.":
          { fontSize: "16px", letterSpacing: "0.01px" },
        "If they find something that looks suspicious, they can quickly alert Search & Rescue staff via the app.":
          { fontSize: "16px", letterSpacing: "0.01px" },
        "It\u2019s hoped that this project will greatly speed up the process for rescuers and lead to hundreds of volunteers being alerted when their help is needed.":
          { fontSize: "16px", letterSpacing: "0.01px" },
        "I\u2019m quite proud of the work and it should be something that the rescue teams can really benefit from.":
          { fontSize: "16px", letterSpacing: "0.01px" },
        "uu-app-map-screen": {
          width: "400px",
          height: "824.19px",
          borderRadius: "50px",
        },
        "Frame 477": { padding: "40px 0px 40px 0px" },
        "Frame 458": {},
        "Frame 136": { width: "800px" },
        "Read more:": { fontSize: "24px", letterSpacing: "0.09px" },
        "liam-uu-project-linkedin": {
          width: "800px",
          height: "341.5px",
          borderRadius: "20px",
        },
        "Frame 461": { gap: "32px", padding: "50px 0px 0px 0px" },
        "Frame 451": { width: "800px" },
        UniAwardResponsive: { width: "1536px" },
      },
    },
    {
      variantValues: { breakpoint: "xl" },
      overrides: {
        "Award for \u201CBest Mobile Technology Project\u201D": {
          fontSize: "48px",
        },
        TitleFrame: { padding: "20px 50px 20px 50px" },
        "liam-uu-awards-cropped": { height: "821.16px" },
        "Frame 449": {},
        "I was delighted to pick up my award for \u201CBest Mobile Technology Project\u201D at Ulster University, after the awards ceremony was delayed due to Covid.":
          { fontSize: "16px", letterSpacing: "0.01px" },
        "This project will allow Search & Rescue teams in Derry to better organise their public searches for missing people along the River Foyle.":
          { fontSize: "16px", letterSpacing: "0.01px" },
        "The city of Derry is currently facing a profound mental health crisis. On a daily basis, local volunteers are alerted to a person who has gone missing near the River Foyle.":
          { fontSize: "16px", letterSpacing: "0.01px" },
        "As a result, Search and Rescue operations in the river and the surrounding areas are very common. In these situations, the rescue teams will ask the public for help them search a larger area.":
          { fontSize: "16px", letterSpacing: "0.01px" },
        "Currently, rescue teams will allocate members of the public to specific areas - where they will then search for the missing person.":
          { fontSize: "16px", letterSpacing: "0.01px" },
        "This is a slow process and something I thought could be drastically improved.":
          { fontSize: "16px", letterSpacing: "0.01px" },
        "uu-app-home-screen": {
          width: "400px",
          height: "826.04px",
          borderRadius: "50px",
        },
        "Frame 476": { padding: "40px 0px 40px 0px" },
        "I developed a cross-platform app (iOS and Android), which Search and Rescue teams can use to quickly share information with the public when there\u2019s an emergency.":
          { fontSize: "16px", letterSpacing: "0.01px" },
        "Members of the public are shown details about the missing person, such as their appearance and last known position - along with the area they are being asked to search.":
          { fontSize: "16px", letterSpacing: "0.01px" },
        "If they find something that looks suspicious, they can quickly alert Search & Rescue staff via the app.":
          { fontSize: "16px", letterSpacing: "0.01px" },
        "It\u2019s hoped that this project will greatly speed up the process for rescuers and lead to hundreds of volunteers being alerted when their help is needed.":
          { fontSize: "16px", letterSpacing: "0.01px" },
        "I\u2019m quite proud of the work and it should be something that the rescue teams can really benefit from.":
          { fontSize: "16px", letterSpacing: "0.01px" },
        "uu-app-map-screen": {
          width: "400px",
          height: "824.19px",
          borderRadius: "50px",
        },
        "Frame 477": { padding: "40px 0px 40px 0px" },
        "Frame 458": {},
        "Frame 136": { width: "800px" },
        "Read more:": { fontSize: "24px", letterSpacing: "0.09px" },
        "liam-uu-project-linkedin": {
          width: "800px",
          height: "341.5px",
          borderRadius: "20px",
        },
        "Frame 461": { gap: "32px", padding: "50px 0px 0px 0px" },
        "Frame 451": { width: "800px" },
        UniAwardResponsive: { width: "1280px" },
      },
    },
    {
      variantValues: { breakpoint: "large" },
      overrides: {
        "Award for \u201CBest Mobile Technology Project\u201D": {
          fontSize: "40px",
        },
        TitleFrame: { padding: "20px 50px 20px 50px" },
        "liam-uu-awards-cropped": { height: "636.4px" },
        "Frame 449": {},
        "I was delighted to pick up my award for \u201CBest Mobile Technology Project\u201D at Ulster University, after the awards ceremony was delayed due to Covid.":
          { fontSize: "14px" },
        "This project will allow Search & Rescue teams in Derry to better organise their public searches for missing people along the River Foyle.":
          { fontSize: "14px" },
        "The city of Derry is currently facing a profound mental health crisis. On a daily basis, local volunteers are alerted to a person who has gone missing near the River Foyle.":
          { fontSize: "14px" },
        "As a result, Search and Rescue operations in the river and the surrounding areas are very common. In these situations, the rescue teams will ask the public for help them search a larger area.":
          { fontSize: "14px" },
        "Currently, rescue teams will allocate members of the public to specific areas - where they will then search for the missing person.":
          { fontSize: "14px" },
        "This is a slow process and something I thought could be drastically improved.":
          { fontSize: "14px" },
        "uu-app-home-screen": {
          width: "350px",
          height: "722.78px",
          borderRadius: "45px",
        },
        "Frame 476": { padding: "40px 0px 40px 0px" },
        "I developed a cross-platform app (iOS and Android), which Search and Rescue teams can use to quickly share information with the public when there\u2019s an emergency.":
          { fontSize: "14px" },
        "Members of the public are shown details about the missing person, such as their appearance and last known position - along with the area they are being asked to search.":
          { fontSize: "14px" },
        "If they find something that looks suspicious, they can quickly alert Search & Rescue staff via the app.":
          { fontSize: "14px" },
        "It\u2019s hoped that this project will greatly speed up the process for rescuers and lead to hundreds of volunteers being alerted when their help is needed.":
          { fontSize: "14px" },
        "I\u2019m quite proud of the work and it should be something that the rescue teams can really benefit from.":
          { fontSize: "14px" },
        "uu-app-map-screen": {
          width: "350px",
          height: "721.17px",
          borderRadius: "45px",
        },
        "Frame 477": { padding: "40px 0px 40px 0px" },
        "Frame 458": {},
        "Frame 136": { width: "650px" },
        "Read more:": { fontSize: "24px", letterSpacing: "0.09px" },
        "liam-uu-project-linkedin": {
          width: "650px",
          height: "277.47px",
          borderRadius: "20px",
        },
        "Frame 461": { gap: "32px", padding: "50px 0px 0px 0px" },
        "Frame 451": { width: "650px" },
        UniAwardResponsive: { width: "992px" },
      },
    },
    {
      variantValues: { breakpoint: "medium" },
      overrides: {
        "Award for \u201CBest Mobile Technology Project\u201D": {
          fontSize: "36px",
        },
        TitleFrame: { padding: "20px 50px 20px 50px" },
        "liam-uu-awards-cropped": { height: "492.7px" },
        "Frame 449": {},
        "I was delighted to pick up my award for \u201CBest Mobile Technology Project\u201D at Ulster University, after the awards ceremony was delayed due to Covid.":
          { fontSize: "14px" },
        "This project will allow Search & Rescue teams in Derry to better organise their public searches for missing people along the River Foyle.":
          { fontSize: "14px" },
        "The city of Derry is currently facing a profound mental health crisis. On a daily basis, local volunteers are alerted to a person who has gone missing near the River Foyle.":
          { fontSize: "14px" },
        "As a result, Search and Rescue operations in the river and the surrounding areas are very common. In these situations, the rescue teams will ask the public for help them search a larger area.":
          { fontSize: "14px" },
        "Currently, rescue teams will allocate members of the public to specific areas - where they will then search for the missing person.":
          { fontSize: "14px" },
        "This is a slow process and something I thought could be drastically improved.":
          { fontSize: "14px" },
        "uu-app-home-screen": {},
        "Frame 476": { padding: "30px 0px 30px 0px" },
        "I developed a cross-platform app (iOS and Android), which Search and Rescue teams can use to quickly share information with the public when there\u2019s an emergency.":
          { fontSize: "14px" },
        "Members of the public are shown details about the missing person, such as their appearance and last known position - along with the area they are being asked to search.":
          { fontSize: "14px" },
        "If they find something that looks suspicious, they can quickly alert Search & Rescue staff via the app.":
          { fontSize: "14px" },
        "It\u2019s hoped that this project will greatly speed up the process for rescuers and lead to hundreds of volunteers being alerted when their help is needed.":
          { fontSize: "14px" },
        "I\u2019m quite proud of the work and it should be something that the rescue teams can really benefit from.":
          { fontSize: "14px" },
        "uu-app-map-screen": {},
        "Frame 477": { padding: "30px 0px 30px 0px" },
        "Frame 458": {},
        "Frame 136": { width: "520px" },
        "Read more:": { fontSize: "24px", letterSpacing: "0.09px" },
        "liam-uu-project-linkedin": {
          width: "520px",
          height: "221.98px",
          borderRadius: "20px",
        },
        "Frame 461": { gap: "24px", padding: "50px 0px 0px 0px" },
        "Frame 451": { width: "520px" },
        UniAwardResponsive: { width: "768px" },
      },
    },
    {
      variantValues: { breakpoint: "small" },
      overrides: {
        "Award for \u201CBest Mobile Technology Project\u201D": {
          fontSize: "36px",
        },
        TitleFrame: { padding: "20px 50px 20px 50px" },
        "liam-uu-awards-cropped": { height: "307.94px" },
        "Frame 449": {},
        "I was delighted to pick up my award for \u201CBest Mobile Technology Project\u201D at Ulster University, after the awards ceremony was delayed due to Covid.":
          { fontSize: "14px" },
        "This project will allow Search & Rescue teams in Derry to better organise their public searches for missing people along the River Foyle.":
          { fontSize: "14px" },
        "The city of Derry is currently facing a profound mental health crisis. On a daily basis, local volunteers are alerted to a person who has gone missing near the River Foyle.":
          { fontSize: "14px" },
        "As a result, Search and Rescue operations in the river and the surrounding areas are very common. In these situations, the rescue teams will ask the public for help them search a larger area.":
          { fontSize: "14px" },
        "Currently, rescue teams will allocate members of the public to specific areas - where they will then search for the missing person.":
          { fontSize: "14px" },
        "This is a slow process and something I thought could be drastically improved.":
          { fontSize: "14px" },
        "uu-app-home-screen": {},
        "Frame 476": { padding: "30px 0px 30px 0px" },
        "I developed a cross-platform app (iOS and Android), which Search and Rescue teams can use to quickly share information with the public when there\u2019s an emergency.":
          { fontSize: "14px" },
        "Members of the public are shown details about the missing person, such as their appearance and last known position - along with the area they are being asked to search.":
          { fontSize: "14px" },
        "If they find something that looks suspicious, they can quickly alert Search & Rescue staff via the app.":
          { fontSize: "14px" },
        "It\u2019s hoped that this project will greatly speed up the process for rescuers and lead to hundreds of volunteers being alerted when their help is needed.":
          { fontSize: "14px" },
        "I\u2019m quite proud of the work and it should be something that the rescue teams can really benefit from.":
          { fontSize: "14px" },
        "uu-app-map-screen": {},
        "Frame 477": { padding: "30px 0px 30px 0px" },
        "Frame 458": {},
        "Frame 136": { width: "380px" },
        "Read more:": { fontSize: "20px", letterSpacing: "0.05px" },
        "liam-uu-project-linkedin": { width: "380px", height: "162.21px" },
        "Frame 461": {},
        "Frame 451": { width: "380px" },
        UniAwardResponsive: { width: "480px" },
      },
    },
    {
      variantValues: { breakpoint: "base" },
      overrides: {
        "Award for \u201CBest Mobile Technology Project\u201D": {},
        TitleFrame: {},
        "liam-uu-awards-cropped": {},
        "Frame 449": {},
        "I was delighted to pick up my award for \u201CBest Mobile Technology Project\u201D at Ulster University, after the awards ceremony was delayed due to Covid.":
          {},
        "This project will allow Search & Rescue teams in Derry to better organise their public searches for missing people along the River Foyle.":
          {},
        "The city of Derry is currently facing a profound mental health crisis. On a daily basis, local volunteers are alerted to a person who has gone missing near the River Foyle.":
          {},
        "As a result, Search and Rescue operations in the river and the surrounding areas are very common. In these situations, the rescue teams will ask the public for help them search a larger area.":
          {},
        "Currently, rescue teams will allocate members of the public to specific areas - where they will then search for the missing person.":
          {},
        "This is a slow process and something I thought could be drastically improved.":
          {},
        "uu-app-home-screen": {},
        "Frame 476": {},
        "I developed a cross-platform app (iOS and Android), which Search and Rescue teams can use to quickly share information with the public when there\u2019s an emergency.":
          {},
        "Members of the public are shown details about the missing person, such as their appearance and last known position - along with the area they are being asked to search.":
          {},
        "If they find something that looks suspicious, they can quickly alert Search & Rescue staff via the app.":
          {},
        "It\u2019s hoped that this project will greatly speed up the process for rescuers and lead to hundreds of volunteers being alerted when their help is needed.":
          {},
        "I\u2019m quite proud of the work and it should be something that the rescue teams can really benefit from.":
          {},
        "uu-app-map-screen": {},
        "Frame 477": {},
        "Frame 458": {},
        "Frame 136": {},
        "Read more:": {},
        "liam-uu-project-linkedin": {},
        "Frame 461": {},
        "Frame 451": {},
        UniAwardResponsive: {},
      },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "base",
    small: "small",
    medium: "medium",
    large: "large",
    xl: "xl",
    xxl: "xxl",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  const liamuuprojectlinkedinOnClick = useNavigateAction({
    type: "url",
    url: "https://www.linkedin.com/posts/activity-7009115888452780032-4e4B?utm_source=share&utm_medium=member_desktop",
    target: "_blank",
  });
  return (
    <Flex
      gap="0"
      direction="column"
      width="380px"
      height="unset"
      justifyContent="center"
      alignItems="center"
      position="relative"
      padding="80px 0px 80px 0px"
      backgroundColor="rgba(245,245,247,1)"
      display="flex"
      {...getOverrideProps(overrides, "UniAwardResponsive")}
      {...rest}
    >
      <Flex
        gap="10px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 50px 0px 50px"
        display="flex"
        {...getOverrideProps(overrides, "TitleFrame")}
      >
        <Text
          fontFamily="Inter"
          fontSize="32px"
          fontWeight="600"
          color="rgba(0,0,0,1)"
          lineHeight="35px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Award for “Best Mobile Technology Project”"
          {...getOverrideProps(
            overrides,
            "Award for \u201CBest Mobile Technology Project\u201D"
          )}
        ></Text>
      </Flex>
      <Flex
        gap="10px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="50px 0px 50px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Frame 449")}
      >
        <Image
          width="unset"
          height="243.78px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          objectFit="cover"
          src="https://personal-website-liam.s3.eu-west-1.amazonaws.com/public/liam-uu-awards-cropped.jpg"
          {...getOverrideProps(overrides, "liam-uu-awards-cropped")}
        ></Image>
      </Flex>
      <Flex
        gap="24px"
        direction="column"
        width="300px"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Frame 136")}
      >
        <Flex
          gap="16px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 458")}
        >
          <Text
            fontFamily="Inter"
            fontSize="13px"
            fontWeight="400"
            color="rgba(0,0,0,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="I was delighted to pick up my award for “Best Mobile Technology Project” at Ulster University, after the awards ceremony was delayed due to Covid."
            {...getOverrideProps(
              overrides,
              "I was delighted to pick up my award for \u201CBest Mobile Technology Project\u201D at Ulster University, after the awards ceremony was delayed due to Covid."
            )}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="13px"
            fontWeight="400"
            color="rgba(0,0,0,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="This project will allow Search & Rescue teams in Derry to better organise their public searches for missing people along the River Foyle."
            {...getOverrideProps(
              overrides,
              "This project will allow Search & Rescue teams in Derry to better organise their public searches for missing people along the River Foyle."
            )}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="13px"
            fontWeight="400"
            color="rgba(0,0,0,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="The city of Derry is currently facing a profound mental health crisis. On a daily basis, local volunteers are alerted to a person who has gone missing near the River Foyle."
            {...getOverrideProps(
              overrides,
              "The city of Derry is currently facing a profound mental health crisis. On a daily basis, local volunteers are alerted to a person who has gone missing near the River Foyle."
            )}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="13px"
            fontWeight="400"
            color="rgba(0,0,0,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="As a result, Search and Rescue operations in the river and the surrounding areas are very common. In these situations, the rescue teams will ask the public for help them search a larger area."
            {...getOverrideProps(
              overrides,
              "As a result, Search and Rescue operations in the river and the surrounding areas are very common. In these situations, the rescue teams will ask the public for help them search a larger area."
            )}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="13px"
            fontWeight="400"
            color="rgba(0,0,0,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Currently, rescue teams will allocate members of the public to specific areas - where they will then search for the missing person."
            {...getOverrideProps(
              overrides,
              "Currently, rescue teams will allocate members of the public to specific areas - where they will then search for the missing person."
            )}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="13px"
            fontWeight="400"
            color="rgba(0,0,0,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="This is a slow process and something I thought could be drastically improved."
            {...getOverrideProps(
              overrides,
              "This is a slow process and something I thought could be drastically improved."
            )}
          ></Text>
          <Flex
            gap="10px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="20px 0px 20px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 476")}
          >
            <Image
              width="250px"
              height="516.27px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              borderRadius="35px"
              padding="0px 0px 0px 0px"
              objectFit="cover"
              src="https://personal-website-liam.s3.eu-west-1.amazonaws.com/public/uu-app-home-screen.jpeg"
              {...getOverrideProps(overrides, "uu-app-home-screen")}
            ></Image>
          </Flex>
          <Text
            fontFamily="Inter"
            fontSize="13px"
            fontWeight="400"
            color="rgba(0,0,0,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="I developed a cross-platform app (iOS and Android), which Search and Rescue teams can use to quickly share information with the public when there’s an emergency."
            {...getOverrideProps(
              overrides,
              "I developed a cross-platform app (iOS and Android), which Search and Rescue teams can use to quickly share information with the public when there\u2019s an emergency."
            )}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="13px"
            fontWeight="400"
            color="rgba(0,0,0,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Members of the public are shown details about the missing person, such as their appearance and last known position - along with the area they are being asked to search."
            {...getOverrideProps(
              overrides,
              "Members of the public are shown details about the missing person, such as their appearance and last known position - along with the area they are being asked to search."
            )}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="13px"
            fontWeight="400"
            color="rgba(0,0,0,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="If they find something that looks suspicious, they can quickly alert Search & Rescue staff via the app."
            {...getOverrideProps(
              overrides,
              "If they find something that looks suspicious, they can quickly alert Search & Rescue staff via the app."
            )}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="13px"
            fontWeight="400"
            color="rgba(0,0,0,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="It’s hoped that this project will greatly speed up the process for rescuers and lead to hundreds of volunteers being alerted when their help is needed."
            {...getOverrideProps(
              overrides,
              "It\u2019s hoped that this project will greatly speed up the process for rescuers and lead to hundreds of volunteers being alerted when their help is needed."
            )}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="13px"
            fontWeight="400"
            color="rgba(0,0,0,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="I’m quite proud of the work and it should be something that the rescue teams can really benefit from."
            {...getOverrideProps(
              overrides,
              "I\u2019m quite proud of the work and it should be something that the rescue teams can really benefit from."
            )}
          ></Text>
          <Flex
            gap="10px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="20px 0px 20px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 477")}
          >
            <Image
              width="250px"
              height="515.12px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              borderRadius="35px"
              padding="0px 0px 0px 0px"
              objectFit="cover"
              src="https://personal-website-liam.s3.eu-west-1.amazonaws.com/public/uu-app-map-screen.jpeg"
              {...getOverrideProps(overrides, "uu-app-map-screen")}
            ></Image>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        gap="24px"
        direction="column"
        width="300px"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Frame 451")}
      >
        <Flex
          gap="16px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="30px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 461")}
        >
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="700"
            color="rgba(0,0,0,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0.01px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Read more:"
            {...getOverrideProps(overrides, "Read more:")}
          ></Text>
          <Image
            width="300px"
            height="128.06px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            borderRadius="15px"
            padding="0px 0px 0px 0px"
            objectFit="cover"
            src="https://personal-website-liam.s3.eu-west-1.amazonaws.com/public/liam-uu-project-linkedin.png"
            onClick={() => {
              liamuuprojectlinkedinOnClick();
            }}
            {...getOverrideProps(overrides, "liam-uu-project-linkedin")}
          ></Image>
        </Flex>
      </Flex>
    </Flex>
  );
}
