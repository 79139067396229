import React, { useEffect } from 'react';
import { BlogFooterResponsive, NavigationBarResponsive, WorkInProgressResponsive }  from '../ui-components';

const ProjectsPage = () => {
    useEffect(() => {
        document.title = 'Projects - Liam McCormick';
    }, []);

    return (
        <div>
            <NavigationBarResponsive width={"100%"}/>
            <WorkInProgressResponsive width={"100%"}/>
            <BlogFooterResponsive width={"100%"}/>
        </div>
    );
}

export default ProjectsPage;