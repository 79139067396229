/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import { Flex, Image, Text, useBreakpointValue } from "@aws-amplify/ui-react";
export default function AboutLiamResponsive(props) {
  const { overrides: overridesProp, ...restProp } = props;
  const variants = [
    {
      variantValues: { breakpoint: "xxl" },
      overrides: {
        "About Liam": { fontSize: "24px" },
        "Working in Innovation 1": { width: "515px", height: "47.82px" },
        "Frame 418": { padding: "20px 0px 20px 0px" },
        "I\u2019m a Senior Software Engineer within the Innovation team at Kainos. I work to identify business value in emerging technologies, implement them and then share these insights with the rest of the company.":
          {
            fontSize: "16px",
            letterSpacing: "0.01px",
            shrink: "1",
            grow: "1",
            basis: "0",
          },
        "I often get to try some of the latest advances in technology and then come up with innovative solutions that solve difficult problems for the company\u2019s customers - such as the UK Government.":
          {
            fontSize: "16px",
            letterSpacing: "0.01px",
            shrink: "1",
            grow: "1",
            basis: "0",
          },
        "Frame 442": { direction: "row" },
        "Main Body38622734": {},
        image38622740: { width: "100px", height: "100px" },
        AI38622741: { fontSize: "16px" },
        "Frame 37438622739": { padding: "40px 20px 20px 20px" },
        image38622743: { width: "100px", height: "100px" },
        "AR / VR38622744": { fontSize: "14px" },
        "Frame 37338622742": { padding: "40px 20px 20px 20px" },
        image38622746: { width: "100px", height: "100px" },
        "Computer Vision38622747": {
          fontSize: "14px",
          children: "Computer Vision",
        },
        "Frame 37538622745": {
          padding: "40px 20px 20px 20px",
          display: "flex",
        },
        image38622749: { width: "100px", height: "100px" },
        LLMs38622750: { fontSize: "14px" },
        "Frame 37638622748": {
          padding: "40px 20px 20px 20px",
          display: "flex",
        },
        "Frame 395": { gap: "70px" },
        image38672731: {},
        AI38672732: {},
        "Frame 37438672730": {},
        image38672734: {},
        "AR / VR38672735": {},
        "Frame 37338672733": {},
        image38672737: {},
        "Computer Vision38672738": {},
        "Frame 37538672736": { display: "none" },
        image38672740: {},
        LLMs38672741: {},
        "Frame 37638672739": { display: "none" },
        "Frame 421": { display: "none" },
        image38622753: { width: "100px", height: "100px" },
        "Satellite Images38622754": { fontSize: "14px" },
        "Frame 37438622752": { padding: "40px 20px 20px 20px" },
        image38622756: { width: "100px", height: "100px" },
        Autonomous38622757: { fontSize: "14px" },
        "Frame 37338622755": { padding: "40px 20px 20px 20px" },
        image38622759: { width: "100px", height: "100px" },
        "Smart Cities38622760": { fontSize: "14px" },
        "Frame 37538622758": {
          padding: "40px 20px 20px 20px",
          display: "flex",
        },
        image38622762: { width: "100px", height: "100px" },
        Mobile38622763: { fontSize: "14px" },
        "Frame 37638622761": {
          padding: "40px 20px 20px 20px",
          display: "flex",
        },
        "Frame 420": { gap: "70px" },
        image38672744: {},
        "Satellite Images38672745": {},
        "Frame 37438672743": {},
        image38672747: {},
        Autonomous38672748: {},
        "Frame 37338672746": {},
        image38672750: {},
        "Smart Cities38672751": {},
        "Frame 37538672749": { display: "none" },
        image38672753: {},
        Mobile38672754: {},
        "Frame 37638672752": { display: "none" },
        "Frame 422": { display: "none" },
        "Frame 430": { gap: "30px" },
        "Main Body38622730": { width: "unset", alignSelf: "stretch" },
        "Type Lock Up": { padding: "100px 240px 100px 240px" },
        AboutLiamResponsive: { width: "1536px" },
      },
    },
    {
      variantValues: { breakpoint: "xl" },
      overrides: {
        "About Liam": { fontSize: "24px" },
        "Working in Innovation 1": { width: "515px", height: "47.82px" },
        "Frame 418": { padding: "20px 0px 20px 0px" },
        "I\u2019m a Senior Software Engineer within the Innovation team at Kainos. I work to identify business value in emerging technologies, implement them and then share these insights with the rest of the company.":
          {
            fontSize: "16px",
            letterSpacing: "0.01px",
            shrink: "1",
            grow: "1",
            basis: "0",
          },
        "I often get to try some of the latest advances in technology and then come up with innovative solutions that solve difficult problems for the company\u2019s customers - such as the UK Government.":
          {
            fontSize: "16px",
            letterSpacing: "0.01px",
            shrink: "1",
            grow: "1",
            basis: "0",
          },
        "Frame 442": { direction: "row" },
        "Main Body38622734": {},
        image38622740: { width: "100px", height: "100px" },
        AI38622741: { fontSize: "16px" },
        "Frame 37438622739": { padding: "40px 20px 20px 20px" },
        image38622743: { width: "100px", height: "100px" },
        "AR / VR38622744": { fontSize: "14px" },
        "Frame 37338622742": { padding: "40px 20px 20px 20px" },
        image38622746: { width: "100px", height: "100px" },
        "Computer Vision38622747": {
          fontSize: "14px",
          children: "Computer Vision",
        },
        "Frame 37538622745": {
          padding: "40px 20px 20px 20px",
          display: "flex",
        },
        image38622749: { width: "100px", height: "100px" },
        LLMs38622750: { fontSize: "14px" },
        "Frame 37638622748": {
          padding: "40px 20px 20px 20px",
          display: "flex",
        },
        "Frame 395": { gap: "50px" },
        image38672731: {},
        AI38672732: {},
        "Frame 37438672730": {},
        image38672734: {},
        "AR / VR38672735": {},
        "Frame 37338672733": {},
        image38672737: {},
        "Computer Vision38672738": {},
        "Frame 37538672736": { display: "none" },
        image38672740: {},
        LLMs38672741: {},
        "Frame 37638672739": { display: "none" },
        "Frame 421": { display: "none" },
        image38622753: { width: "100px", height: "100px" },
        "Satellite Images38622754": { fontSize: "14px" },
        "Frame 37438622752": { padding: "40px 20px 20px 20px" },
        image38622756: { width: "100px", height: "100px" },
        Autonomous38622757: { fontSize: "14px" },
        "Frame 37338622755": { padding: "40px 20px 20px 20px" },
        image38622759: { width: "100px", height: "100px" },
        "Smart Cities38622760": { fontSize: "14px" },
        "Frame 37538622758": {
          padding: "40px 20px 20px 20px",
          display: "flex",
        },
        image38622762: { width: "100px", height: "100px" },
        Mobile38622763: { fontSize: "14px" },
        "Frame 37638622761": {
          padding: "40px 20px 20px 20px",
          display: "flex",
        },
        "Frame 420": { gap: "50px" },
        image38672744: {},
        "Satellite Images38672745": {},
        "Frame 37438672743": {},
        image38672747: {},
        Autonomous38672748: {},
        "Frame 37338672746": {},
        image38672750: {},
        "Smart Cities38672751": {},
        "Frame 37538672749": { display: "none" },
        image38672753: {},
        Mobile38672754: {},
        "Frame 37638672752": { display: "none" },
        "Frame 422": { display: "none" },
        "Frame 430": { gap: "30px" },
        "Main Body38622730": { width: "800px" },
        "Type Lock Up": { padding: "100px 240px 100px 240px" },
        AboutLiamResponsive: { width: "1280px" },
      },
    },
    {
      variantValues: { breakpoint: "large" },
      overrides: {
        "About Liam": { fontSize: "16px" },
        "Working in Innovation 1": { width: "380px", height: "35.29px" },
        "Frame 418": { padding: "10px 0px 10px 0px" },
        "I\u2019m a Senior Software Engineer within the Innovation team at Kainos. I work to identify business value in emerging technologies, implement them and then share these insights with the rest of the company.":
          { fontSize: "14px", shrink: "1", grow: "1", basis: "0" },
        "I often get to try some of the latest advances in technology and then come up with innovative solutions that solve difficult problems for the company\u2019s customers - such as the UK Government.":
          { fontSize: "14px", shrink: "1", grow: "1", basis: "0" },
        "Frame 442": { direction: "row" },
        "Main Body38622734": {},
        image38622740: { width: "80px", height: "80px" },
        AI38622741: { fontSize: "16px" },
        "Frame 37438622739": { padding: "40px 20px 20px 20px" },
        image38622743: { width: "80px", height: "80px" },
        "AR / VR38622744": { fontSize: "14px" },
        "Frame 37338622742": { padding: "40px 20px 20px 20px" },
        image38622746: { width: "80px", height: "80px" },
        "Computer Vision38622747": {
          fontSize: "14px",
          children: "Computer Vision",
        },
        "Frame 37538622745": {
          padding: "40px 20px 20px 20px",
          display: "flex",
        },
        image38622749: { width: "80px", height: "80px" },
        LLMs38622750: { fontSize: "14px" },
        "Frame 37638622748": {
          padding: "40px 20px 20px 20px",
          display: "flex",
        },
        "Frame 395": { gap: "30px" },
        image38672731: {},
        AI38672732: {},
        "Frame 37438672730": {},
        image38672734: {},
        "AR / VR38672735": {},
        "Frame 37338672733": {},
        image38672737: {},
        "Computer Vision38672738": {},
        "Frame 37538672736": { display: "none" },
        image38672740: {},
        LLMs38672741: {},
        "Frame 37638672739": { display: "none" },
        "Frame 421": { display: "none" },
        image38622753: { width: "80px", height: "80px" },
        "Satellite Images38622754": { fontSize: "14px" },
        "Frame 37438622752": { padding: "40px 20px 20px 20px" },
        image38622756: { width: "80px", height: "80px" },
        Autonomous38622757: { fontSize: "14px" },
        "Frame 37338622755": { padding: "40px 20px 20px 20px" },
        image38622759: { width: "80px", height: "80px" },
        "Smart Cities38622760": { fontSize: "14px" },
        "Frame 37538622758": {
          padding: "40px 20px 20px 20px",
          display: "flex",
        },
        image38622762: { width: "80px", height: "80px" },
        Mobile38622763: { fontSize: "14px" },
        "Frame 37638622761": {
          padding: "40px 20px 20px 20px",
          display: "flex",
        },
        "Frame 420": { gap: "30px" },
        image38672744: {},
        "Satellite Images38672745": {},
        "Frame 37438672743": {},
        image38672747: {},
        Autonomous38672748: {},
        "Frame 37338672746": {},
        image38672750: {},
        "Smart Cities38672751": {},
        "Frame 37538672749": { display: "none" },
        image38672753: {},
        Mobile38672754: {},
        "Frame 37638672752": { display: "none" },
        "Frame 422": { display: "none" },
        "Frame 430": {},
        "Main Body38622730": { width: "692px" },
        "Type Lock Up": { padding: "100px 150px 100px 150px" },
        AboutLiamResponsive: { width: "992px" },
      },
    },
    {
      variantValues: { breakpoint: "medium" },
      overrides: {
        "About Liam": { fontSize: "16px" },
        "Working in Innovation 1": { width: "300px", height: "27.86px" },
        "Frame 418": { padding: "10px 0px 10px 0px" },
        "I\u2019m a Senior Software Engineer within the Innovation team at Kainos. I work to identify business value in emerging technologies, implement them and then share these insights with the rest of the company.":
          { fontSize: "14px", shrink: "1", grow: "1", basis: "0" },
        "I often get to try some of the latest advances in technology and then come up with innovative solutions that solve difficult problems for the company\u2019s customers - such as the UK Government.":
          { fontSize: "14px", shrink: "1", grow: "1", basis: "0" },
        "Frame 442": { direction: "row" },
        "Main Body38622734": {},
        image38622740: { width: "60px", height: "60px" },
        AI38622741: { fontSize: "14px" },
        "Frame 37438622739": {},
        image38622743: { width: "60px", height: "60px" },
        "AR / VR38622744": { fontSize: "14px" },
        "Frame 37338622742": {},
        image38622746: { width: "60px", height: "60px" },
        "Computer Vision38622747": {
          fontSize: "13px",
          children: "Computer Vision",
        },
        "Frame 37538622745": {
          padding: "20px 20px 20px 20px",
          display: "flex",
        },
        image38622749: { width: "60px", height: "60px" },
        LLMs38622750: { fontSize: "14px" },
        "Frame 37638622748": {
          padding: "20px 20px 20px 20px",
          display: "flex",
        },
        "Frame 395": { gap: "25px" },
        image38672731: {},
        AI38672732: {},
        "Frame 37438672730": {},
        image38672734: {},
        "AR / VR38672735": {},
        "Frame 37338672733": {},
        image38672737: {},
        "Computer Vision38672738": {},
        "Frame 37538672736": { display: "none" },
        image38672740: {},
        LLMs38672741: {},
        "Frame 37638672739": { display: "none" },
        "Frame 421": { display: "none" },
        image38622753: { width: "60px", height: "60px" },
        "Satellite Images38622754": { fontSize: "14px" },
        "Frame 37438622752": {},
        image38622756: { width: "60px", height: "60px" },
        Autonomous38622757: { fontSize: "14px" },
        "Frame 37338622755": {},
        image38622759: { width: "60px", height: "60px" },
        "Smart Cities38622760": { fontSize: "14px" },
        "Frame 37538622758": {
          padding: "20px 20px 20px 20px",
          display: "flex",
        },
        image38622762: { width: "60px", height: "60px" },
        Mobile38622763: { fontSize: "14px" },
        "Frame 37638622761": {
          padding: "20px 20px 20px 20px",
          display: "flex",
        },
        "Frame 420": { gap: "25px" },
        image38672744: {},
        "Satellite Images38672745": {},
        "Frame 37438672743": {},
        image38672747: {},
        Autonomous38672748: {},
        "Frame 37338672746": {},
        image38672750: {},
        "Smart Cities38672751": {},
        "Frame 37538672749": { display: "none" },
        image38672753: {},
        Mobile38672754: {},
        "Frame 37638672752": { display: "none" },
        "Frame 422": { display: "none" },
        "Frame 430": {},
        "Main Body38622730": { width: "568px" },
        "Type Lock Up": { padding: "100px 100px 100px 100px" },
        AboutLiamResponsive: { width: "768px", overflow: "hidden" },
      },
    },
    {
      variantValues: { breakpoint: "small" },
      overrides: {
        "About Liam": { fontSize: "16px" },
        "Working in Innovation 1": { width: "280px", height: "26px" },
        "Frame 418": { padding: "10px 0px 10px 0px" },
        "I\u2019m a Senior Software Engineer within the Innovation team at Kainos. I work to identify business value in emerging technologies, implement them and then share these insights with the rest of the company.":
          {},
        "I often get to try some of the latest advances in technology and then come up with innovative solutions that solve difficult problems for the company\u2019s customers - such as the UK Government.":
          {},
        "Frame 442": {},
        "Main Body38622734": {},
        image38622740: { width: "50px", height: "50px" },
        AI38622741: { fontSize: "13px" },
        "Frame 37438622739": {},
        image38622743: { width: "50px", height: "50px" },
        "AR / VR38622744": { fontSize: "13px" },
        "Frame 37338622742": {},
        image38622746: { width: "40px", height: "40px" },
        "Computer Vision38622747": { children: "Computer Vision" },
        "Frame 37538622745": { padding: "20px 20px 20px 20px" },
        image38622749: {},
        LLMs38622750: {},
        "Frame 37638622748": {},
        "Frame 395": {},
        image38672731: {},
        AI38672732: {},
        "Frame 37438672730": {},
        image38672734: {},
        "AR / VR38672735": {},
        "Frame 37338672733": {},
        image38672737: { width: "50px", height: "50px" },
        "Computer Vision38672738": { fontSize: "13px" },
        "Frame 37538672736": {},
        image38672740: { width: "50px", height: "50px" },
        LLMs38672741: { fontSize: "13px" },
        "Frame 37638672739": {},
        "Frame 421": {},
        image38622753: { width: "50px", height: "50px" },
        "Satellite Images38622754": { fontSize: "13px" },
        "Frame 37438622752": {},
        image38622756: { width: "50px", height: "50px" },
        Autonomous38622757: { fontSize: "13px" },
        "Frame 37338622755": {},
        image38622759: { width: "40px", height: "40px" },
        "Smart Cities38622760": { fontSize: "12px" },
        "Frame 37538622758": { padding: "20px 20px 20px 20px" },
        image38622762: {},
        Mobile38622763: {},
        "Frame 37638622761": {},
        "Frame 420": {},
        image38672744: {},
        "Satellite Images38672745": {},
        "Frame 37438672743": {},
        image38672747: {},
        Autonomous38672748: {},
        "Frame 37338672746": {},
        image38672750: { width: "50px", height: "50px" },
        "Smart Cities38672751": { fontSize: "13px" },
        "Frame 37538672749": {},
        image38672753: { width: "50px", height: "50px" },
        Mobile38672754: { fontSize: "13px" },
        "Frame 37638672752": {},
        "Frame 422": {},
        "Frame 430": {},
        "Main Body38622730": { width: "380px" },
        "Type Lock Up": {},
        AboutLiamResponsive: { width: "480px" },
      },
    },
    {
      variantValues: { breakpoint: "base" },
      overrides: {
        "About Liam": {},
        "Working in Innovation 1": {},
        "Frame 418": {},
        "I\u2019m a Senior Software Engineer within the Innovation team at Kainos. I work to identify business value in emerging technologies, implement them and then share these insights with the rest of the company.":
          {},
        "I often get to try some of the latest advances in technology and then come up with innovative solutions that solve difficult problems for the company\u2019s customers - such as the UK Government.":
          {},
        "Frame 442": {},
        "Main Body38622734": {},
        image38622740: {},
        AI38622741: {},
        "Frame 37438622739": {},
        image38622743: {},
        "AR / VR38622744": {},
        "Frame 37338622742": {},
        image38622746: {},
        "Computer Vision38622747": {},
        "Frame 37538622745": {},
        image38622749: {},
        LLMs38622750: {},
        "Frame 37638622748": {},
        "Frame 395": {},
        image38672731: {},
        AI38672732: {},
        "Frame 37438672730": {},
        image38672734: {},
        "AR / VR38672735": {},
        "Frame 37338672733": {},
        image38672737: {},
        "Computer Vision38672738": {},
        "Frame 37538672736": {},
        image38672740: {},
        LLMs38672741: {},
        "Frame 37638672739": {},
        "Frame 421": {},
        image38622753: {},
        "Satellite Images38622754": {},
        "Frame 37438622752": {},
        image38622756: {},
        Autonomous38622757: {},
        "Frame 37338622755": {},
        image38622759: {},
        "Smart Cities38622760": {},
        "Frame 37538622758": {},
        image38622762: {},
        Mobile38622763: {},
        "Frame 37638622761": {},
        "Frame 420": {},
        image38672744: {},
        "Satellite Images38672745": {},
        "Frame 37438672743": {},
        image38672747: {},
        Autonomous38672748: {},
        "Frame 37338672746": {},
        image38672750: {},
        "Smart Cities38672751": {},
        "Frame 37538672749": {},
        image38672753: {},
        Mobile38672754: {},
        "Frame 37638672752": {},
        "Frame 422": {},
        "Frame 430": {},
        "Main Body38622730": {},
        "Type Lock Up": {},
        AboutLiamResponsive: {},
      },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "base",
    small: "small",
    medium: "medium",
    large: "large",
    xl: "xl",
    xxl: "xxl",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  return (
    <Flex
      gap="10px"
      direction="column"
      width="380px"
      height="unset"
      justifyContent="center"
      alignItems="center"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(245,245,247,1)"
      display="flex"
      {...getOverrideProps(overrides, "AboutLiamResponsive")}
      {...rest}
    >
      <Flex
        gap="0"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="100px 50px 100px 50px"
        display="flex"
        {...getOverrideProps(overrides, "Type Lock Up")}
      >
        <Flex
          gap="16px"
          direction="column"
          width="280px"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Main Body38622730")}
        >
          <Text
            fontFamily="Inter"
            fontSize="14px"
            fontWeight="700"
            color="rgba(51,51,51,1)"
            lineHeight="0px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="About Liam"
            {...getOverrideProps(overrides, "About Liam")}
          ></Text>
          <Flex
            gap="0"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="5px 0px 5px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 418")}
          >
            <Image
              width="220px"
              height="20.43px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              objectFit="cover"
              src="https://personal-website-liam.s3.eu-west-1.amazonaws.com/public/Working+in+Innovation+(3).png"
              {...getOverrideProps(overrides, "Working in Innovation 1")}
            ></Image>
          </Flex>
          <Flex
            gap="16px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Main Body38622734")}
          >
            <Flex
              gap="16px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              grow="1"
              shrink="1"
              basis="0"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 442")}
            >
              <Text
                fontFamily="Inter"
                fontSize="13px"
                fontWeight="400"
                color="rgba(0,0,0,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="I’m a Senior Software Engineer within the Innovation team at Kainos. I work to identify business value in emerging technologies, implement them and then share these insights with the rest of the company."
                {...getOverrideProps(
                  overrides,
                  "I\u2019m a Senior Software Engineer within the Innovation team at Kainos. I work to identify business value in emerging technologies, implement them and then share these insights with the rest of the company."
                )}
              ></Text>
              <Text
                fontFamily="Inter"
                fontSize="13px"
                fontWeight="400"
                color="rgba(0,0,0,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="I often get to try some of the latest advances in technology and then come up with innovative solutions that solve difficult problems for the company’s customers - such as the UK Government."
                {...getOverrideProps(
                  overrides,
                  "I often get to try some of the latest advances in technology and then come up with innovative solutions that solve difficult problems for the company\u2019s customers - such as the UK Government."
                )}
              ></Text>
            </Flex>
          </Flex>
          <Flex
            gap="16px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="30px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 430")}
          >
            <Flex
              gap="20px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 395")}
            >
              <Flex
                gap="16px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                grow="1"
                shrink="1"
                basis="0"
                alignSelf="stretch"
                position="relative"
                borderRadius="20px"
                padding="20px 20px 20px 20px"
                backgroundColor="rgba(255,255,255,1)"
                display="flex"
                {...getOverrideProps(overrides, "Frame 37438622739")}
              >
                <Image
                  width="40px"
                  height="40px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  objectFit="cover"
                  src="https://personal-website-liam.s3.eu-west-1.amazonaws.com/public/ai-icon.png"
                  {...getOverrideProps(overrides, "image38622740")}
                ></Image>
                <Text
                  fontFamily="Inter"
                  fontSize="12px"
                  fontWeight="600"
                  color="rgba(0,0,0,1)"
                  lineHeight="16px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="AI"
                  {...getOverrideProps(overrides, "AI38622741")}
                ></Text>
              </Flex>
              <Flex
                gap="16px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                grow="1"
                shrink="1"
                basis="0"
                alignSelf="stretch"
                position="relative"
                borderRadius="20px"
                padding="20px 20px 20px 20px"
                backgroundColor="rgba(255,255,255,1)"
                display="flex"
                {...getOverrideProps(overrides, "Frame 37338622742")}
              >
                <Image
                  width="40px"
                  height="40px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  objectFit="cover"
                  src="https://personal-website-liam.s3.eu-west-1.amazonaws.com/public/virtual-reality-glasses.png"
                  {...getOverrideProps(overrides, "image38622743")}
                ></Image>
                <Text
                  fontFamily="Inter"
                  fontSize="12px"
                  fontWeight="600"
                  color="rgba(0,0,0,1)"
                  lineHeight="16px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="AR / VR"
                  {...getOverrideProps(overrides, "AR / VR38622744")}
                ></Text>
              </Flex>
              <Flex
                gap="16px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                grow="1"
                shrink="1"
                basis="0"
                alignSelf="stretch"
                position="relative"
                borderRadius="20px"
                padding="0px 20px 0px 20px"
                backgroundColor="rgba(255,255,255,1)"
                display="none"
                {...getOverrideProps(overrides, "Frame 37538622745")}
              >
                <Image
                  width="30px"
                  height="30px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  objectFit="cover"
                  src="https://personal-website-liam.s3.eu-west-1.amazonaws.com/public/facial-recognition.png"
                  {...getOverrideProps(overrides, "image38622746")}
                ></Image>
                <Text
                  fontFamily="Inter"
                  fontSize="12px"
                  fontWeight="600"
                  color="rgba(0,0,0,1)"
                  lineHeight="16px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Computer &#xA;Vision"
                  {...getOverrideProps(overrides, "Computer Vision38622747")}
                ></Text>
              </Flex>
              <Flex
                gap="16px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                grow="1"
                shrink="1"
                basis="0"
                alignSelf="stretch"
                position="relative"
                borderRadius="20px"
                padding="0px 20px 0px 20px"
                backgroundColor="rgba(255,255,255,1)"
                display="none"
                {...getOverrideProps(overrides, "Frame 37638622748")}
              >
                <Image
                  width="30px"
                  height="30px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  objectFit="cover"
                  src="https://personal-website-liam.s3.eu-west-1.amazonaws.com/public/openai-logomark.png"
                  {...getOverrideProps(overrides, "image38622749")}
                ></Image>
                <Text
                  fontFamily="Inter"
                  fontSize="12px"
                  fontWeight="600"
                  color="rgba(0,0,0,1)"
                  lineHeight="16px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="LLMs"
                  {...getOverrideProps(overrides, "LLMs38622750")}
                ></Text>
              </Flex>
            </Flex>
            <Flex
              gap="20px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 421")}
            >
              <Flex
                gap="16px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                grow="1"
                shrink="1"
                basis="0"
                alignSelf="stretch"
                position="relative"
                borderRadius="20px"
                padding="20px 20px 20px 20px"
                backgroundColor="rgba(255,255,255,1)"
                display="none"
                {...getOverrideProps(overrides, "Frame 37438672730")}
              >
                <Image
                  width="40px"
                  height="40px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  objectFit="cover"
                  src="https://cdn-icons-png.flaticon.com/512/3314/3314608.png"
                  {...getOverrideProps(overrides, "image38672731")}
                ></Image>
                <Text
                  fontFamily="Inter"
                  fontSize="12px"
                  fontWeight="600"
                  color="rgba(0,0,0,1)"
                  lineHeight="16px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="AI"
                  {...getOverrideProps(overrides, "AI38672732")}
                ></Text>
              </Flex>
              <Flex
                gap="16px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                grow="1"
                shrink="1"
                basis="0"
                alignSelf="stretch"
                position="relative"
                borderRadius="20px"
                padding="20px 20px 20px 20px"
                backgroundColor="rgba(255,255,255,1)"
                display="none"
                {...getOverrideProps(overrides, "Frame 37338672733")}
              >
                <Image
                  width="40px"
                  height="40px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  objectFit="cover"
                  src="https://personal-website-liam.s3.eu-west-1.amazonaws.com/public/virtual-reality-glasses.png"
                  {...getOverrideProps(overrides, "image38672734")}
                ></Image>
                <Text
                  fontFamily="Inter"
                  fontSize="12px"
                  fontWeight="600"
                  color="rgba(0,0,0,1)"
                  lineHeight="16px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="AR / VR"
                  {...getOverrideProps(overrides, "AR / VR38672735")}
                ></Text>
              </Flex>
              <Flex
                gap="16px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                grow="1"
                shrink="1"
                basis="0"
                alignSelf="stretch"
                position="relative"
                borderRadius="20px"
                padding="20px 20px 20px 20px"
                backgroundColor="rgba(255,255,255,1)"
                display="flex"
                {...getOverrideProps(overrides, "Frame 37538672736")}
              >
                <Image
                  width="40px"
                  height="40px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  objectFit="cover"
                  src="https://personal-website-liam.s3.eu-west-1.amazonaws.com/public/facial-recognition.png"
                  {...getOverrideProps(overrides, "image38672737")}
                ></Image>
                <Text
                  fontFamily="Inter"
                  fontSize="11px"
                  fontWeight="600"
                  color="rgba(0,0,0,1)"
                  lineHeight="16px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Computer Vision"
                  {...getOverrideProps(overrides, "Computer Vision38672738")}
                ></Text>
              </Flex>
              <Flex
                gap="16px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                grow="1"
                shrink="1"
                basis="0"
                alignSelf="stretch"
                position="relative"
                borderRadius="20px"
                padding="20px 20px 20px 20px"
                backgroundColor="rgba(255,255,255,1)"
                display="flex"
                {...getOverrideProps(overrides, "Frame 37638672739")}
              >
                <Image
                  width="40px"
                  height="40px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  objectFit="cover"
                  src="https://logosandtypes.com/wp-content/uploads/2022/07/openai.svg"
                  {...getOverrideProps(overrides, "image38672740")}
                ></Image>
                <Text
                  fontFamily="Inter"
                  fontSize="12px"
                  fontWeight="600"
                  color="rgba(0,0,0,1)"
                  lineHeight="16px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="LLMs"
                  {...getOverrideProps(overrides, "LLMs38672741")}
                ></Text>
              </Flex>
            </Flex>
            <Flex
              gap="16px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 420")}
            >
              <Flex
                gap="16px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                grow="1"
                shrink="1"
                basis="0"
                alignSelf="stretch"
                position="relative"
                borderRadius="20px"
                padding="20px 20px 20px 20px"
                backgroundColor="rgba(255,255,255,1)"
                display="flex"
                {...getOverrideProps(overrides, "Frame 37438622752")}
              >
                <Image
                  width="40px"
                  height="40px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  objectFit="cover"
                  src="https://personal-website-liam.s3.eu-west-1.amazonaws.com/public/satellite.png"
                  {...getOverrideProps(overrides, "image38622753")}
                ></Image>
                <Text
                  fontFamily="Inter"
                  fontSize="11px"
                  fontWeight="600"
                  color="rgba(0,0,0,1)"
                  lineHeight="16px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Satellite Images"
                  {...getOverrideProps(overrides, "Satellite Images38622754")}
                ></Text>
              </Flex>
              <Flex
                gap="16px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                grow="1"
                shrink="1"
                basis="0"
                alignSelf="stretch"
                position="relative"
                borderRadius="20px"
                padding="20px 20px 20px 20px"
                backgroundColor="rgba(255,255,255,1)"
                display="flex"
                {...getOverrideProps(overrides, "Frame 37338622755")}
              >
                <Image
                  width="40px"
                  height="40px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  objectFit="cover"
                  src="https://personal-website-liam.s3.eu-west-1.amazonaws.com/public/smart-car.png"
                  {...getOverrideProps(overrides, "image38622756")}
                ></Image>
                <Text
                  fontFamily="Inter"
                  fontSize="12px"
                  fontWeight="600"
                  color="rgba(0,0,0,1)"
                  lineHeight="16px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Autonomous"
                  {...getOverrideProps(overrides, "Autonomous38622757")}
                ></Text>
              </Flex>
              <Flex
                gap="16px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                grow="1"
                shrink="1"
                basis="0"
                alignSelf="stretch"
                position="relative"
                borderRadius="20px"
                padding="40px 50px 40px 50px"
                backgroundColor="rgba(255,255,255,1)"
                display="none"
                {...getOverrideProps(overrides, "Frame 37538622758")}
              >
                <Image
                  width="50px"
                  height="50px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  objectFit="cover"
                  src="https://personal-website-liam.s3.eu-west-1.amazonaws.com/public/smart-city.png"
                  {...getOverrideProps(overrides, "image38622759")}
                ></Image>
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="600"
                  color="rgba(0,0,0,1)"
                  lineHeight="16px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Smart Cities"
                  {...getOverrideProps(overrides, "Smart Cities38622760")}
                ></Text>
              </Flex>
              <Flex
                gap="16px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                grow="1"
                shrink="1"
                basis="0"
                alignSelf="stretch"
                position="relative"
                borderRadius="20px"
                padding="40px 50px 40px 50px"
                backgroundColor="rgba(255,255,255,1)"
                display="none"
                {...getOverrideProps(overrides, "Frame 37638622761")}
              >
                <Image
                  width="50px"
                  height="50px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  objectFit="cover"
                  src="https://personal-website-liam.s3.eu-west-1.amazonaws.com/public/smartphone.png"
                  {...getOverrideProps(overrides, "image38622762")}
                ></Image>
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="600"
                  color="rgba(0,0,0,1)"
                  lineHeight="16px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Mobile"
                  {...getOverrideProps(overrides, "Mobile38622763")}
                ></Text>
              </Flex>
            </Flex>
            <Flex
              gap="16px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 422")}
            >
              <Flex
                gap="16px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                grow="1"
                shrink="1"
                basis="0"
                alignSelf="stretch"
                position="relative"
                borderRadius="20px"
                padding="40px 50px 40px 50px"
                backgroundColor="rgba(255,255,255,1)"
                display="none"
                {...getOverrideProps(overrides, "Frame 37438672743")}
              >
                <Image
                  width="50px"
                  height="50px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  objectFit="cover"
                  src="https://personal-website-liam.s3.eu-west-1.amazonaws.com/public/satellite.png"
                  {...getOverrideProps(overrides, "image38672744")}
                ></Image>
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="600"
                  color="rgba(0,0,0,1)"
                  lineHeight="16px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Satellite Images"
                  {...getOverrideProps(overrides, "Satellite Images38672745")}
                ></Text>
              </Flex>
              <Flex
                gap="16px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                grow="1"
                shrink="1"
                basis="0"
                alignSelf="stretch"
                position="relative"
                borderRadius="20px"
                padding="40px 50px 40px 50px"
                backgroundColor="rgba(255,255,255,1)"
                display="none"
                {...getOverrideProps(overrides, "Frame 37338672746")}
              >
                <Image
                  width="50px"
                  height="50px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  objectFit="cover"
                  src="https://personal-website-liam.s3.eu-west-1.amazonaws.com/public/smart-car.png"
                  {...getOverrideProps(overrides, "image38672747")}
                ></Image>
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="600"
                  color="rgba(0,0,0,1)"
                  lineHeight="16px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Autonomous"
                  {...getOverrideProps(overrides, "Autonomous38672748")}
                ></Text>
              </Flex>
              <Flex
                gap="16px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                grow="1"
                shrink="1"
                basis="0"
                alignSelf="stretch"
                position="relative"
                borderRadius="20px"
                padding="20px 20px 20px 20px"
                backgroundColor="rgba(255,255,255,1)"
                display="flex"
                {...getOverrideProps(overrides, "Frame 37538672749")}
              >
                <Image
                  width="40px"
                  height="40px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  objectFit="cover"
                  src="https://personal-website-liam.s3.eu-west-1.amazonaws.com/public/smart-city.png"
                  {...getOverrideProps(overrides, "image38672750")}
                ></Image>
                <Text
                  fontFamily="Inter"
                  fontSize="12px"
                  fontWeight="600"
                  color="rgba(0,0,0,1)"
                  lineHeight="16px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Smart Cities"
                  {...getOverrideProps(overrides, "Smart Cities38672751")}
                ></Text>
              </Flex>
              <Flex
                gap="16px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                grow="1"
                shrink="1"
                basis="0"
                alignSelf="stretch"
                position="relative"
                borderRadius="20px"
                padding="20px 20px 20px 20px"
                backgroundColor="rgba(255,255,255,1)"
                display="flex"
                {...getOverrideProps(overrides, "Frame 37638672752")}
              >
                <Image
                  width="40px"
                  height="40px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  objectFit="cover"
                  src="https://personal-website-liam.s3.eu-west-1.amazonaws.com/public/smartphone.png"
                  {...getOverrideProps(overrides, "image38672753")}
                ></Image>
                <Text
                  fontFamily="Inter"
                  fontSize="12px"
                  fontWeight="600"
                  color="rgba(0,0,0,1)"
                  lineHeight="16px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Mobile"
                  {...getOverrideProps(overrides, "Mobile38672754")}
                ></Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
