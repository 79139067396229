import React, { useEffect } from 'react';
import { NavigationBarResponsive, IntroductionResponsive, AboutLiamResponsive, AchievementsResponsive, ExperienceResponsive, ContactResponsive, HomeFooterResponsive, GalleryResponsive, Gallery2Responsive }  from '../ui-components';

const HomePage = () => {
    useEffect(() => {
        document.title = 'Home - Liam McCormick';
    }, []);

    return (
        <div>
            <NavigationBarResponsive width={"100%"}/>
            <IntroductionResponsive width={"100%"} />
            <AboutLiamResponsive width={"100%"}/>
            {/* <GalleryResponsive width={"100%"} /> */}
            <Gallery2Responsive width={"100%"} />
            <AchievementsResponsive width={"100%"} />
            <ExperienceResponsive width={"100%"} />
            <ContactResponsive width={"100%"} />
            <HomeFooterResponsive width={"100%"} />
        </div>
    );
}

export default HomePage;