import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomePage from './pages/homePage';
import BlogPage from './pages/blogPage';
import BidenPage from './pages/bidenPage';
import ProjectsPage from './pages/projectsPage';
import MicrosoftPage from './pages/microsoftPage';
import MinisterPage from './pages/ministerPage';
import UniAwardsPage from './pages/uniAwardsPage';

function App() {
  return (
    <BrowserRouter>
      <div>
        <Routes>
          <Route path="/" element={<HomePage/>} />
          <Route path="/blog" element={<BlogPage/>} />
          <Route path="/projects" element={<ProjectsPage/>} />
          <Route path="/president-biden" element={<BidenPage/>} />
          <Route path="/uk-cabinet-minister" element={<MinisterPage/>} />
          <Route path="/microsoft-uk-office" element={<MicrosoftPage/>} />
          <Route path="/uu-awards-ceremony" element={<UniAwardsPage/>} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
