/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import { Flex, Image, Text, useBreakpointValue } from "@aws-amplify/ui-react";
export default function WorkInProgressResponsive(props) {
  const { overrides: overridesProp, ...restProp } = props;
  const variants = [
    {
      variantValues: { breakpoint: "xxl" },
      overrides: {
        "Work in Progress": { fontSize: "48px" },
        "Frame 449": {},
        "work-in-progress 1": { width: "400px", height: "400px" },
        "Frame 424": {},
        "Frame 136": {},
        WorkInProgressResponsive: { width: "1536px" },
      },
    },
    {
      variantValues: { breakpoint: "xl" },
      overrides: {
        "Work in Progress": { fontSize: "48px" },
        "Frame 449": {},
        "work-in-progress 1": { width: "400px", height: "400px" },
        "Frame 424": {},
        "Frame 136": {},
        WorkInProgressResponsive: { width: "1280px" },
      },
    },
    {
      variantValues: { breakpoint: "large" },
      overrides: {
        "Work in Progress": { fontSize: "40px" },
        "Frame 449": {},
        "work-in-progress 1": { width: "400px", height: "400px" },
        "Frame 424": {},
        "Frame 136": {},
        WorkInProgressResponsive: { width: "992px" },
      },
    },
    {
      variantValues: { breakpoint: "medium" },
      overrides: {
        "Work in Progress": { fontSize: "36px" },
        "Frame 449": {},
        "work-in-progress 1": { width: "350px", height: "350px" },
        "Frame 424": {},
        "Frame 136": {},
        WorkInProgressResponsive: { width: "768px" },
      },
    },
    {
      variantValues: { breakpoint: "small" },
      overrides: {
        "Work in Progress": {},
        "Frame 449": {},
        "work-in-progress 1": {},
        "Frame 424": {},
        "Frame 136": {},
        WorkInProgressResponsive: { width: "480px" },
      },
    },
    {
      variantValues: { breakpoint: "base" },
      overrides: {
        "Work in Progress": {},
        "Frame 449": {},
        "work-in-progress 1": {},
        "Frame 424": {},
        "Frame 136": {},
        WorkInProgressResponsive: {},
      },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "base",
    small: "small",
    medium: "medium",
    large: "large",
    xl: "xl",
    xxl: "xxl",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  return (
    <Flex
      gap="0"
      direction="column"
      width="380px"
      height="unset"
      justifyContent="center"
      alignItems="center"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(239,240,240,1)"
      display="flex"
      {...getOverrideProps(overrides, "WorkInProgressResponsive")}
      {...rest}
    >
      <Flex
        gap="24px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="70px 0px 50px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Frame 136")}
      >
        <Flex
          gap="10px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="20px 0px 20px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 449")}
        >
          <Text
            fontFamily="Inter"
            fontSize="32px"
            fontWeight="600"
            color="rgba(0,0,0,1)"
            lineHeight="20px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            grow="1"
            shrink="1"
            basis="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Work in Progress"
            {...getOverrideProps(overrides, "Work in Progress")}
          ></Text>
        </Flex>
        <Flex
          gap="30px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="20px 50px 20px 50px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 424")}
        >
          <Image
            width="280px"
            height="280px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            borderRadius="20px"
            padding="0px 0px 0px 0px"
            objectFit="cover"
            src="https://personal-website-liam.s3.eu-west-1.amazonaws.com/public/work-in-progress.jpg"
            {...getOverrideProps(overrides, "work-in-progress 1")}
          ></Image>
        </Flex>
      </Flex>
    </Flex>
  );
}
