/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
  useNavigateAction,
} from "@aws-amplify/ui-react/internal";
import { Flex, Image, Text, useBreakpointValue } from "@aws-amplify/ui-react";
export default function UkMinisterResponsive(props) {
  const { overrides: overridesProp, ...restProp } = props;
  const variants = [
    {
      variantValues: { breakpoint: "small" },
      overrides: {
        "Meeting with UK Cabinet Minister": { fontSize: "36px" },
        TitleFrame: { padding: "20px 50px 20px 50px" },
        "liam-uk-minister": { height: "269.77px" },
        "Frame 449": { height: "369.77px" },
        "I was pleased to have met with UK Cabinet Minister, Conor Burns (Northern Ireland Office), at the Belfast office.":
          { fontSize: "14px" },
        "I was joined by Holly Toner and we presented to the Minister just some of the interesting work that we do within the Innovation team.":
          { fontSize: "14px" },
        "My most-recent Computer Vision work with DVSA (UK Government department) was showcased to the Minister - along with how our team use the latest advances in technology to solve difficult problems.":
          { fontSize: "14px" },
        "Frame 458": {},
        "Frame 136": { width: "380px" },
        "Read more:": { fontSize: "20px", letterSpacing: "0.05px" },
        "kainos-uk-minister-linkedin": { width: "380px", height: "150.34px" },
        "Frame 461": { gap: "24px" },
        "Frame 451": { width: "380px" },
        UkMinisterResponsive: { width: "480px" },
      },
    },
    {
      variantValues: { breakpoint: "medium" },
      overrides: {
        "Meeting with UK Cabinet Minister": { fontSize: "36px" },
        TitleFrame: { padding: "20px 50px 20px 50px" },
        "liam-uk-minister": { height: "431.63px" },
        "Frame 449": { height: "531.63px" },
        "I was pleased to have met with UK Cabinet Minister, Conor Burns (Northern Ireland Office), at the Belfast office.":
          { fontSize: "14px" },
        "I was joined by Holly Toner and we presented to the Minister just some of the interesting work that we do within the Innovation team.":
          { fontSize: "14px" },
        "My most-recent Computer Vision work with DVSA (UK Government department) was showcased to the Minister - along with how our team use the latest advances in technology to solve difficult problems.":
          { fontSize: "14px" },
        "Frame 458": {},
        "Frame 136": { width: "520px" },
        "Read more:": { fontSize: "24px", letterSpacing: "0.09px" },
        "kainos-uk-minister-linkedin": {
          width: "520px",
          height: "205.74px",
          borderRadius: "20px",
        },
        "Frame 461": { gap: "24px", padding: "50px 0px 0px 0px" },
        "Frame 451": { width: "520px" },
        UkMinisterResponsive: { width: "768px" },
      },
    },
    {
      variantValues: { breakpoint: "large" },
      overrides: {
        "Meeting with UK Cabinet Minister": { fontSize: "40px" },
        TitleFrame: { padding: "20px 50px 20px 50px" },
        "liam-uk-minister": { height: "557.52px" },
        "Frame 449": { height: "657.52px" },
        "I was pleased to have met with UK Cabinet Minister, Conor Burns (Northern Ireland Office), at the Belfast office.":
          { fontSize: "14px" },
        "I was joined by Holly Toner and we presented to the Minister just some of the interesting work that we do within the Innovation team.":
          { fontSize: "14px" },
        "My most-recent Computer Vision work with DVSA (UK Government department) was showcased to the Minister - along with how our team use the latest advances in technology to solve difficult problems.":
          { fontSize: "14px" },
        "Frame 458": {},
        "Frame 136": { width: "650px" },
        "Read more:": { fontSize: "24px", letterSpacing: "0.09px" },
        "kainos-uk-minister-linkedin": {
          width: "650px",
          height: "257.17px",
          borderRadius: "20px",
        },
        "Frame 461": { gap: "32px", padding: "50px 0px 0px 0px" },
        "Frame 451": { width: "650px" },
        UkMinisterResponsive: { width: "992px" },
      },
    },
    {
      variantValues: { breakpoint: "xl" },
      overrides: {
        "Meeting with UK Cabinet Minister": { fontSize: "48px" },
        TitleFrame: { padding: "20px 50px 20px 50px" },
        "liam-uk-minister": { height: "719.38px" },
        "Frame 449": { height: "819.38px" },
        "I was pleased to have met with UK Cabinet Minister, Conor Burns (Northern Ireland Office), at the Belfast office.":
          { fontSize: "16px", letterSpacing: "0.01px" },
        "I was joined by Holly Toner and we presented to the Minister just some of the interesting work that we do within the Innovation team.":
          { fontSize: "16px", letterSpacing: "0.01px" },
        "My most-recent Computer Vision work with DVSA (UK Government department) was showcased to the Minister - along with how our team use the latest advances in technology to solve difficult problems.":
          { fontSize: "16px", letterSpacing: "0.01px" },
        "Frame 458": {},
        "Frame 136": { width: "800px" },
        "Read more:": { fontSize: "24px", letterSpacing: "0.09px" },
        "kainos-uk-minister-linkedin": {
          width: "800px",
          height: "316.52px",
          borderRadius: "20px",
        },
        "Frame 461": { gap: "32px", padding: "50px 0px 0px 0px" },
        "Frame 451": { width: "800px" },
        UkMinisterResponsive: { width: "1280px" },
      },
    },
    {
      variantValues: { breakpoint: "xxl" },
      overrides: {
        "Meeting with UK Cabinet Minister": { fontSize: "48px" },
        TitleFrame: { padding: "20px 50px 20px 50px" },
        "liam-uk-minister": { width: "1536px", height: "863.25px" },
        "Frame 449": { height: "963.25px" },
        "I was pleased to have met with UK Cabinet Minister, Conor Burns (Northern Ireland Office), at the Belfast office.":
          { fontSize: "16px", letterSpacing: "0.01px" },
        "I was joined by Holly Toner and we presented to the Minister just some of the interesting work that we do within the Innovation team.":
          { fontSize: "16px", letterSpacing: "0.01px" },
        "My most-recent Computer Vision work with DVSA (UK Government department) was showcased to the Minister - along with how our team use the latest advances in technology to solve difficult problems.":
          { fontSize: "16px", letterSpacing: "0.01px" },
        "Frame 458": {},
        "Frame 136": { width: "800px" },
        "Read more:": { fontSize: "24px", letterSpacing: "0.09px" },
        "kainos-uk-minister-linkedin": {
          width: "800px",
          height: "316.52px",
          borderRadius: "20px",
        },
        "Frame 461": { gap: "32px", padding: "50px 0px 0px 0px" },
        "Frame 451": { width: "800px" },
        UkMinisterResponsive: { width: "1536px" },
      },
    },
    {
      variantValues: { breakpoint: "base" },
      overrides: {
        "Meeting with UK Cabinet Minister": {},
        TitleFrame: {},
        "liam-uk-minister": {},
        "Frame 449": {},
        "I was pleased to have met with UK Cabinet Minister, Conor Burns (Northern Ireland Office), at the Belfast office.":
          {},
        "I was joined by Holly Toner and we presented to the Minister just some of the interesting work that we do within the Innovation team.":
          {},
        "My most-recent Computer Vision work with DVSA (UK Government department) was showcased to the Minister - along with how our team use the latest advances in technology to solve difficult problems.":
          {},
        "Frame 458": {},
        "Frame 136": {},
        "Read more:": {},
        "kainos-uk-minister-linkedin": {},
        "Frame 461": {},
        "Frame 451": {},
        UkMinisterResponsive: {},
      },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "base",
    small: "small",
    medium: "medium",
    large: "large",
    xl: "xl",
    xxl: "xxl",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  const kainosukministerlinkedinOnClick = useNavigateAction({
    type: "url",
    url: "https://www.linkedin.com/posts/kainos_innovation-skills-activity-6948684159246598144-Mto9?utm_source=share&utm_medium=member_desktop",
    target: "_blank",
  });
  return (
    <Flex
      gap="0"
      direction="column"
      width="380px"
      height="unset"
      justifyContent="center"
      alignItems="center"
      position="relative"
      padding="80px 0px 80px 0px"
      backgroundColor="rgba(245,245,247,1)"
      display="flex"
      {...getOverrideProps(overrides, "UkMinisterResponsive")}
      {...rest}
    >
      <Flex
        gap="10px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 50px 0px 50px"
        display="flex"
        {...getOverrideProps(overrides, "TitleFrame")}
      >
        <Text
          fontFamily="Inter"
          fontSize="32px"
          fontWeight="600"
          color="rgba(0,0,0,1)"
          lineHeight="35px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Meeting with UK Cabinet Minister"
          {...getOverrideProps(overrides, "Meeting with UK Cabinet Minister")}
        ></Text>
      </Flex>
      <Flex
        gap="10px"
        direction="column"
        width="unset"
        height="313.56px"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="50px 0px 50px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Frame 449")}
      >
        <Image
          width="unset"
          height="213.56px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          objectFit="cover"
          src="https://personal-website-liam.s3.eu-west-1.amazonaws.com/public/liam-uk-minister.JPG"
          {...getOverrideProps(overrides, "liam-uk-minister")}
        ></Image>
      </Flex>
      <Flex
        gap="24px"
        direction="column"
        width="300px"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Frame 136")}
      >
        <Flex
          gap="16px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 458")}
        >
          <Text
            fontFamily="Inter"
            fontSize="13px"
            fontWeight="400"
            color="rgba(0,0,0,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="I was pleased to have met with UK Cabinet Minister, Conor Burns (Northern Ireland Office), at the Belfast office."
            {...getOverrideProps(
              overrides,
              "I was pleased to have met with UK Cabinet Minister, Conor Burns (Northern Ireland Office), at the Belfast office."
            )}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="13px"
            fontWeight="400"
            color="rgba(0,0,0,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="I was joined by Holly Toner and we presented to the Minister just some of the interesting work that we do within the Innovation team."
            {...getOverrideProps(
              overrides,
              "I was joined by Holly Toner and we presented to the Minister just some of the interesting work that we do within the Innovation team."
            )}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="13px"
            fontWeight="400"
            color="rgba(0,0,0,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="My most-recent Computer Vision work with DVSA (UK Government department) was showcased to the Minister - along with how our team use the latest advances in technology to solve difficult problems."
            {...getOverrideProps(
              overrides,
              "My most-recent Computer Vision work with DVSA (UK Government department) was showcased to the Minister - along with how our team use the latest advances in technology to solve difficult problems."
            )}
          ></Text>
        </Flex>
      </Flex>
      <Flex
        gap="24px"
        direction="column"
        width="300px"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Frame 451")}
      >
        <Flex
          gap="16px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="30px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 461")}
        >
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="700"
            color="rgba(0,0,0,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0.01px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Read more:"
            {...getOverrideProps(overrides, "Read more:")}
          ></Text>
          <Image
            width="300px"
            height="118.69px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            borderRadius="15px"
            padding="0px 0px 0px 0px"
            objectFit="cover"
            src="https://personal-website-liam.s3.eu-west-1.amazonaws.com/public/kainos-uk-minister-linkedin.png"
            onClick={() => {
              kainosukministerlinkedinOnClick();
            }}
            {...getOverrideProps(overrides, "kainos-uk-minister-linkedin")}
          ></Image>
        </Flex>
      </Flex>
    </Flex>
  );
}
