/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
  useNavigateAction,
} from "@aws-amplify/ui-react/internal";
import { Flex, Text, View, useBreakpointValue } from "@aws-amplify/ui-react";
export default function HomeFooterResponsive(props) {
  const { overrides: overridesProp, ...restProp } = props;
  const variants = [
    {
      variantValues: { breakpoint: "xxl" },
      overrides: {
        "Rectangle 738": { width: "1536px" },
        Attribution: { fontSize: "24px", letterSpacing: "0.09px" },
        "To find the source for each image, click on the links below:": {
          fontSize: "16px",
          letterSpacing: "0.01px",
        },
        AI: { fontSize: "16px", letterSpacing: "0.01px" },
        VR: { fontSize: "16px", letterSpacing: "0.01px" },
        CV: { fontSize: "16px", letterSpacing: "0.01px" },
        Satellite: { fontSize: "16px", letterSpacing: "0.01px" },
        Autonomous: { fontSize: "16px", letterSpacing: "0.01px" },
        "Smart city": { fontSize: "16px", letterSpacing: "0.01px" },
        iPhone: { fontSize: "16px", letterSpacing: "0.01px" },
        Biden: { fontSize: "16px", letterSpacing: "0.01px" },
        Biden2: { fontSize: "16px", letterSpacing: "0.01px" },
        Apple: { fontSize: "16px", letterSpacing: "0.01px" },
        OpenAI: { fontSize: "16px", letterSpacing: "0.01px" },
        AWS: { fontSize: "16px", letterSpacing: "0.01px" },
        Azure: { fontSize: "16px", letterSpacing: "0.01px" },
        Python: { fontSize: "16px", letterSpacing: "0.01px" },
        "C#": { fontSize: "16px", letterSpacing: "0.01px" },
        LinkedIn: { fontSize: "16px", letterSpacing: "0.01px" },
        Twitter: { fontSize: "16px", letterSpacing: "0.01px" },
        Medium: { fontSize: "16px", letterSpacing: "0.01px" },
        "Frame 474": {},
        "\u00A9 2023 Liam McCormick": {
          fontSize: "16px",
          letterSpacing: "0.01px",
        },
        "Frame 467": { gap: "30px", padding: "0px 70px 30px 70px" },
        HomeFooterResponsive: { width: "unset" },
      },
    },
    {
      variantValues: { breakpoint: "xl" },
      overrides: {
        "Rectangle 738": { width: "1280px" },
        Attribution: { fontSize: "24px", letterSpacing: "0.09px" },
        "To find the source for each image, click on the links below:": {
          fontSize: "16px",
          letterSpacing: "0.01px",
        },
        AI: { fontSize: "16px", letterSpacing: "0.01px" },
        VR: { fontSize: "16px", letterSpacing: "0.01px" },
        CV: { fontSize: "16px", letterSpacing: "0.01px" },
        Satellite: { fontSize: "16px", letterSpacing: "0.01px" },
        Autonomous: { fontSize: "16px", letterSpacing: "0.01px" },
        "Smart city": { fontSize: "16px", letterSpacing: "0.01px" },
        iPhone: { fontSize: "16px", letterSpacing: "0.01px" },
        Biden: { fontSize: "16px", letterSpacing: "0.01px" },
        Biden2: { fontSize: "16px", letterSpacing: "0.01px" },
        Apple: { fontSize: "16px", letterSpacing: "0.01px" },
        OpenAI: { fontSize: "16px", letterSpacing: "0.01px" },
        AWS: { fontSize: "16px", letterSpacing: "0.01px" },
        Azure: { fontSize: "16px", letterSpacing: "0.01px" },
        Python: { fontSize: "16px", letterSpacing: "0.01px" },
        "C#": { fontSize: "16px", letterSpacing: "0.01px" },
        LinkedIn: { fontSize: "16px", letterSpacing: "0.01px" },
        Twitter: { fontSize: "16px", letterSpacing: "0.01px" },
        Medium: { fontSize: "16px", letterSpacing: "0.01px" },
        "Frame 474": {},
        "\u00A9 2023 Liam McCormick": {
          fontSize: "16px",
          letterSpacing: "0.01px",
        },
        "Frame 467": { gap: "30px", padding: "0px 50px 30px 50px" },
        HomeFooterResponsive: { width: "unset" },
      },
    },
    {
      variantValues: { breakpoint: "large" },
      overrides: {
        "Rectangle 738": { width: "992px" },
        Attribution: {},
        "To find the source for each image, click on the links below:": {
          fontSize: "14px",
        },
        AI: { fontSize: "14px" },
        VR: { fontSize: "14px" },
        CV: { fontSize: "14px" },
        Satellite: { fontSize: "14px" },
        Autonomous: { fontSize: "14px" },
        "Smart city": { fontSize: "14px" },
        iPhone: { fontSize: "14px" },
        Biden: { fontSize: "14px" },
        Biden2: { fontSize: "14px" },
        Apple: { fontSize: "14px" },
        OpenAI: { fontSize: "14px" },
        AWS: { fontSize: "14px" },
        Azure: { fontSize: "14px" },
        Python: { fontSize: "14px" },
        "C#": { fontSize: "14px" },
        LinkedIn: { fontSize: "14px" },
        Twitter: { fontSize: "14px" },
        Medium: { fontSize: "14px" },
        "Frame 474": {},
        "\u00A9 2023 Liam McCormick": { fontSize: "14px" },
        "Frame 467": { gap: "25px", padding: "0px 60px 30px 60px" },
        HomeFooterResponsive: { width: "unset" },
      },
    },
    {
      variantValues: { breakpoint: "medium" },
      overrides: {
        "Rectangle 738": { width: "768px" },
        Attribution: {},
        "To find the source for each image, click on the links below:": {
          fontSize: "14px",
        },
        AI: { fontSize: "14px" },
        VR: { fontSize: "14px" },
        CV: { fontSize: "14px" },
        Satellite: { fontSize: "14px" },
        Autonomous: { fontSize: "14px" },
        "Smart city": { fontSize: "14px" },
        iPhone: { fontSize: "14px" },
        Biden: { fontSize: "14px" },
        Biden2: { fontSize: "14px" },
        Apple: { fontSize: "14px" },
        OpenAI: { fontSize: "14px" },
        AWS: { fontSize: "14px" },
        Azure: { fontSize: "14px" },
        Python: { fontSize: "14px" },
        "C#": { fontSize: "14px" },
        LinkedIn: { fontSize: "14px" },
        Twitter: { fontSize: "14px" },
        Medium: { fontSize: "14px" },
        "Frame 474": {},
        "\u00A9 2023 Liam McCormick": { fontSize: "14px" },
        "Frame 467": { gap: "25px", padding: "0px 40px 30px 40px" },
        HomeFooterResponsive: { width: "768px" },
      },
    },
    {
      variantValues: { breakpoint: "small" },
      overrides: {
        "Rectangle 738": { width: "480px" },
        Attribution: {},
        "To find the source for each image, click on the links below:": {},
        AI: {},
        VR: {},
        CV: {},
        Satellite: {},
        Autonomous: {},
        "Smart city": {},
        iPhone: {},
        Biden: {},
        Biden2: {},
        Apple: {},
        OpenAI: {},
        AWS: {},
        Azure: {},
        Python: {},
        "C#": {},
        LinkedIn: {},
        Twitter: {},
        Medium: {},
        "Frame 474": {},
        "\u00A9 2023 Liam McCormick": {},
        "Frame 467": { padding: "0px 30px 20px 30px" },
        HomeFooterResponsive: { width: "480px" },
      },
    },
    {
      variantValues: { breakpoint: "base" },
      overrides: {
        "Rectangle 738": {},
        Attribution: {},
        "To find the source for each image, click on the links below:": {},
        AI: {},
        VR: {},
        CV: {},
        Satellite: {},
        Autonomous: {},
        "Smart city": {},
        iPhone: {},
        Biden: {},
        Biden2: {},
        Apple: {},
        OpenAI: {},
        AWS: {},
        Azure: {},
        Python: {},
        "C#": {},
        LinkedIn: {},
        Twitter: {},
        Medium: {},
        "Frame 474": {},
        "\u00A9 2023 Liam McCormick": {},
        "Frame 467": {},
        HomeFooterResponsive: {},
      },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "base",
    small: "small",
    medium: "medium",
    large: "large",
    xl: "xl",
    xxl: "xxl",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  const aIOnClick = useNavigateAction({
    type: "url",
    url: "https://cdn-icons-png.flaticon.com/512/3314/3314608.png",
    target: "_blank",
  });
  const vROnClick = useNavigateAction({
    type: "url",
    url: "https://www.flaticon.com/free-icon/virtual-reality-glasses_10781896?k=1687647016394",
    target: "_blank",
  });
  const cVOnClick = useNavigateAction({
    type: "url",
    url: "https://www.flaticon.com/free-icon/facial-recognition_5371797?term=facial+recognition&page=1&position=10&origin=search&related_id=5371797",
    target: "_blank",
  });
  const satelliteOnClick = useNavigateAction({
    type: "url",
    url: "https://www.flaticon.com/free-icon/satellite_3115527?term=satellite&page=1&position=8&origin=search&related_id=3115527",
    target: "_blank",
  });
  const autonomousOnClick = useNavigateAction({
    type: "url",
    url: "https://www.flaticon.com/free-icon/smart-car_5785453?term=autonomous+vehicle&page=1&position=45&origin=search&related_id=5785453",
    target: "_blank",
  });
  const smartcityOnClick = useNavigateAction({
    type: "url",
    url: "https://www.flaticon.com/free-icon/smart-city_10144293?term=smart+city&page=2&position=33&origin=search&related_id=10144293",
    target: "_blank",
  });
  const iPhoneOnClick = useNavigateAction({
    type: "url",
    url: "https://www.flaticon.com/free-icon/smartphone_10514136?term=iphone&page=1&position=4&origin=search&related_id=10514136",
    target: "_blank",
  });
  const bidenOnClick = useNavigateAction({
    type: "url",
    url: "https://www.gettyimages.co.uk/detail/news-photo/attendees-listen-as-us-president-joe-biden-delivers-a-news-photo/1251746838",
    target: "_blank",
  });
  const bidenTwoOnClick = useNavigateAction({
    type: "url",
    url: "https://presseye.com/media/dqQsylTZTXIFq5FV96l-hA..a?ts=H_8VJt7qH7gVjSTjFCxWXj3PmrrhYYMIQ9RspCoJHiA.a",
    target: "_blank",
  });
  const appleOnClick = useNavigateAction({
    type: "url",
    url: "https://1000logos.net/wp-content/uploads/2016/10/Apple-Logo.png",
    target: "_blank",
  });
  const openAIOnClick = useNavigateAction({
    type: "url",
    url: "https://openai.com/brand#logos",
    target: "_blank",
  });
  const aWSOnClick = useNavigateAction({
    type: "url",
    url: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/93/Amazon_Web_Services_Logo.svg/1024px-Amazon_Web_Services_Logo.svg.png",
    target: "_blank",
  });
  const azureOnClick = useNavigateAction({
    type: "url",
    url: "https://www.i-finity.co.uk/media/qs4nkxbk/azure-new.png?width=1200&height=630",
    target: "_blank",
  });
  const pythonOnClick = useNavigateAction({
    type: "url",
    url: "https://logos-world.net/wp-content/uploads/2021/10/Python-Symbol.png",
    target: "_blank",
  });
  const cNmberOnClick = useNavigateAction({
    type: "url",
    url: "https://upload.wikimedia.org/wikipedia/commons/4/4f/Csharp_Logo.png",
    target: "_blank",
  });
  const linkedInOnClick = useNavigateAction({
    type: "url",
    url: "https://logos-world.net/wp-content/uploads/2020/06/Linkedin-Logo-2003.png",
    target: "_blank",
  });
  const twitterOnClick = useNavigateAction({
    type: "url",
    url: "https://assets.stickpng.com/images/580b57fcd9996e24bc43c53e.png",
    target: "_blank",
  });
  const mediumOnClick = useNavigateAction({
    type: "url",
    url: "https://download.logo.wine/logo/Medium_(website)/Medium_(website)-Logo.wine.png",
    target: "_blank",
  });
  return (
    <Flex
      gap="10px"
      direction="column"
      width="380px"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(245,245,247,1)"
      display="flex"
      {...getOverrideProps(overrides, "HomeFooterResponsive")}
      {...rest}
    >
      <View
        width="unset"
        height="55px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(245,245,247,1)"
        {...getOverrideProps(overrides, "Rectangle 738")}
      ></View>
      <Flex
        gap="20px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 10px 20px 10px"
        display="flex"
        {...getOverrideProps(overrides, "Frame 467")}
      >
        <Text
          fontFamily="Inter"
          fontSize="20px"
          fontWeight="700"
          color="rgba(0,0,0,1)"
          lineHeight="24px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          letterSpacing="0.05px"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Attribution"
          {...getOverrideProps(overrides, "Attribution")}
        ></Text>
        <Text
          fontFamily="Inter"
          fontSize="13px"
          fontWeight="400"
          color="rgba(0,0,0,1)"
          lineHeight="24px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          letterSpacing="0px"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="To find the source for each image, click on the links below:"
          {...getOverrideProps(
            overrides,
            "To find the source for each image, click on the links below:"
          )}
        ></Text>
        <Flex
          gap="2px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 474")}
        >
          <Text
            fontFamily="Inter"
            fontSize="13px"
            fontWeight="400"
            color="rgba(191,64,191,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="AI logo"
            onClick={() => {
              aIOnClick();
            }}
            {...getOverrideProps(overrides, "AI")}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="13px"
            fontWeight="400"
            color="rgba(191,64,191,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Vr glasses icons created by afif fudin - Flaticon"
            onClick={() => {
              vROnClick();
            }}
            {...getOverrideProps(overrides, "VR")}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="13px"
            fontWeight="400"
            color="rgba(191,64,191,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Facial recognition icons created by smashingstocks - Flaticon"
            onClick={() => {
              cVOnClick();
            }}
            {...getOverrideProps(overrides, "CV")}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="13px"
            fontWeight="400"
            color="rgba(191,64,191,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Satellite icons created by mattbadal - Flaticon"
            onClick={() => {
              satelliteOnClick();
            }}
            {...getOverrideProps(overrides, "Satellite")}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="13px"
            fontWeight="400"
            color="rgba(191,64,191,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Smart car icons created by PIXARTIST - Flaticon"
            onClick={() => {
              autonomousOnClick();
            }}
            {...getOverrideProps(overrides, "Autonomous")}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="13px"
            fontWeight="400"
            color="rgba(191,64,191,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Smart city icons created by Designing Hub - Flaticon"
            onClick={() => {
              smartcityOnClick();
            }}
            {...getOverrideProps(overrides, "Smart city")}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="13px"
            fontWeight="400"
            color="rgba(191,64,191,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="iPhone icons created by Andri Graphic - Flaticon"
            onClick={() => {
              iPhoneOnClick();
            }}
            {...getOverrideProps(overrides, "iPhone")}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="13px"
            fontWeight="400"
            color="rgba(191,64,191,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="President Biden photo (over shoulder) - Jim Watson"
            onClick={() => {
              bidenOnClick();
            }}
            {...getOverrideProps(overrides, "Biden")}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="13px"
            fontWeight="400"
            color="rgba(191,64,191,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="President Biden photo (long shot) - Kelvin Boyes, Press Eye"
            onClick={() => {
              bidenTwoOnClick();
            }}
            {...getOverrideProps(overrides, "Biden2")}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="13px"
            fontWeight="400"
            color="rgba(191,64,191,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Apple logo - 1000logos"
            onClick={() => {
              appleOnClick();
            }}
            {...getOverrideProps(overrides, "Apple")}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="13px"
            fontWeight="400"
            color="rgba(191,64,191,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="OpenAI logo - OpenAI"
            onClick={() => {
              openAIOnClick();
            }}
            {...getOverrideProps(overrides, "OpenAI")}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="13px"
            fontWeight="400"
            color="rgba(191,64,191,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="AWS logo - Wikimedia"
            onClick={() => {
              aWSOnClick();
            }}
            {...getOverrideProps(overrides, "AWS")}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="13px"
            fontWeight="400"
            color="rgba(191,64,191,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Azure logo - Windows/Microsoft"
            onClick={() => {
              azureOnClick();
            }}
            {...getOverrideProps(overrides, "Azure")}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="13px"
            fontWeight="400"
            color="rgba(191,64,191,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Python logo - Logos World"
            onClick={() => {
              pythonOnClick();
            }}
            {...getOverrideProps(overrides, "Python")}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="13px"
            fontWeight="400"
            color="rgba(191,64,191,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="C# logo - Wikimedia"
            onClick={() => {
              cNmberOnClick();
            }}
            {...getOverrideProps(overrides, "C#")}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="13px"
            fontWeight="400"
            color="rgba(191,64,191,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="LinkedIn logo - Logos World"
            onClick={() => {
              linkedInOnClick();
            }}
            {...getOverrideProps(overrides, "LinkedIn")}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="13px"
            fontWeight="400"
            color="rgba(191,64,191,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Twitter logo - Stick PNG"
            onClick={() => {
              twitterOnClick();
            }}
            {...getOverrideProps(overrides, "Twitter")}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="13px"
            fontWeight="400"
            color="rgba(191,64,191,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Medium logo - Logo Wine"
            onClick={() => {
              mediumOnClick();
            }}
            {...getOverrideProps(overrides, "Medium")}
          ></Text>
        </Flex>
        <Text
          fontFamily="Inter"
          fontSize="13px"
          fontWeight="400"
          color="rgba(0,0,0,1)"
          lineHeight="24px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          letterSpacing="0px"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="© 2023 Liam McCormick"
          {...getOverrideProps(overrides, "\u00A9 2023 Liam McCormick")}
        ></Text>
      </Flex>
    </Flex>
  );
}
