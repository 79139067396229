/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
  useNavigateAction,
} from "@aws-amplify/ui-react/internal";
import { Flex, Image, Text, useBreakpointValue } from "@aws-amplify/ui-react";
export default function ContactResponsive(props) {
  const { overrides: overridesProp, ...restProp } = props;
  const variants = [
    {
      variantValues: { breakpoint: "base" },
      overrides: {
        "Contact Liam": {},
        image39052761: {},
        "Frame 374": {},
        image39052763: {},
        "Frame 373": {},
        image39052765: {},
        "Frame 375": {},
        "Frame 13639052759": {},
        "Frame 13639052757": {},
        ContactResponsive: {},
      },
    },
    {
      variantValues: { breakpoint: "small" },
      overrides: {
        "Contact Liam": {},
        image39052761: { width: "90px", height: "42.84px" },
        "Frame 374": { padding: "19px 19px 19px 19px" },
        image39052763: { width: "60px", height: "35.68px" },
        "Frame 373": { padding: "19px 19px 19px 19px" },
        image39052765: { width: "90px", height: "53.51px" },
        "Frame 375": { padding: "19px 19px 19px 19px" },
        "Frame 13639052759": {},
        "Frame 13639052757": {},
        ContactResponsive: { width: "480px" },
      },
    },
    {
      variantValues: { breakpoint: "medium" },
      overrides: {
        "Contact Liam": { fontSize: "32px" },
        image39052761: { width: "110px", height: "52.36px" },
        "Frame 374": { padding: "29px 29px 29px 29px" },
        image39052763: { width: "80px", height: "47.57px" },
        "Frame 373": { padding: "29px 29px 29px 29px" },
        image39052765: { width: "110px", height: "65.41px" },
        "Frame 375": { padding: "29px 29px 29px 29px" },
        "Frame 13639052759": { gap: "30px" },
        "Frame 13639052757": {},
        ContactResponsive: {
          width: "768px",
          padding: "100px 100px 100px 100px",
        },
      },
    },
    {
      variantValues: { breakpoint: "large" },
      overrides: {
        "Contact Liam": { fontSize: "36px" },
        image39052761: { width: "200px", height: "95.2px" },
        "Frame 374": { padding: "39px 39px 39px 39px" },
        image39052763: { width: "110px", height: "65.41px" },
        "Frame 373": {},
        image39052765: { width: "200px", height: "118.92px" },
        "Frame 375": {},
        "Frame 13639052759": { gap: "30px", padding: "30px 0px 0px 0px" },
        "Frame 13639052757": {},
        ContactResponsive: {
          width: "992px",
          padding: "100px 150px 100px 150px",
        },
      },
    },
    {
      variantValues: { breakpoint: "xl" },
      overrides: {
        "Contact Liam": { fontSize: "40px" },
        image39052761: { width: "200px", height: "95.2px" },
        "Frame 374": { padding: "39px 39px 39px 39px" },
        image39052763: { width: "150px", height: "89.19px" },
        "Frame 373": { padding: "39px 39px 39px 39px" },
        image39052765: { width: "200px", height: "118.92px" },
        "Frame 375": { padding: "39px 39px 39px 39px" },
        "Frame 13639052759": { gap: "30px", padding: "30px 0px 0px 0px" },
        "Frame 13639052757": {},
        ContactResponsive: {
          width: "1280px",
          padding: "100px 240px 100px 240px",
        },
      },
    },
    {
      variantValues: { breakpoint: "xxl" },
      overrides: {
        "Contact Liam": { fontSize: "40px" },
        image39052761: { width: "200px", height: "95.2px" },
        "Frame 374": { padding: "39px 39px 39px 39px" },
        image39052763: { width: "150px", height: "89.19px" },
        "Frame 373": { padding: "39px 39px 39px 39px" },
        image39052765: { width: "200px", height: "118.92px" },
        "Frame 375": { padding: "39px 39px 39px 39px" },
        "Frame 13639052759": { gap: "30px", padding: "30px 0px 0px 0px" },
        "Frame 13639052757": {},
        ContactResponsive: {
          width: "1536px",
          padding: "100px 240px 100px 240px",
        },
      },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "base",
    small: "small",
    medium: "medium",
    large: "large",
    xl: "xl",
    xxl: "xxl",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  const imageThreeNineZeroFiveTwoSevenSixOneOnClick = useNavigateAction({
    type: "url",
    url: "https://www.linkedin.com/in/liam-mccormick44/",
    target: "_blank",
  });
  const imageThreeNineZeroFiveTwoSevenSixThreeOnClick = useNavigateAction({
    type: "url",
    url: "https://twitter.com/liammc_44",
    target: "_blank",
  });
  const imageThreeNineZeroFiveTwoSevenSixFiveOnClick = useNavigateAction({
    type: "url",
    url: "https://medium.com/@liammccormick1",
    target: "_blank",
  });
  return (
    <Flex
      gap="0"
      direction="column"
      width="380px"
      height="unset"
      justifyContent="center"
      alignItems="center"
      position="relative"
      padding="100px 50px 100px 50px"
      backgroundColor="rgba(255,255,255,1)"
      display="flex"
      {...getOverrideProps(overrides, "ContactResponsive")}
      {...rest}
    >
      <Flex
        gap="24px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Frame 13639052757")}
      >
        <Text
          fontFamily="Inter"
          fontSize="28px"
          fontWeight="600"
          color="rgba(0,0,0,1)"
          lineHeight="20px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Contact Liam"
          {...getOverrideProps(overrides, "Contact Liam")}
        ></Text>
        <Flex
          gap="10px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="10px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 13639052759")}
        >
          <Flex
            gap="16px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            grow="1"
            shrink="1"
            basis="0"
            alignSelf="stretch"
            position="relative"
            border="1px SOLID rgba(239,240,240,1)"
            borderRadius="20px"
            padding="9px 9px 9px 9px"
            backgroundColor="rgba(255,255,255,1)"
            display="flex"
            {...getOverrideProps(overrides, "Frame 374")}
          >
            <Image
              width="70px"
              height="33.32px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              objectFit="cover"
              src="https://personal-website-liam.s3.eu-west-1.amazonaws.com/public/linkedin-logo.png"
              onClick={() => {
                imageThreeNineZeroFiveTwoSevenSixOneOnClick();
              }}
              {...getOverrideProps(overrides, "image39052761")}
            ></Image>
          </Flex>
          <Flex
            gap="16px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            grow="1"
            shrink="1"
            basis="0"
            alignSelf="stretch"
            position="relative"
            border="1px SOLID rgba(239,240,240,1)"
            borderRadius="20px"
            padding="9px 9px 9px 9px"
            backgroundColor="rgba(255,255,255,1)"
            display="flex"
            {...getOverrideProps(overrides, "Frame 373")}
          >
            <Image
              width="50px"
              height="29.73px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              objectFit="cover"
              src="https://personal-website-liam.s3.eu-west-1.amazonaws.com/public/twitter-logo.png"
              onClick={() => {
                imageThreeNineZeroFiveTwoSevenSixThreeOnClick();
              }}
              {...getOverrideProps(overrides, "image39052763")}
            ></Image>
          </Flex>
          <Flex
            gap="16px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            grow="1"
            shrink="1"
            basis="0"
            alignSelf="stretch"
            position="relative"
            border="1px SOLID rgba(239,240,240,1)"
            borderRadius="20px"
            padding="9px 9px 9px 9px"
            backgroundColor="rgba(255,255,255,1)"
            display="flex"
            {...getOverrideProps(overrides, "Frame 375")}
          >
            <Image
              width="70px"
              height="41.62px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              objectFit="cover"
              src="https://personal-website-liam.s3.eu-west-1.amazonaws.com/public/medium-logo.png"
              onClick={() => {
                imageThreeNineZeroFiveTwoSevenSixFiveOnClick();
              }}
              {...getOverrideProps(overrides, "image39052765")}
            ></Image>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
