/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import { Flex, Image, useBreakpointValue } from "@aws-amplify/ui-react";
export default function IntroductionResponsive(props) {
  const { overrides: overridesProp, ...restProp } = props;
  const variants = [
    {
      variantValues: { breakpoint: "xxl" },
      overrides: {
        "small-image": { height: "unset", shrink: "1", grow: "1", basis: "0" },
        "medium-image": { width: "1536px", height: "630px", display: "none" },
        "large-image": { width: "1536px", height: "720px", display: "block" },
        "Frame 441": { height: "720px" },
        IntroductionResponsive: { width: "1536px" },
      },
    },
    {
      variantValues: { breakpoint: "xl" },
      overrides: {
        "small-image": { height: "unset", shrink: "1", grow: "1", basis: "0" },
        "medium-image": { width: "1280px", height: "600px", display: "none" },
        "large-image": {
          width: "unset",
          height: "600px",
          display: "block",
          alignSelf: "stretch",
        },
        "Frame 441": { height: "600px" },
        IntroductionResponsive: { width: "1280px" },
      },
    },
    {
      variantValues: { breakpoint: "large" },
      overrides: {
        "small-image": { height: "unset", shrink: "1", grow: "1", basis: "0" },
        "medium-image": { width: "992px", height: "488.17px", display: "none" },
        "large-image": {
          width: "unset",
          height: "488.17px",
          display: "block",
          alignSelf: "stretch",
        },
        "Frame 441": { height: "488.17px" },
        IntroductionResponsive: { width: "992px" },
      },
    },
    {
      variantValues: { breakpoint: "medium" },
      overrides: {
        "small-image": { height: "unset", shrink: "1", grow: "1", basis: "0" },
        "medium-image": { width: "768px", height: "378px", display: "none" },
        "large-image": {
          width: "unset",
          height: "378px",
          display: "block",
          alignSelf: "stretch",
        },
        "Frame 441": { height: "378px" },
        IntroductionResponsive: { width: "768px" },
      },
    },
    {
      variantValues: { breakpoint: "small" },
      overrides: {
        "small-image": { height: "232px" },
        "medium-image": { height: "380px" },
        "large-image": { width: "480px", height: "380px" },
        "Frame 441": { height: "380px" },
        IntroductionResponsive: { width: "480px" },
      },
    },
    {
      variantValues: { breakpoint: "base" },
      overrides: {
        "small-image": {},
        "medium-image": {},
        "large-image": {},
        "Frame 441": {},
        IntroductionResponsive: {},
      },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "base",
    small: "small",
    medium: "medium",
    large: "large",
    xl: "xl",
    xxl: "xxl",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  return (
    <Flex
      gap="0"
      direction="column"
      width="380px"
      height="unset"
      justifyContent="center"
      alignItems="center"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(245,245,247,1)"
      display="flex"
      {...getOverrideProps(overrides, "IntroductionResponsive")}
      {...rest}
    >
      <Flex
        gap="0"
        direction="column"
        width="unset"
        height="300px"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Frame 441")}
      >
        <Image
          width="unset"
          height="184px"
          display="none"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          objectFit="cover"
          src="https://personal-website-liam.s3.eu-west-1.amazonaws.com/public/liam-microsoft-text-1440-small.png"
          {...getOverrideProps(overrides, "small-image")}
        ></Image>
        <Image
          width="unset"
          height="300px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          objectFit="cover"
          src="https://personal-website-liam.s3.eu-west-1.amazonaws.com/public/liam-microsoft-text-4428-medium.png"
          {...getOverrideProps(overrides, "medium-image")}
        ></Image>
        <Image
          width="380px"
          height="300px"
          display="none"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          objectFit="cover"
          src="https://personal-website-liam.s3.eu-west-1.amazonaws.com/public/liam-microsoft-text-4428-large.png"
          {...getOverrideProps(overrides, "large-image")}
        ></Image>
      </Flex>
    </Flex>
  );
}
