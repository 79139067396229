/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import { Flex, Image, useBreakpointValue } from "@aws-amplify/ui-react";
export default function Gallery2Responsive(props) {
  const { overrides: overridesProp, ...restProp } = props;
  const variants = [
    {
      variantValues: { breakpoint: "xxl" },
      overrides: {
        "small-image": { height: "unset", shrink: "1", grow: "1", basis: "0" },
        "medium-image": { width: "1536px", height: "630px", display: "none" },
        "large-image": { width: "1536px", height: "1024px", display: "block" },
        "Frame 441": { shrink: "0" },
        Gallery2Responsive: { width: "1536px", height: "1024px" },
      },
    },
    {
      variantValues: { breakpoint: "xl" },
      overrides: {
        "small-image": { height: "unset", shrink: "1", grow: "1", basis: "0" },
        "medium-image": { width: "1280px", height: "600px", display: "none" },
        "large-image": {
          width: "unset",
          height: "853px",
          display: "block",
          alignSelf: "stretch",
        },
        "Frame 441": {},
        Gallery2Responsive: { width: "1280px", height: "853px" },
      },
    },
    {
      variantValues: { breakpoint: "large" },
      overrides: {
        "small-image": { height: "unset", shrink: "1", grow: "1", basis: "0" },
        "medium-image": { width: "992px", height: "488.17px", display: "none" },
        "large-image": {
          width: "unset",
          height: "661px",
          display: "block",
          alignSelf: "stretch",
        },
        "Frame 441": {},
        Gallery2Responsive: { width: "992px", height: "661px" },
      },
    },
    {
      variantValues: { breakpoint: "medium" },
      overrides: {
        "small-image": { height: "unset", shrink: "1", grow: "1", basis: "0" },
        "medium-image": { height: "768px" },
        "large-image": {
          width: "unset",
          height: "768px",
          alignSelf: "stretch",
        },
        "Frame 441": {},
        Gallery2Responsive: { width: "768px", height: "768px" },
      },
    },
    {
      variantValues: { breakpoint: "small" },
      overrides: {
        "small-image": { height: "232px" },
        "medium-image": { height: "480px" },
        "large-image": { width: "480px", height: "380px" },
        "Frame 441": {},
        Gallery2Responsive: { width: "480px", height: "480px" },
      },
    },
    {
      variantValues: { breakpoint: "base" },
      overrides: {
        "small-image": {},
        "medium-image": {},
        "large-image": {},
        "Frame 441": {},
        Gallery2Responsive: {},
      },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "base",
    small: "small",
    medium: "medium",
    large: "large",
    xl: "xl",
    xxl: "xxl",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  return (
    <Flex
      gap="0"
      direction="column"
      width="380px"
      height="380px"
      justifyContent="center"
      alignItems="center"
      position="relative"
      padding="0px 0px 0px 0px"
      display="flex"
      {...getOverrideProps(overrides, "Gallery2Responsive")}
      {...rest}
    >
      <Flex
        gap="0"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        grow="1"
        shrink="1"
        basis="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Frame 441")}
      >
        <Image
          width="unset"
          height="184px"
          display="none"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          objectFit="cover"
          {...getOverrideProps(overrides, "small-image")}
        ></Image>
        <Image
          width="unset"
          height="380px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          objectFit="cover"
          src="https://personal-website-liam.s3.eu-west-1.amazonaws.com/public/gallery-3600-large-long.JPG"
          {...getOverrideProps(overrides, "medium-image")}
        ></Image>
        <Image
          width="380px"
          height="300px"
          display="none"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          objectFit="cover"
          src="https://personal-website-liam.s3.eu-west-1.amazonaws.com/public/gallery-3600-large-wide.JPG"
          {...getOverrideProps(overrides, "large-image")}
        ></Image>
      </Flex>
    </Flex>
  );
}
