/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
  useNavigateAction,
} from "@aws-amplify/ui-react/internal";
import {
  Button,
  Flex,
  Image,
  Text,
  useBreakpointValue,
} from "@aws-amplify/ui-react";
export default function AchievementsResponsive(props) {
  const { overrides: overridesProp, ...restProp } = props;
  const variants = [
    {
      variantValues: { breakpoint: "xxl" },
      overrides: {
        "Achievements 1": { width: "325px", height: "37px" },
        "Frame 444": {},
        "IMG_0033 238952765": {
          width: "400px",
          height: "253.89px",
          display: "block",
        },
        "President Biden Visit": { fontSize: "32px", textAlign: "left" },
        "Frame 44338952827": { padding: "0px 0px 0px 0px" },
        "IMG_0033 338952826": {
          width: "280px",
          height: "177.72px",
          display: "none",
        },
        "It was an honour to have been invited to President Biden\u2019s speech in Belfast, which marked 25 years since the Good Friday Agreement was signed.":
          { fontSize: "18px", letterSpacing: "0.03px" },
        "The President placed an emphasis on the next generation of leaders in Northern Ireland and the valuable work that\u2019s being done in the push for further progress.":
          { fontSize: "18px", letterSpacing: "0.03px" },
        Button40333615: { size: "default" },
        "Frame 42538952768": { padding: "20px 0px 20px 0px" },
        "Frame 42338952766": {},
        "Frame 375": { gap: "70px", padding: "60px 100px 60px 100px" },
        "IMG_0033 238952900": {
          width: "400px",
          height: "253.89px",
          display: "block",
        },
        "Meeting with UK Minister38952903": {
          fontSize: "32px",
          textAlign: "left",
        },
        "Frame 44338952902": { padding: "0px 0px 0px 0px" },
        "IMG_0033 338952904": {
          width: "280px",
          height: "177.72px",
          display: "none",
        },
        "Pleased to have met with UK Cabinet Minister, Conor Burns (Northern Ireland Office), at the Belfast office.38952906":
          { fontSize: "18px", letterSpacing: "0.03px" },
        "I was joined by Holly Toner and we presented to the Minister just some of the interesting work that we do within the Innovation team.38952907":
          { fontSize: "18px", letterSpacing: "0.03px" },
        Button40464134: { size: "default" },
        "Frame 42538952905": { padding: "20px 0px 20px 0px" },
        "Frame 42338952901": {},
        "Frame 379": { gap: "70px", padding: "60px 100px 60px 100px" },
        "IMG_0033 238952909": {
          width: "400px",
          height: "253.89px",
          display: "block",
        },
        "Presenting at Microsoft\u2019s UK Office38952912": {
          fontSize: "32px",
          textAlign: "left",
          children: "Presenting at Microsoft\u2019s UK Office",
        },
        "Frame 44338952911": { padding: "0px 0px 0px 0px" },
        "IMG_0033 338952913": {
          width: "280px",
          height: "177.72px",
          display: "none",
        },
        "It was a great time presenting at Microsoft\u2019s offices for the TeenTech hackathon.38952915":
          { fontSize: "18px", letterSpacing: "0.03px" },
        "I was joined by Michael Kerrigan and we led multiple sessions on how we\u2019ve used Computer Vision and ML to tackle difficult problems - along with ways the students can apply the same kind of innovative thinking in their hackathon projects.38952916":
          { fontSize: "18px", letterSpacing: "0.03px" },
        Button40403905: { size: "default" },
        "Frame 42538952914": { padding: "20px 0px 20px 0px" },
        "Frame 42338952910": {},
        "Frame 380": { gap: "70px", padding: "60px 100px 60px 100px" },
        "IMG_0033 238952918": {
          width: "400px",
          height: "253.89px",
          display: "block",
        },
        "Best Mobile Technology Project38952921": {
          fontSize: "32px",
          textAlign: "left",
        },
        "Frame 44338952920": { padding: "0px 0px 0px 0px" },
        "IMG_0033 338952922": {
          width: "280px",
          height: "177.72px",
          display: "none",
        },
        "Delighted to pick up my award for \u201CBest Mobile Technology Project\u201D at Ulster University, after the awards ceremony was delayed due to Covid.38952924":
          { fontSize: "18px", letterSpacing: "0.03px" },
        "This project will allow Search & Rescue teams in Derry to better organise their public searches for missing people - and quickly share information with the public when there\u2019s an emergency.38952925":
          { fontSize: "18px", letterSpacing: "0.03px" },
        Button40464138: { size: "default" },
        "Frame 42538952923": { padding: "20px 0px 20px 0px" },
        "Frame 42338952919": {},
        "Frame 381": { gap: "70px", padding: "60px 100px 60px 100px" },
        "IMG_0033 5": {},
        "Meeting with UK Minister38952774": {},
        "Pleased to have met with UK Cabinet Minister, Conor Burns (Northern Ireland Office), at the Belfast office.38952776":
          {},
        "I was joined by Holly Toner and we presented to the Minister just some of the interesting work that we do within the Innovation team.38952777":
          {},
        "Frame 428": {},
        "Frame 42338952773": {},
        "Frame 377": {},
        "IMG_0033 338952779": {},
        "Presenting at Microsoft\u2019s UK Office38952781": {},
        "It was a great time presenting at Microsoft\u2019s offices for the TeenTech hackathon.38952783":
          {},
        "I was joined by Michael Kerrigan and we led multiple sessions on how we\u2019ve used Computer Vision and ML to tackle difficult problems - along with ways the students can apply the same kind of innovative thinking in their hackathon projects.38952784":
          {},
        "Frame 426": {},
        "Frame 42338952780": {},
        "Frame 378": {},
        "IMG_0033 4": {},
        "Best Mobile Technology Project38952788": {},
        "Delighted to pick up my award for \u201CBest Mobile Technology Project\u201D at Ulster University, after the awards ceremony was delayed due to Covid.38952790":
          {},
        "This project will allow Search & Rescue teams in Derry to better organise their public searches for missing people - and quickly share information with the public when there\u2019s an emergency.38952791":
          {},
        "Frame 427": {},
        "Frame 42338952787": {},
        "Frame 376": {},
        "Frame 424": {
          justifyContent: "flex-start",
          alignItems: "flex-start",
          padding: "20px 240px 20px 240px",
        },
        "Frame 136": {},
        AchievementsResponsive: { width: "1536px" },
      },
    },
    {
      variantValues: { breakpoint: "xl" },
      overrides: {
        "Achievements 1": { width: "325px", height: "37px" },
        "Frame 444": {},
        "IMG_0033 238952765": {
          width: "350px",
          height: "222.15px",
          display: "block",
        },
        "President Biden Visit": { fontSize: "28px", textAlign: "left" },
        "Frame 44338952827": { padding: "0px 0px 0px 0px" },
        "IMG_0033 338952826": {
          width: "280px",
          height: "177.72px",
          display: "none",
        },
        "It was an honour to have been invited to President Biden\u2019s speech in Belfast, which marked 25 years since the Good Friday Agreement was signed.":
          { fontSize: "16px", letterSpacing: "0.01px" },
        "The President placed an emphasis on the next generation of leaders in Northern Ireland and the valuable work that\u2019s being done in the push for further progress.":
          { fontSize: "16px", letterSpacing: "0.01px" },
        Button40333615: { size: "default" },
        "Frame 42538952768": { padding: "20px 0px 20px 0px" },
        "Frame 42338952766": {},
        "Frame 375": { padding: "40px 60px 40px 60px" },
        "IMG_0033 238952900": {
          width: "350px",
          height: "222.15px",
          display: "block",
        },
        "Meeting with UK Minister38952903": {
          fontSize: "28px",
          textAlign: "left",
        },
        "Frame 44338952902": { padding: "0px 0px 0px 0px" },
        "IMG_0033 338952904": {
          width: "280px",
          height: "177.72px",
          display: "none",
        },
        "Pleased to have met with UK Cabinet Minister, Conor Burns (Northern Ireland Office), at the Belfast office.38952906":
          { fontSize: "16px", letterSpacing: "0.01px" },
        "I was joined by Holly Toner and we presented to the Minister just some of the interesting work that we do within the Innovation team.38952907":
          { fontSize: "16px", letterSpacing: "0.01px" },
        Button40464134: { size: "default" },
        "Frame 42538952905": { padding: "20px 0px 20px 0px" },
        "Frame 42338952901": {},
        "Frame 379": { padding: "40px 60px 40px 60px" },
        "IMG_0033 238952909": {
          width: "350px",
          height: "222.15px",
          display: "block",
        },
        "Presenting at Microsoft\u2019s UK Office38952912": {
          fontSize: "28px",
          lineHeight: "40px",
          textAlign: "left",
          children: "Presenting at Microsoft\u2019s UK Office",
        },
        "Frame 44338952911": { padding: "0px 0px 0px 0px" },
        "IMG_0033 338952913": {
          width: "280px",
          height: "177.72px",
          display: "none",
        },
        "It was a great time presenting at Microsoft\u2019s offices for the TeenTech hackathon.38952915":
          { fontSize: "16px", display: "none", letterSpacing: "0.01px" },
        "I was joined by Michael Kerrigan and we led multiple sessions on how we\u2019ve used Computer Vision and ML to tackle difficult problems - along with ways the students can apply the same kind of innovative thinking in their hackathon projects.38952916":
          { fontSize: "16px", letterSpacing: "0.01px" },
        Button40403905: { size: "default" },
        "Frame 42538952914": { padding: "20px 0px 20px 0px" },
        "Frame 42338952910": {},
        "Frame 380": { padding: "40px 60px 40px 60px" },
        "IMG_0033 238952918": {
          width: "350px",
          height: "222.15px",
          display: "block",
        },
        "Best Mobile Technology Project38952921": {
          fontSize: "28px",
          lineHeight: "40px",
          textAlign: "left",
        },
        "Frame 44338952920": { padding: "0px 0px 0px 0px" },
        "IMG_0033 338952922": {
          width: "280px",
          height: "177.72px",
          display: "none",
        },
        "Delighted to pick up my award for \u201CBest Mobile Technology Project\u201D at Ulster University, after the awards ceremony was delayed due to Covid.38952924":
          { fontSize: "16px", letterSpacing: "0.01px" },
        "This project will allow Search & Rescue teams in Derry to better organise their public searches for missing people - and quickly share information with the public when there\u2019s an emergency.38952925":
          { fontSize: "16px", letterSpacing: "0.01px" },
        Button40464138: { size: "default" },
        "Frame 42538952923": { padding: "20px 0px 20px 0px" },
        "Frame 42338952919": {},
        "Frame 381": { padding: "40px 60px 40px 60px" },
        "IMG_0033 5": {},
        "Meeting with UK Minister38952774": {},
        "Pleased to have met with UK Cabinet Minister, Conor Burns (Northern Ireland Office), at the Belfast office.38952776":
          {},
        "I was joined by Holly Toner and we presented to the Minister just some of the interesting work that we do within the Innovation team.38952777":
          {},
        "Frame 428": {},
        "Frame 42338952773": {},
        "Frame 377": {},
        "IMG_0033 338952779": {},
        "Presenting at Microsoft\u2019s UK Office38952781": {},
        "It was a great time presenting at Microsoft\u2019s offices for the TeenTech hackathon.38952783":
          {},
        "I was joined by Michael Kerrigan and we led multiple sessions on how we\u2019ve used Computer Vision and ML to tackle difficult problems - along with ways the students can apply the same kind of innovative thinking in their hackathon projects.38952784":
          {},
        "Frame 426": {},
        "Frame 42338952780": {},
        "Frame 378": {},
        "IMG_0033 4": {},
        "Best Mobile Technology Project38952788": {},
        "Delighted to pick up my award for \u201CBest Mobile Technology Project\u201D at Ulster University, after the awards ceremony was delayed due to Covid.38952790":
          {},
        "This project will allow Search & Rescue teams in Derry to better organise their public searches for missing people - and quickly share information with the public when there\u2019s an emergency.38952791":
          {},
        "Frame 427": {},
        "Frame 42338952787": {},
        "Frame 376": {},
        "Frame 424": {
          justifyContent: "flex-start",
          alignItems: "flex-start",
          padding: "20px 240px 20px 240px",
        },
        "Frame 136": {},
        AchievementsResponsive: { width: "1280px" },
      },
    },
    {
      variantValues: { breakpoint: "large" },
      overrides: {
        "Achievements 1": { width: "280px", height: "31.88px" },
        "Frame 444": {},
        "IMG_0033 238952765": {
          width: "300px",
          height: "190.41px",
          display: "block",
        },
        "President Biden Visit": { fontSize: "26px", textAlign: "left" },
        "Frame 44338952827": { padding: "0px 0px 0px 0px" },
        "IMG_0033 338952826": {
          width: "280px",
          height: "177.72px",
          display: "none",
        },
        "It was an honour to have been invited to President Biden\u2019s speech in Belfast, which marked 25 years since the Good Friday Agreement was signed.":
          { fontSize: "14px" },
        "The President placed an emphasis on the next generation of leaders in Northern Ireland and the valuable work that\u2019s being done in the push for further progress.":
          { fontSize: "14px" },
        Button40333615: { size: "default" },
        "Frame 42538952768": { padding: "10px 0px 10px 0px" },
        "Frame 42338952766": {},
        "Frame 375": { gap: "40px", padding: "40px 50px 40px 50px" },
        "IMG_0033 238952900": {
          width: "300px",
          height: "190.41px",
          display: "block",
        },
        "Meeting with UK Minister38952903": {
          fontSize: "26px",
          textAlign: "left",
        },
        "Frame 44338952902": { padding: "0px 0px 0px 0px" },
        "IMG_0033 338952904": {
          width: "280px",
          height: "177.72px",
          display: "none",
        },
        "Pleased to have met with UK Cabinet Minister, Conor Burns (Northern Ireland Office), at the Belfast office.38952906":
          { fontSize: "14px" },
        "I was joined by Holly Toner and we presented to the Minister just some of the interesting work that we do within the Innovation team.38952907":
          { fontSize: "14px" },
        Button40464134: { size: "default" },
        "Frame 42538952905": { padding: "10px 0px 10px 0px" },
        "Frame 42338952901": {},
        "Frame 379": { gap: "40px", padding: "40px 50px 40px 50px" },
        "IMG_0033 238952909": {
          width: "300px",
          height: "190.41px",
          display: "block",
        },
        "Presenting at Microsoft\u2019s UK Office38952912": {
          fontSize: "26px",
          textAlign: "left",
          children: "Presenting at Microsoft\u2019s UK Office",
        },
        "Frame 44338952911": { padding: "0px 0px 0px 0px" },
        "IMG_0033 338952913": {
          width: "280px",
          height: "177.72px",
          display: "none",
        },
        "It was a great time presenting at Microsoft\u2019s offices for the TeenTech hackathon.38952915":
          { fontSize: "14px" },
        "I was joined by Michael Kerrigan and we led multiple sessions on how we\u2019ve used Computer Vision and ML to tackle difficult problems - along with ways the students can apply the same kind of innovative thinking in their hackathon projects.38952916":
          { fontSize: "14px" },
        Button40403905: { size: "default" },
        "Frame 42538952914": { padding: "10px 0px 0px 0px" },
        "Frame 42338952910": {},
        "Frame 380": { gap: "40px", padding: "40px 50px 40px 50px" },
        "IMG_0033 238952918": {
          width: "300px",
          height: "190.41px",
          display: "block",
        },
        "Best Mobile Technology Project38952921": {
          fontSize: "26px",
          textAlign: "left",
        },
        "Frame 44338952920": { padding: "0px 0px 0px 0px" },
        "IMG_0033 338952922": {
          width: "280px",
          height: "177.72px",
          display: "none",
        },
        "Delighted to pick up my award for \u201CBest Mobile Technology Project\u201D at Ulster University, after the awards ceremony was delayed due to Covid.38952924":
          { fontSize: "14px" },
        "This project will allow Search & Rescue teams in Derry to better organise their public searches for missing people - and quickly share information with the public when there\u2019s an emergency.38952925":
          { fontSize: "14px" },
        Button40464138: { size: "default" },
        "Frame 42538952923": { padding: "10px 0px 0px 0px" },
        "Frame 42338952919": {},
        "Frame 381": { gap: "40px", padding: "40px 50px 40px 50px" },
        "IMG_0033 5": {},
        "Meeting with UK Minister38952774": {},
        "Pleased to have met with UK Cabinet Minister, Conor Burns (Northern Ireland Office), at the Belfast office.38952776":
          {},
        "I was joined by Holly Toner and we presented to the Minister just some of the interesting work that we do within the Innovation team.38952777":
          {},
        "Frame 428": {},
        "Frame 42338952773": {},
        "Frame 377": {},
        "IMG_0033 338952779": {},
        "Presenting at Microsoft\u2019s UK Office38952781": {},
        "It was a great time presenting at Microsoft\u2019s offices for the TeenTech hackathon.38952783":
          {},
        "I was joined by Michael Kerrigan and we led multiple sessions on how we\u2019ve used Computer Vision and ML to tackle difficult problems - along with ways the students can apply the same kind of innovative thinking in their hackathon projects.38952784":
          {},
        "Frame 426": {},
        "Frame 42338952780": {},
        "Frame 378": {},
        "IMG_0033 4": {},
        "Best Mobile Technology Project38952788": {},
        "Delighted to pick up my award for \u201CBest Mobile Technology Project\u201D at Ulster University, after the awards ceremony was delayed due to Covid.38952790":
          {},
        "This project will allow Search & Rescue teams in Derry to better organise their public searches for missing people - and quickly share information with the public when there\u2019s an emergency.38952791":
          {},
        "Frame 427": {},
        "Frame 42338952787": {},
        "Frame 376": {},
        "Frame 424": {
          justifyContent: "flex-start",
          alignItems: "flex-start",
          padding: "20px 150px 20px 150px",
        },
        "Frame 136": {},
        AchievementsResponsive: { width: "992px" },
      },
    },
    {
      variantValues: { breakpoint: "medium" },
      overrides: {
        "Achievements 1": { width: "280px", height: "31.88px" },
        "Frame 444": {},
        "IMG_0033 238952765": { display: "block" },
        "President Biden Visit": { fontSize: "26px", textAlign: "left" },
        "Frame 44338952827": { padding: "0px 0px 0px 0px" },
        "IMG_0033 338952826": {
          width: "280px",
          height: "177.72px",
          display: "none",
        },
        "It was an honour to have been invited to President Biden\u2019s speech in Belfast, which marked 25 years since the Good Friday Agreement was signed.":
          { fontSize: "14px" },
        "The President placed an emphasis on the next generation of leaders in Northern Ireland and the valuable work that\u2019s being done in the push for further progress.":
          { fontSize: "14px" },
        Button40333615: { size: "default" },
        "Frame 42538952768": { padding: "10px 0px 10px 0px" },
        "Frame 42338952766": {},
        "Frame 375": { gap: "30px" },
        "IMG_0033 238952900": { display: "block" },
        "Meeting with UK Minister38952903": {
          fontSize: "26px",
          textAlign: "left",
        },
        "Frame 44338952902": { padding: "0px 0px 0px 0px" },
        "IMG_0033 338952904": {
          width: "280px",
          height: "177.72px",
          display: "none",
        },
        "Pleased to have met with UK Cabinet Minister, Conor Burns (Northern Ireland Office), at the Belfast office.38952906":
          { fontSize: "14px" },
        "I was joined by Holly Toner and we presented to the Minister just some of the interesting work that we do within the Innovation team.38952907":
          { fontSize: "14px" },
        Button40464134: { size: "default" },
        "Frame 42538952905": { padding: "10px 0px 10px 0px" },
        "Frame 42338952901": {},
        "Frame 379": { gap: "30px" },
        "IMG_0033 238952909": { display: "block" },
        "Presenting at Microsoft\u2019s UK Office38952912": {
          fontSize: "26px",
          textAlign: "left",
        },
        "Frame 44338952911": { padding: "0px 0px 0px 0px" },
        "IMG_0033 338952913": {
          width: "280px",
          height: "177.72px",
          display: "none",
        },
        "It was a great time presenting at Microsoft\u2019s offices for the TeenTech hackathon.38952915":
          { fontSize: "14px" },
        "I was joined by Michael Kerrigan and we led multiple sessions on how we\u2019ve used Computer Vision and ML to tackle difficult problems - along with ways the students can apply the same kind of innovative thinking in their hackathon projects.38952916":
          { fontSize: "14px" },
        Button40403905: { size: "default" },
        "Frame 42538952914": { padding: "10px 0px 0px 0px" },
        "Frame 42338952910": {},
        "Frame 380": { gap: "30px" },
        "IMG_0033 238952918": { display: "block" },
        "Best Mobile Technology Project38952921": {
          fontSize: "26px",
          textAlign: "left",
          children: "Best Mobile Technology \nProject",
        },
        "Frame 44338952920": { padding: "0px 0px 0px 0px" },
        "IMG_0033 338952922": {
          width: "280px",
          height: "177.72px",
          display: "none",
        },
        "Delighted to pick up my award for \u201CBest Mobile Technology Project\u201D at Ulster University, after the awards ceremony was delayed due to Covid.38952924":
          { fontSize: "14px" },
        "This project will allow Search & Rescue teams in Derry to better organise their public searches for missing people - and quickly share information with the public when there\u2019s an emergency.38952925":
          { fontSize: "14px" },
        Button40464138: { size: "default" },
        "Frame 42538952923": { padding: "10px 0px 0px 0px" },
        "Frame 42338952919": {},
        "Frame 381": { gap: "30px" },
        "IMG_0033 5": {},
        "Meeting with UK Minister38952774": {},
        "Pleased to have met with UK Cabinet Minister, Conor Burns (Northern Ireland Office), at the Belfast office.38952776":
          {},
        "I was joined by Holly Toner and we presented to the Minister just some of the interesting work that we do within the Innovation team.38952777":
          {},
        "Frame 428": {},
        "Frame 42338952773": {},
        "Frame 377": {},
        "IMG_0033 338952779": {},
        "Presenting at Microsoft\u2019s UK Office38952781": {},
        "It was a great time presenting at Microsoft\u2019s offices for the TeenTech hackathon.38952783":
          {},
        "I was joined by Michael Kerrigan and we led multiple sessions on how we\u2019ve used Computer Vision and ML to tackle difficult problems - along with ways the students can apply the same kind of innovative thinking in their hackathon projects.38952784":
          {},
        "Frame 426": {},
        "Frame 42338952780": {},
        "Frame 378": {},
        "IMG_0033 4": {},
        "Best Mobile Technology Project38952788": {},
        "Delighted to pick up my award for \u201CBest Mobile Technology Project\u201D at Ulster University, after the awards ceremony was delayed due to Covid.38952790":
          {},
        "This project will allow Search & Rescue teams in Derry to better organise their public searches for missing people - and quickly share information with the public when there\u2019s an emergency.38952791":
          {},
        "Frame 427": {},
        "Frame 42338952787": {},
        "Frame 376": {},
        "Frame 424": {
          justifyContent: "flex-start",
          alignItems: "flex-start",
          padding: "20px 100px 20px 100px",
        },
        "Frame 136": {},
        AchievementsResponsive: { width: "768px" },
      },
    },
    {
      variantValues: { breakpoint: "small" },
      overrides: {
        "Achievements 1": { width: "240px", height: "27.32px" },
        "Frame 444": {},
        "IMG_0033 238952765": {},
        "President Biden Visit": { fontSize: "24px" },
        "Frame 44338952827": {},
        "IMG_0033 338952826": { width: "280px", height: "177.72px" },
        "It was an honour to have been invited to President Biden\u2019s speech in Belfast, which marked 25 years since the Good Friday Agreement was signed.":
          {},
        "The President placed an emphasis on the next generation of leaders in Northern Ireland and the valuable work that\u2019s being done in the push for further progress.":
          {},
        Button40333615: {},
        "Frame 42538952768": {},
        "Frame 42338952766": {},
        "Frame 375": {},
        "IMG_0033 238952900": {},
        "Meeting with UK Minister38952903": { fontSize: "24px" },
        "Frame 44338952902": {},
        "IMG_0033 338952904": { width: "280px", height: "177.72px" },
        "Pleased to have met with UK Cabinet Minister, Conor Burns (Northern Ireland Office), at the Belfast office.38952906":
          {},
        "I was joined by Holly Toner and we presented to the Minister just some of the interesting work that we do within the Innovation team.38952907":
          {},
        Button40464134: {},
        "Frame 42538952905": {},
        "Frame 42338952901": {},
        "Frame 379": {},
        "IMG_0033 238952909": {},
        "Presenting at Microsoft\u2019s UK Office38952912": {
          fontSize: "24px",
        },
        "Frame 44338952911": {},
        "IMG_0033 338952913": { width: "280px", height: "177.72px" },
        "It was a great time presenting at Microsoft\u2019s offices for the TeenTech hackathon.38952915":
          {},
        "I was joined by Michael Kerrigan and we led multiple sessions on how we\u2019ve used Computer Vision and ML to tackle difficult problems - along with ways the students can apply the same kind of innovative thinking in their hackathon projects.38952916":
          {},
        Button40403905: {},
        "Frame 42538952914": {},
        "Frame 42338952910": {},
        "Frame 380": {},
        "IMG_0033 238952918": {},
        "Best Mobile Technology Project38952921": {
          fontSize: "24px",
          children: "Best Mobile Technology \nProject",
        },
        "Frame 44338952920": {},
        "IMG_0033 338952922": { width: "280px", height: "177.72px" },
        "Delighted to pick up my award for \u201CBest Mobile Technology Project\u201D at Ulster University, after the awards ceremony was delayed due to Covid.38952924":
          {},
        "This project will allow Search & Rescue teams in Derry to better organise their public searches for missing people - and quickly share information with the public when there\u2019s an emergency.38952925":
          {},
        Button40464138: {},
        "Frame 42538952923": {},
        "Frame 42338952919": {},
        "Frame 381": {},
        "IMG_0033 5": {},
        "Meeting with UK Minister38952774": {},
        "Pleased to have met with UK Cabinet Minister, Conor Burns (Northern Ireland Office), at the Belfast office.38952776":
          {},
        "I was joined by Holly Toner and we presented to the Minister just some of the interesting work that we do within the Innovation team.38952777":
          {},
        "Frame 428": {},
        "Frame 42338952773": {},
        "Frame 377": {},
        "IMG_0033 338952779": {},
        "Presenting at Microsoft\u2019s UK Office38952781": {},
        "It was a great time presenting at Microsoft\u2019s offices for the TeenTech hackathon.38952783":
          {},
        "I was joined by Michael Kerrigan and we led multiple sessions on how we\u2019ve used Computer Vision and ML to tackle difficult problems - along with ways the students can apply the same kind of innovative thinking in their hackathon projects.38952784":
          {},
        "Frame 426": {},
        "Frame 42338952780": {},
        "Frame 378": {},
        "IMG_0033 4": {},
        "Best Mobile Technology Project38952788": {},
        "Delighted to pick up my award for \u201CBest Mobile Technology Project\u201D at Ulster University, after the awards ceremony was delayed due to Covid.38952790":
          {},
        "This project will allow Search & Rescue teams in Derry to better organise their public searches for missing people - and quickly share information with the public when there\u2019s an emergency.38952791":
          {},
        "Frame 427": {},
        "Frame 42338952787": {},
        "Frame 376": {},
        "Frame 424": { justifyContent: "flex-start", alignItems: "flex-start" },
        "Frame 136": {},
        AchievementsResponsive: { width: "480px" },
      },
    },
    {
      variantValues: { breakpoint: "base" },
      overrides: {
        "Achievements 1": {},
        "Frame 444": {},
        "IMG_0033 238952765": {},
        "President Biden Visit": {},
        "Frame 44338952827": {},
        "IMG_0033 338952826": {},
        "It was an honour to have been invited to President Biden\u2019s speech in Belfast, which marked 25 years since the Good Friday Agreement was signed.":
          {},
        "The President placed an emphasis on the next generation of leaders in Northern Ireland and the valuable work that\u2019s being done in the push for further progress.":
          {},
        Button40333615: {},
        "Frame 42538952768": {},
        "Frame 42338952766": {},
        "Frame 375": {},
        "IMG_0033 238952900": {},
        "Meeting with UK Minister38952903": {},
        "Frame 44338952902": {},
        "IMG_0033 338952904": {},
        "Pleased to have met with UK Cabinet Minister, Conor Burns (Northern Ireland Office), at the Belfast office.38952906":
          {},
        "I was joined by Holly Toner and we presented to the Minister just some of the interesting work that we do within the Innovation team.38952907":
          {},
        Button40464134: {},
        "Frame 42538952905": {},
        "Frame 42338952901": {},
        "Frame 379": {},
        "IMG_0033 238952909": {},
        "Presenting at Microsoft\u2019s UK Office38952912": {},
        "Frame 44338952911": {},
        "IMG_0033 338952913": {},
        "It was a great time presenting at Microsoft\u2019s offices for the TeenTech hackathon.38952915":
          {},
        "I was joined by Michael Kerrigan and we led multiple sessions on how we\u2019ve used Computer Vision and ML to tackle difficult problems - along with ways the students can apply the same kind of innovative thinking in their hackathon projects.38952916":
          {},
        Button40403905: {},
        "Frame 42538952914": {},
        "Frame 42338952910": {},
        "Frame 380": {},
        "IMG_0033 238952918": {},
        "Best Mobile Technology Project38952921": {},
        "Frame 44338952920": {},
        "IMG_0033 338952922": {},
        "Delighted to pick up my award for \u201CBest Mobile Technology Project\u201D at Ulster University, after the awards ceremony was delayed due to Covid.38952924":
          {},
        "This project will allow Search & Rescue teams in Derry to better organise their public searches for missing people - and quickly share information with the public when there\u2019s an emergency.38952925":
          {},
        Button40464138: {},
        "Frame 42538952923": {},
        "Frame 42338952919": {},
        "Frame 381": {},
        "IMG_0033 5": {},
        "Meeting with UK Minister38952774": {},
        "Pleased to have met with UK Cabinet Minister, Conor Burns (Northern Ireland Office), at the Belfast office.38952776":
          {},
        "I was joined by Holly Toner and we presented to the Minister just some of the interesting work that we do within the Innovation team.38952777":
          {},
        "Frame 428": {},
        "Frame 42338952773": {},
        "Frame 377": {},
        "IMG_0033 338952779": {},
        "Presenting at Microsoft\u2019s UK Office38952781": {},
        "It was a great time presenting at Microsoft\u2019s offices for the TeenTech hackathon.38952783":
          {},
        "I was joined by Michael Kerrigan and we led multiple sessions on how we\u2019ve used Computer Vision and ML to tackle difficult problems - along with ways the students can apply the same kind of innovative thinking in their hackathon projects.38952784":
          {},
        "Frame 426": {},
        "Frame 42338952780": {},
        "Frame 378": {},
        "IMG_0033 4": {},
        "Best Mobile Technology Project38952788": {},
        "Delighted to pick up my award for \u201CBest Mobile Technology Project\u201D at Ulster University, after the awards ceremony was delayed due to Covid.38952790":
          {},
        "This project will allow Search & Rescue teams in Derry to better organise their public searches for missing people - and quickly share information with the public when there\u2019s an emergency.38952791":
          {},
        "Frame 427": {},
        "Frame 42338952787": {},
        "Frame 376": {},
        "Frame 424": {},
        "Frame 136": {},
        AchievementsResponsive: {},
      },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "base",
    small: "small",
    medium: "medium",
    large: "large",
    xl: "xl",
    xxl: "xxl",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  const frameThreeSevenFiveOnClick = useNavigateAction({
    type: "url",
    url: "/president-biden",
  });
  const iMGUnderScoreZeroZeroThreeThreeTwoThreeEightNineFiveTwoSevenSixFiveOnClick =
    useNavigateAction({ type: "url", url: "/president-biden" });
  const buttonFourZeroThreeThreeThreeSixOneFiveOnClick = useNavigateAction({
    type: "url",
    url: "/president-biden",
  });
  const frameThreeSevenNineOnClick = useNavigateAction({
    type: "url",
    url: "/uk-cabinet-minister",
  });
  const iMGUnderScoreZeroZeroThreeThreeTwoThreeEightNineFiveTwoNineZeroZeroOnClick =
    useNavigateAction({ type: "url", url: "/uk-cabinet-minister" });
  const buttonFourZeroFourSixFourOneThreeFourOnClick = useNavigateAction({
    type: "url",
    url: "/uk-cabinet-minister",
  });
  const frameThreeEightZeroOnClick = useNavigateAction({
    type: "url",
    url: "/microsoft-uk-office",
  });
  const iMGUnderScoreZeroZeroThreeThreeTwoThreeEightNineFiveTwoNineZeroNineOnClick =
    useNavigateAction({ type: "url", url: "/microsoft-uk-office" });
  const buttonFourZeroFourZeroThreeNineZeroFiveOnClick = useNavigateAction({
    type: "url",
    url: "/microsoft-uk-office",
  });
  const frameThreeEightOneOnClick = useNavigateAction({
    type: "url",
    url: "/uu-awards-ceremony",
  });
  const iMGUnderScoreZeroZeroThreeThreeTwoThreeEightNineFiveTwoNineOneEightOnClick =
    useNavigateAction({ type: "url", url: "/uu-awards-ceremony" });
  const buttonFourZeroFourSixFourOneThreeEightOnClick = useNavigateAction({
    type: "url",
    url: "/uu-awards-ceremony",
  });
  return (
    <Flex
      gap="0"
      direction="column"
      width="380px"
      height="unset"
      justifyContent="center"
      alignItems="center"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(255,255,255,1)"
      display="flex"
      {...getOverrideProps(overrides, "AchievementsResponsive")}
      {...rest}
    >
      <Flex
        gap="24px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="70px 0px 50px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Frame 136")}
      >
        <Flex
          gap="0"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 444")}
        >
          <Image
            width="200px"
            height="22.77px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            objectFit="cover"
            src="https://personal-website-liam.s3.eu-west-1.amazonaws.com/public/Achievements.png"
            {...getOverrideProps(overrides, "Achievements 1")}
          ></Image>
        </Flex>
        <Flex
          gap="30px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="20px 50px 20px 50px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 424")}
        >
          <Flex
            gap="50px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            borderRadius="20px"
            padding="20px 30px 20px 30px"
            backgroundColor="rgba(245,245,247,1)"
            display="flex"
            onClick={() => {
              frameThreeSevenFiveOnClick();
            }}
            {...getOverrideProps(overrides, "Frame 375")}
          >
            <Image
              width="250px"
              height="158.68px"
              display="none"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              borderRadius="20px"
              padding="0px 0px 0px 0px"
              objectFit="cover"
              src="https://personal-website-liam.s3.eu-west-1.amazonaws.com/public/liam-president-biden-shoulder.jpg"
              onClick={() => {
                iMGUnderScoreZeroZeroThreeThreeTwoThreeEightNineFiveTwoSevenSixFiveOnClick();
              }}
              {...getOverrideProps(overrides, "IMG_0033 238952765")}
            ></Image>
            <Flex
              gap="10px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              grow="1"
              shrink="1"
              basis="0"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 42338952766")}
            >
              <Flex
                gap="0"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 20px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 44338952827")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="20px"
                  fontWeight="600"
                  color="rgba(0,0,0,1)"
                  lineHeight="30px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="President Biden Visit"
                  {...getOverrideProps(overrides, "President Biden Visit")}
                ></Text>
              </Flex>
              <Image
                width="220px"
                height="139.64px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                shrink="0"
                position="relative"
                borderRadius="20px"
                padding="0px 0px 0px 0px"
                objectFit="cover"
                src="https://personal-website-liam.s3.eu-west-1.amazonaws.com/public/liam-president-biden-shoulder.jpg"
                {...getOverrideProps(overrides, "IMG_0033 338952826")}
              ></Image>
              <Flex
                gap="20px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="20px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 42538952768")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="13px"
                  fontWeight="400"
                  color="rgba(0,0,0,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0px"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="It was an honour to have been invited to President Biden’s speech in Belfast, which marked 25 years since the Good Friday Agreement was signed."
                  {...getOverrideProps(
                    overrides,
                    "It was an honour to have been invited to President Biden\u2019s speech in Belfast, which marked 25 years since the Good Friday Agreement was signed."
                  )}
                ></Text>
                <Text
                  fontFamily="Inter"
                  fontSize="13px"
                  fontWeight="400"
                  color="rgba(0,0,0,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0px"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="The President placed an emphasis on the next generation of leaders in Northern Ireland and the valuable work that’s being done in the push for further progress."
                  {...getOverrideProps(
                    overrides,
                    "The President placed an emphasis on the next generation of leaders in Northern Ireland and the valuable work that\u2019s being done in the push for further progress."
                  )}
                ></Text>
                <Button
                  width="unset"
                  height="unset"
                  shrink="0"
                  size="small"
                  isDisabled={false}
                  variation="default"
                  children="Read more"
                  onClick={() => {
                    buttonFourZeroThreeThreeThreeSixOneFiveOnClick();
                  }}
                  {...getOverrideProps(overrides, "Button40333615")}
                ></Button>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            gap="50px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            borderRadius="20px"
            padding="20px 30px 20px 30px"
            backgroundColor="rgba(245,245,247,1)"
            display="flex"
            onClick={() => {
              frameThreeSevenNineOnClick();
            }}
            {...getOverrideProps(overrides, "Frame 379")}
          >
            <Image
              width="250px"
              height="158.68px"
              display="none"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              borderRadius="20px"
              padding="0px 0px 0px 0px"
              objectFit="cover"
              src="https://personal-website-liam.s3.eu-west-1.amazonaws.com/public/liam-uk-minister.JPG"
              onClick={() => {
                iMGUnderScoreZeroZeroThreeThreeTwoThreeEightNineFiveTwoNineZeroZeroOnClick();
              }}
              {...getOverrideProps(overrides, "IMG_0033 238952900")}
            ></Image>
            <Flex
              gap="10px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              grow="1"
              shrink="1"
              basis="0"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 42338952901")}
            >
              <Flex
                gap="0"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 20px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 44338952902")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="20px"
                  fontWeight="600"
                  color="rgba(0,0,0,1)"
                  lineHeight="30px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Meeting with UK Minister"
                  {...getOverrideProps(
                    overrides,
                    "Meeting with UK Minister38952903"
                  )}
                ></Text>
              </Flex>
              <Image
                width="220px"
                height="139.64px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                shrink="0"
                position="relative"
                borderRadius="20px"
                padding="0px 0px 0px 0px"
                objectFit="cover"
                src="https://personal-website-liam.s3.eu-west-1.amazonaws.com/public/liam-uk-minister.JPG"
                {...getOverrideProps(overrides, "IMG_0033 338952904")}
              ></Image>
              <Flex
                gap="20px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="20px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 42538952905")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="13px"
                  fontWeight="400"
                  color="rgba(0,0,0,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0px"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Pleased to have met with UK Cabinet Minister, Conor Burns (Northern Ireland Office), at the Belfast office."
                  {...getOverrideProps(
                    overrides,
                    "Pleased to have met with UK Cabinet Minister, Conor Burns (Northern Ireland Office), at the Belfast office.38952906"
                  )}
                ></Text>
                <Text
                  fontFamily="Inter"
                  fontSize="13px"
                  fontWeight="400"
                  color="rgba(0,0,0,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0px"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="I was joined by Holly Toner and we presented to the Minister just some of the interesting work that we do within the Innovation team."
                  {...getOverrideProps(
                    overrides,
                    "I was joined by Holly Toner and we presented to the Minister just some of the interesting work that we do within the Innovation team.38952907"
                  )}
                ></Text>
                <Button
                  width="unset"
                  height="unset"
                  shrink="0"
                  size="small"
                  isDisabled={false}
                  variation="default"
                  children="Read more"
                  onClick={() => {
                    buttonFourZeroFourSixFourOneThreeFourOnClick();
                  }}
                  {...getOverrideProps(overrides, "Button40464134")}
                ></Button>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            gap="50px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            borderRadius="20px"
            padding="20px 30px 20px 30px"
            backgroundColor="rgba(245,245,247,1)"
            display="flex"
            onClick={() => {
              frameThreeEightZeroOnClick();
            }}
            {...getOverrideProps(overrides, "Frame 380")}
          >
            <Image
              width="250px"
              height="158.68px"
              display="none"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              borderRadius="20px"
              padding="0px 0px 0px 0px"
              objectFit="cover"
              src="https://personal-website-liam.s3.eu-west-1.amazonaws.com/public/liam-microsoft-height-cropped.JPG"
              onClick={() => {
                iMGUnderScoreZeroZeroThreeThreeTwoThreeEightNineFiveTwoNineZeroNineOnClick();
              }}
              {...getOverrideProps(overrides, "IMG_0033 238952909")}
            ></Image>
            <Flex
              gap="10px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              grow="1"
              shrink="1"
              basis="0"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 42338952910")}
            >
              <Flex
                gap="0"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 20px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 44338952911")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="20px"
                  fontWeight="600"
                  color="rgba(0,0,0,1)"
                  lineHeight="30px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Presenting at&#xA;Microsoft’s UK Office"
                  {...getOverrideProps(
                    overrides,
                    "Presenting at Microsoft\u2019s UK Office38952912"
                  )}
                ></Text>
              </Flex>
              <Image
                width="220px"
                height="139.64px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                shrink="0"
                position="relative"
                borderRadius="20px"
                padding="0px 0px 0px 0px"
                objectFit="cover"
                src="https://personal-website-liam.s3.eu-west-1.amazonaws.com/public/liam-microsoft.JPG"
                {...getOverrideProps(overrides, "IMG_0033 338952913")}
              ></Image>
              <Flex
                gap="20px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="20px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 42538952914")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="13px"
                  fontWeight="400"
                  color="rgba(0,0,0,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0px"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="It was a great time presenting at Microsoft’s offices for the TeenTech hackathon."
                  {...getOverrideProps(
                    overrides,
                    "It was a great time presenting at Microsoft\u2019s offices for the TeenTech hackathon.38952915"
                  )}
                ></Text>
                <Text
                  fontFamily="Inter"
                  fontSize="13px"
                  fontWeight="400"
                  color="rgba(0,0,0,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0px"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="I was joined by Michael Kerrigan and we led multiple sessions on how we’ve used Computer Vision and ML to tackle difficult problems - along with ways the students can apply the same kind of innovative thinking in their hackathon projects."
                  {...getOverrideProps(
                    overrides,
                    "I was joined by Michael Kerrigan and we led multiple sessions on how we\u2019ve used Computer Vision and ML to tackle difficult problems - along with ways the students can apply the same kind of innovative thinking in their hackathon projects.38952916"
                  )}
                ></Text>
                <Button
                  width="unset"
                  height="unset"
                  shrink="0"
                  size="small"
                  isDisabled={false}
                  variation="default"
                  children="Read more"
                  onClick={() => {
                    buttonFourZeroFourZeroThreeNineZeroFiveOnClick();
                  }}
                  {...getOverrideProps(overrides, "Button40403905")}
                ></Button>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            gap="50px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            borderRadius="20px"
            padding="20px 30px 20px 30px"
            backgroundColor="rgba(245,245,247,1)"
            display="flex"
            onClick={() => {
              frameThreeEightOneOnClick();
            }}
            {...getOverrideProps(overrides, "Frame 381")}
          >
            <Image
              width="250px"
              height="158.68px"
              display="none"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              borderRadius="20px"
              padding="0px 0px 0px 0px"
              objectFit="cover"
              src="https://personal-website-liam.s3.eu-west-1.amazonaws.com/public/liam-uu-awards.jpg"
              onClick={() => {
                iMGUnderScoreZeroZeroThreeThreeTwoThreeEightNineFiveTwoNineOneEightOnClick();
              }}
              {...getOverrideProps(overrides, "IMG_0033 238952918")}
            ></Image>
            <Flex
              gap="10px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              grow="1"
              shrink="1"
              basis="0"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 42338952919")}
            >
              <Flex
                gap="0"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 20px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 44338952920")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="20px"
                  fontWeight="600"
                  color="rgba(0,0,0,1)"
                  lineHeight="30px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Best Mobile Technology Project"
                  {...getOverrideProps(
                    overrides,
                    "Best Mobile Technology Project38952921"
                  )}
                ></Text>
              </Flex>
              <Image
                width="220px"
                height="139.64px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                shrink="0"
                position="relative"
                borderRadius="20px"
                padding="0px 0px 0px 0px"
                objectFit="cover"
                src="https://personal-website-liam.s3.eu-west-1.amazonaws.com/public/liam-uu-awards.jpg"
                {...getOverrideProps(overrides, "IMG_0033 338952922")}
              ></Image>
              <Flex
                gap="20px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="20px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 42538952923")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="13px"
                  fontWeight="400"
                  color="rgba(0,0,0,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0px"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Delighted to pick up my award for “Best Mobile Technology Project” at Ulster University, after the awards ceremony was delayed due to Covid."
                  {...getOverrideProps(
                    overrides,
                    "Delighted to pick up my award for \u201CBest Mobile Technology Project\u201D at Ulster University, after the awards ceremony was delayed due to Covid.38952924"
                  )}
                ></Text>
                <Text
                  fontFamily="Inter"
                  fontSize="13px"
                  fontWeight="400"
                  color="rgba(0,0,0,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0px"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="This project will allow Search & Rescue teams in Derry to better organise their public searches for missing people - and quickly share information with the public when there’s an emergency."
                  {...getOverrideProps(
                    overrides,
                    "This project will allow Search & Rescue teams in Derry to better organise their public searches for missing people - and quickly share information with the public when there\u2019s an emergency.38952925"
                  )}
                ></Text>
                <Button
                  width="unset"
                  height="unset"
                  shrink="0"
                  size="small"
                  isDisabled={false}
                  variation="default"
                  children="Read more"
                  onClick={() => {
                    buttonFourZeroFourSixFourOneThreeEightOnClick();
                  }}
                  {...getOverrideProps(overrides, "Button40464138")}
                ></Button>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            gap="50px"
            direction="row"
            width="1120px"
            height="340px"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            position="relative"
            borderRadius="20px"
            padding="15px 0px 15px 0px"
            backgroundColor="rgba(245,245,247,1)"
            display="none"
            {...getOverrideProps(overrides, "Frame 377")}
          >
            <Image
              width="350px"
              height="222.15px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              borderRadius="20px"
              padding="0px 0px 0px 0px"
              objectFit="cover"
              {...getOverrideProps(overrides, "IMG_0033 5")}
            ></Image>
            <Flex
              gap="10px"
              direction="column"
              width="519px"
              height="unset"
              justifyContent="center"
              alignItems="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 42338952773")}
            >
              <Text
                fontFamily="Inter"
                fontSize="32px"
                fontWeight="600"
                color="rgba(0,0,0,1)"
                lineHeight="20px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="519px"
                height="48px"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Meeting with UK Minister"
                {...getOverrideProps(
                  overrides,
                  "Meeting with UK Minister38952774"
                )}
              ></Text>
              <Flex
                gap="20px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 428")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(0,0,0,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0.01px"
                  width="460px"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Pleased to have met with UK Cabinet Minister, Conor Burns (Northern Ireland Office), at the Belfast office."
                  {...getOverrideProps(
                    overrides,
                    "Pleased to have met with UK Cabinet Minister, Conor Burns (Northern Ireland Office), at the Belfast office.38952776"
                  )}
                ></Text>
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(0,0,0,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0.01px"
                  width="460px"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="I was joined by Holly Toner and we presented to the Minister just some of the interesting work that we do within the Innovation team."
                  {...getOverrideProps(
                    overrides,
                    "I was joined by Holly Toner and we presented to the Minister just some of the interesting work that we do within the Innovation team.38952777"
                  )}
                ></Text>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            gap="50px"
            direction="row"
            width="1120px"
            height="340px"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            position="relative"
            borderRadius="20px"
            padding="15px 0px 15px 0px"
            backgroundColor="rgba(245,245,247,1)"
            display="none"
            {...getOverrideProps(overrides, "Frame 378")}
          >
            <Image
              width="350px"
              height="222.15px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              borderRadius="20px"
              padding="0px 0px 0px 0px"
              objectFit="cover"
              {...getOverrideProps(overrides, "IMG_0033 338952779")}
            ></Image>
            <Flex
              gap="10px"
              direction="column"
              width="519px"
              height="unset"
              justifyContent="center"
              alignItems="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 42338952780")}
            >
              <Text
                fontFamily="Inter"
                fontSize="32px"
                fontWeight="600"
                color="rgba(0,0,0,1)"
                lineHeight="20px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="568px"
                height="48px"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Presenting at Microsoft’s UK Office"
                {...getOverrideProps(
                  overrides,
                  "Presenting at Microsoft\u2019s UK Office38952781"
                )}
              ></Text>
              <Flex
                gap="20px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 426")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(0,0,0,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0.01px"
                  width="481px"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="It was a great time presenting at Microsoft’s offices for the TeenTech hackathon."
                  {...getOverrideProps(
                    overrides,
                    "It was a great time presenting at Microsoft\u2019s offices for the TeenTech hackathon.38952783"
                  )}
                ></Text>
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(0,0,0,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0.01px"
                  width="481px"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="I was joined by Michael Kerrigan and we led multiple sessions on how we’ve used Computer Vision and ML to tackle difficult problems - along with ways the students can apply the same kind of innovative thinking in their hackathon projects."
                  {...getOverrideProps(
                    overrides,
                    "I was joined by Michael Kerrigan and we led multiple sessions on how we\u2019ve used Computer Vision and ML to tackle difficult problems - along with ways the students can apply the same kind of innovative thinking in their hackathon projects.38952784"
                  )}
                ></Text>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            gap="50px"
            direction="row"
            width="1120px"
            height="340px"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            position="relative"
            borderRadius="20px"
            padding="15px 0px 15px 0px"
            backgroundColor="rgba(245,245,247,1)"
            display="none"
            {...getOverrideProps(overrides, "Frame 376")}
          >
            <Image
              width="350px"
              height="222.15px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              borderRadius="20px"
              padding="0px 0px 0px 0px"
              objectFit="cover"
              {...getOverrideProps(overrides, "IMG_0033 4")}
            ></Image>
            <Flex
              gap="10px"
              direction="column"
              width="519px"
              height="unset"
              justifyContent="center"
              alignItems="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 42338952787")}
            >
              <Text
                fontFamily="Inter"
                fontSize="32px"
                fontWeight="600"
                color="rgba(0,0,0,1)"
                lineHeight="20px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="519px"
                height="48px"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Best Mobile Technology Project"
                {...getOverrideProps(
                  overrides,
                  "Best Mobile Technology Project38952788"
                )}
              ></Text>
              <Flex
                gap="20px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 427")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(0,0,0,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0.01px"
                  width="490px"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Delighted to pick up my award for “Best Mobile Technology Project” at Ulster University, after the awards ceremony was delayed due to Covid."
                  {...getOverrideProps(
                    overrides,
                    "Delighted to pick up my award for \u201CBest Mobile Technology Project\u201D at Ulster University, after the awards ceremony was delayed due to Covid.38952790"
                  )}
                ></Text>
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(0,0,0,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0.01px"
                  width="490px"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="This project will allow Search & Rescue teams in Derry to better organise their public searches for missing people - and quickly share information with the public when there’s an emergency."
                  {...getOverrideProps(
                    overrides,
                    "This project will allow Search & Rescue teams in Derry to better organise their public searches for missing people - and quickly share information with the public when there\u2019s an emergency.38952791"
                  )}
                ></Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
