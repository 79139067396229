import React, { useEffect } from 'react';
import { NavigationBarResponsive, MicrosoftFooterResponsive, UkMinisterResponsive, UkMinisterFooterResponsive }  from '../ui-components';

const MinisterPage = () => {
    useEffect(() => {
        document.title = 'UK Cabinet Minister - Liam McCormick';
    }, []);

    return (
        <div>
            <NavigationBarResponsive width={"100%"}/>
            <UkMinisterResponsive width={"100%"}/>
            <UkMinisterFooterResponsive width={"100%"}/>
        </div>
    );
}

export default MinisterPage;