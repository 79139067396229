/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import { Flex, Text, View, useBreakpointValue } from "@aws-amplify/ui-react";
export default function MicrosoftFooterResponsive(props) {
  const { overrides: overridesProp, ...restProp } = props;
  const variants = [
    {
      variantValues: { breakpoint: "xxl" },
      overrides: {
        "Rectangle 738": { width: "1536px" },
        "\u00A9 2023 Liam McCormick": {
          fontSize: "16px",
          letterSpacing: "0.01px",
        },
        "Frame 467": { gap: "30px", padding: "0px 70px 30px 70px" },
        MicrosoftFooterResponsive: { width: "unset" },
      },
    },
    {
      variantValues: { breakpoint: "xl" },
      overrides: {
        "Rectangle 738": { width: "1280px" },
        "\u00A9 2023 Liam McCormick": {
          fontSize: "16px",
          letterSpacing: "0.01px",
        },
        "Frame 467": { gap: "30px", padding: "0px 50px 30px 50px" },
        MicrosoftFooterResponsive: { width: "unset" },
      },
    },
    {
      variantValues: { breakpoint: "large" },
      overrides: {
        "Rectangle 738": { width: "992px" },
        "\u00A9 2023 Liam McCormick": { fontSize: "14px" },
        "Frame 467": { gap: "25px", padding: "0px 60px 30px 60px" },
        MicrosoftFooterResponsive: { width: "unset" },
      },
    },
    {
      variantValues: { breakpoint: "medium" },
      overrides: {
        "Rectangle 738": { width: "768px" },
        "\u00A9 2023 Liam McCormick": { fontSize: "14px" },
        "Frame 467": { gap: "25px", padding: "0px 40px 30px 40px" },
        MicrosoftFooterResponsive: { width: "768px" },
      },
    },
    {
      variantValues: { breakpoint: "small" },
      overrides: {
        "Rectangle 738": { width: "480px" },
        "\u00A9 2023 Liam McCormick": { fontSize: "14px" },
        "Frame 467": { padding: "0px 30px 20px 30px" },
        MicrosoftFooterResponsive: { width: "480px" },
      },
    },
    {
      variantValues: { breakpoint: "base" },
      overrides: {
        "Rectangle 738": {},
        "\u00A9 2023 Liam McCormick": {},
        "Frame 467": {},
        MicrosoftFooterResponsive: {},
      },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "base",
    small: "small",
    medium: "medium",
    large: "large",
    xl: "xl",
    xxl: "xxl",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  return (
    <Flex
      gap="10px"
      direction="column"
      width="380px"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(255,255,255,1)"
      display="flex"
      {...getOverrideProps(overrides, "MicrosoftFooterResponsive")}
      {...rest}
    >
      <View
        width="unset"
        height="55px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(255,255,255,1)"
        {...getOverrideProps(overrides, "Rectangle 738")}
      ></View>
      <Flex
        gap="20px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 10px 20px 10px"
        display="flex"
        {...getOverrideProps(overrides, "Frame 467")}
      >
        <Text
          fontFamily="Inter"
          fontSize="13px"
          fontWeight="400"
          color="rgba(0,0,0,1)"
          lineHeight="24px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          letterSpacing="0px"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="© 2023 Liam McCormick"
          {...getOverrideProps(overrides, "\u00A9 2023 Liam McCormick")}
        ></Text>
      </Flex>
    </Flex>
  );
}
