import React, { useEffect } from 'react';
import { NavigationBarResponsive, UniAwardResponsive, UniAwardFooterResponsive }  from '../ui-components';

const UniAwardsPage = () => {
    useEffect(() => {
        document.title = 'Best Project Award - Liam McCormick';
    }, []);

    return (
        <div>
            <NavigationBarResponsive width={"100%"}/>
            <UniAwardResponsive width={"100%"} />
            <UniAwardFooterResponsive width={"100%"}/>
        </div>
    );
}

export default UniAwardsPage;