/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
  useNavigateAction,
} from "@aws-amplify/ui-react/internal";
import { Flex, Image, Text, useBreakpointValue } from "@aws-amplify/ui-react";
export default function MicrosoftResponsive(props) {
  const { overrides: overridesProp, ...restProp } = props;
  const variants = [
    {
      variantValues: { breakpoint: "small" },
      overrides: {
        "Presenting at Microsoft\u2019s UK Office": { fontSize: "36px" },
        TitleFrame: { padding: "20px 50px 20px 50px" },
        "liam-microsoft-height-cropped": { height: "320.63px" },
        "Frame 449": {},
        "I had a great time presenting at Microsoft\u2019s UK headquarters in Reading, as part of the TeenTech hackathon.":
          { fontSize: "14px" },
        "TeenTech aims to inspire young people and encourage them to pursue a career in the tech industry - with other 10,000 involved every year.":
          { fontSize: "14px" },
        "The students were aged between 13-18 and tasked with using technology in a way that will improve patient safety in healthcare.":
          { fontSize: "14px" },
        "I was joined by Michael Kerrigan for the event. We led multiple sessions on how we\u2019ve used Computer Vision and Machine Learning models to tackle difficult problems. We also outlined how the students could apply the same kind of innovative thinking in their hackathon projects.":
          { fontSize: "14px" },
        "Frame 458": {},
        "Frame 136": { width: "380px" },
        "microsoft-office-outside": { width: "380px", height: "387.29px" },
        "Frame 450": {},
        "It was great to see how engaged the students were and we were impressed by the level of technical knowledge they had.":
          { fontSize: "14px" },
        "I was pleased that Microsoft reached out and asked us to deliver the event alongside them - plus it helped both of us to brush up our in-person presenting skills, after being well-used to presenting virtually.":
          { fontSize: "14px" },
        "Frame 460": {},
        "Read more:": { fontSize: "20px", letterSpacing: "0.05px" },
        "teentech-article": { width: "380px", height: "163.47px" },
        "Frame 461": { gap: "24px" },
        "Frame 451": { width: "380px" },
        MicrosoftResponsive: { width: "480px" },
      },
    },
    {
      variantValues: { breakpoint: "medium" },
      overrides: {
        "Presenting at Microsoft\u2019s UK Office": { fontSize: "36px" },
        TitleFrame: { padding: "20px 50px 20px 50px" },
        "liam-microsoft-height-cropped": { height: "513px" },
        "Frame 449": {},
        "I had a great time presenting at Microsoft\u2019s UK headquarters in Reading, as part of the TeenTech hackathon.":
          { fontSize: "14px" },
        "TeenTech aims to inspire young people and encourage them to pursue a career in the tech industry - with other 10,000 involved every year.":
          { fontSize: "14px" },
        "The students were aged between 13-18 and tasked with using technology in a way that will improve patient safety in healthcare.":
          { fontSize: "14px" },
        "I was joined by Michael Kerrigan for the event. We led multiple sessions on how we\u2019ve used Computer Vision and Machine Learning models to tackle difficult problems. We also outlined how the students could apply the same kind of innovative thinking in their hackathon projects.":
          { fontSize: "14px" },
        "Frame 458": {},
        "Frame 136": { width: "520px" },
        "microsoft-office-outside": { width: "520px", height: "529.97px" },
        "Frame 450": {},
        "It was great to see how engaged the students were and we were impressed by the level of technical knowledge they had.":
          { fontSize: "14px" },
        "I was pleased that Microsoft reached out and asked us to deliver the event alongside them - plus it helped both of us to brush up our in-person presenting skills, after being well-used to presenting virtually.":
          { fontSize: "14px" },
        "Frame 460": {},
        "Read more:": { fontSize: "24px", letterSpacing: "0.09px" },
        "teentech-article": {
          width: "520px",
          height: "223.7px",
          borderRadius: "20px",
        },
        "Frame 461": { gap: "24px", padding: "50px 0px 0px 0px" },
        "Frame 451": { width: "520px" },
        MicrosoftResponsive: { width: "768px" },
      },
    },
    {
      variantValues: { breakpoint: "large" },
      overrides: {
        "Presenting at Microsoft\u2019s UK Office": { fontSize: "40px" },
        TitleFrame: { padding: "20px 50px 20px 50px" },
        "liam-microsoft-height-cropped": { height: "662.63px" },
        "Frame 449": {},
        "I had a great time presenting at Microsoft\u2019s UK headquarters in Reading, as part of the TeenTech hackathon.":
          { fontSize: "14px" },
        "TeenTech aims to inspire young people and encourage them to pursue a career in the tech industry - with other 10,000 involved every year.":
          { fontSize: "14px" },
        "The students were aged between 13-18 and tasked with using technology in a way that will improve patient safety in healthcare.":
          { fontSize: "14px" },
        "I was joined by Michael Kerrigan for the event. We led multiple sessions on how we\u2019ve used Computer Vision and Machine Learning models to tackle difficult problems. We also outlined how the students could apply the same kind of innovative thinking in their hackathon projects.":
          { fontSize: "14px" },
        "Frame 458": {},
        "Frame 136": { width: "650px" },
        "microsoft-office-outside": { width: "650px", height: "662.47px" },
        "Frame 450": {},
        "It was great to see how engaged the students were and we were impressed by the level of technical knowledge they had.":
          { fontSize: "14px" },
        "I was pleased that Microsoft reached out and asked us to deliver the event alongside them - plus it helped both of us to brush up our in-person presenting skills, after being well-used to presenting virtually.":
          { fontSize: "14px" },
        "Frame 460": {},
        "Read more:": { fontSize: "24px", letterSpacing: "0.09px" },
        "teentech-article": {
          width: "650px",
          height: "279.62px",
          borderRadius: "20px",
        },
        "Frame 461": { gap: "32px", padding: "50px 0px 0px 0px" },
        "Frame 451": { width: "650px" },
        MicrosoftResponsive: { width: "992px" },
      },
    },
    {
      variantValues: { breakpoint: "xl" },
      overrides: {
        "Presenting at Microsoft\u2019s UK Office": { fontSize: "48px" },
        TitleFrame: { padding: "20px 50px 20px 50px" },
        "liam-microsoft-height-cropped": { height: "855px" },
        "Frame 449": {},
        "I had a great time presenting at Microsoft\u2019s UK headquarters in Reading, as part of the TeenTech hackathon.":
          { fontSize: "16px", letterSpacing: "0.01px" },
        "TeenTech aims to inspire young people and encourage them to pursue a career in the tech industry - with other 10,000 involved every year.":
          { fontSize: "16px", letterSpacing: "0.01px" },
        "The students were aged between 13-18 and tasked with using technology in a way that will improve patient safety in healthcare.":
          { fontSize: "16px", letterSpacing: "0.01px" },
        "I was joined by Michael Kerrigan for the event. We led multiple sessions on how we\u2019ve used Computer Vision and Machine Learning models to tackle difficult problems. We also outlined how the students could apply the same kind of innovative thinking in their hackathon projects.":
          { fontSize: "16px", letterSpacing: "0.01px" },
        "Frame 458": {},
        "Frame 136": { width: "800px" },
        "microsoft-office-outside": { width: "800px", height: "815.34px" },
        "Frame 450": {},
        "It was great to see how engaged the students were and we were impressed by the level of technical knowledge they had.":
          { fontSize: "16px", letterSpacing: "0.01px" },
        "I was pleased that Microsoft reached out and asked us to deliver the event alongside them - plus it helped both of us to brush up our in-person presenting skills, after being well-used to presenting virtually.":
          { fontSize: "16px", letterSpacing: "0.01px" },
        "Frame 460": {},
        "Read more:": { fontSize: "24px", letterSpacing: "0.09px" },
        "teentech-article": {
          width: "800px",
          height: "344.15px",
          borderRadius: "20px",
        },
        "Frame 461": { gap: "32px", padding: "50px 0px 0px 0px" },
        "Frame 451": { width: "800px" },
        MicrosoftResponsive: { width: "1280px" },
      },
    },
    {
      variantValues: { breakpoint: "xxl" },
      overrides: {
        "Presenting at Microsoft\u2019s UK Office": { fontSize: "48px" },
        TitleFrame: { padding: "20px 50px 20px 50px" },
        "liam-microsoft-height-cropped": { height: "1026px" },
        "Frame 449": {},
        "I had a great time presenting at Microsoft\u2019s UK headquarters in Reading, as part of the TeenTech hackathon.":
          { fontSize: "16px", letterSpacing: "0.01px" },
        "TeenTech aims to inspire young people and encourage them to pursue a career in the tech industry - with other 10,000 involved every year.":
          { fontSize: "16px", letterSpacing: "0.01px" },
        "The students were aged between 13-18 and tasked with using technology in a way that will improve patient safety in healthcare.":
          { fontSize: "16px", letterSpacing: "0.01px" },
        "I was joined by Michael Kerrigan for the event. We led multiple sessions on how we\u2019ve used Computer Vision and Machine Learning models to tackle difficult problems. We also outlined how the students could apply the same kind of innovative thinking in their hackathon projects.":
          { fontSize: "16px", letterSpacing: "0.01px" },
        "Frame 458": {},
        "Frame 136": { width: "800px" },
        "microsoft-office-outside": { width: "800px", height: "815.34px" },
        "Frame 450": {},
        "It was great to see how engaged the students were and we were impressed by the level of technical knowledge they had.":
          { fontSize: "16px", letterSpacing: "0.01px" },
        "I was pleased that Microsoft reached out and asked us to deliver the event alongside them - plus it helped both of us to brush up our in-person presenting skills, after being well-used to presenting virtually.":
          { fontSize: "16px", letterSpacing: "0.01px" },
        "Frame 460": {},
        "Read more:": { fontSize: "24px", letterSpacing: "0.09px" },
        "teentech-article": {
          width: "800px",
          height: "344.15px",
          borderRadius: "20px",
        },
        "Frame 461": { gap: "32px", padding: "50px 0px 0px 0px" },
        "Frame 451": { width: "800px" },
        MicrosoftResponsive: { width: "1536px" },
      },
    },
    {
      variantValues: { breakpoint: "base" },
      overrides: {
        "Presenting at Microsoft\u2019s UK Office": {},
        TitleFrame: {},
        "liam-microsoft-height-cropped": {},
        "Frame 449": {},
        "I had a great time presenting at Microsoft\u2019s UK headquarters in Reading, as part of the TeenTech hackathon.":
          {},
        "TeenTech aims to inspire young people and encourage them to pursue a career in the tech industry - with other 10,000 involved every year.":
          {},
        "The students were aged between 13-18 and tasked with using technology in a way that will improve patient safety in healthcare.":
          {},
        "I was joined by Michael Kerrigan for the event. We led multiple sessions on how we\u2019ve used Computer Vision and Machine Learning models to tackle difficult problems. We also outlined how the students could apply the same kind of innovative thinking in their hackathon projects.":
          {},
        "Frame 458": {},
        "Frame 136": {},
        "microsoft-office-outside": {},
        "Frame 450": {},
        "It was great to see how engaged the students were and we were impressed by the level of technical knowledge they had.":
          {},
        "I was pleased that Microsoft reached out and asked us to deliver the event alongside them - plus it helped both of us to brush up our in-person presenting skills, after being well-used to presenting virtually.":
          {},
        "Frame 460": {},
        "Read more:": {},
        "teentech-article": {},
        "Frame 461": {},
        "Frame 451": {},
        MicrosoftResponsive: {},
      },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "base",
    small: "small",
    medium: "medium",
    large: "large",
    xl: "xl",
    xxl: "xxl",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  const teentecharticleOnClick = useNavigateAction({
    type: "url",
    url: "https://teentech.com/patient-safety-innovation-hack-reading/",
    target: "_blank",
  });
  return (
    <Flex
      gap="0"
      direction="column"
      width="380px"
      height="unset"
      justifyContent="center"
      alignItems="center"
      position="relative"
      padding="80px 0px 80px 0px"
      backgroundColor="rgba(245,245,247,1)"
      display="flex"
      {...getOverrideProps(overrides, "MicrosoftResponsive")}
      {...rest}
    >
      <Flex
        gap="10px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 50px 0px 50px"
        display="flex"
        {...getOverrideProps(overrides, "TitleFrame")}
      >
        <Text
          fontFamily="Inter"
          fontSize="32px"
          fontWeight="600"
          color="rgba(0,0,0,1)"
          lineHeight="35px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Presenting at Microsoft’s UK Office"
          {...getOverrideProps(
            overrides,
            "Presenting at Microsoft\u2019s UK Office"
          )}
        ></Text>
      </Flex>
      <Flex
        gap="10px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="50px 0px 50px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Frame 449")}
      >
        <Image
          width="unset"
          height="253.83px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          objectFit="cover"
          src="https://personal-website-liam.s3.eu-west-1.amazonaws.com/public/liam-microsoft-height-cropped.JPG"
          {...getOverrideProps(overrides, "liam-microsoft-height-cropped")}
        ></Image>
      </Flex>
      <Flex
        gap="24px"
        direction="column"
        width="300px"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Frame 136")}
      >
        <Flex
          gap="16px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 458")}
        >
          <Text
            fontFamily="Inter"
            fontSize="13px"
            fontWeight="400"
            color="rgba(0,0,0,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="I had a great time presenting at Microsoft’s UK headquarters in Reading, as part of the TeenTech hackathon."
            {...getOverrideProps(
              overrides,
              "I had a great time presenting at Microsoft\u2019s UK headquarters in Reading, as part of the TeenTech hackathon."
            )}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="13px"
            fontWeight="400"
            color="rgba(0,0,0,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="TeenTech aims to inspire young people and encourage them to pursue a career in the tech industry - with other 10,000 involved every year."
            {...getOverrideProps(
              overrides,
              "TeenTech aims to inspire young people and encourage them to pursue a career in the tech industry - with other 10,000 involved every year."
            )}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="13px"
            fontWeight="400"
            color="rgba(0,0,0,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="The students were aged between 13-18 and tasked with using technology in a way that will improve patient safety in healthcare."
            {...getOverrideProps(
              overrides,
              "The students were aged between 13-18 and tasked with using technology in a way that will improve patient safety in healthcare."
            )}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="13px"
            fontWeight="400"
            color="rgba(0,0,0,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="I was joined by Michael Kerrigan for the event. We led multiple sessions on how we’ve used Computer Vision and Machine Learning models to tackle difficult problems. We also outlined how the students could apply the same kind of innovative thinking in their hackathon projects."
            {...getOverrideProps(
              overrides,
              "I was joined by Michael Kerrigan for the event. We led multiple sessions on how we\u2019ve used Computer Vision and Machine Learning models to tackle difficult problems. We also outlined how the students could apply the same kind of innovative thinking in their hackathon projects."
            )}
          ></Text>
        </Flex>
      </Flex>
      <Flex
        gap="10px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="50px 0px 50px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Frame 450")}
      >
        <Image
          width="300px"
          height="305.75px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          borderRadius="20px"
          padding="0px 0px 0px 0px"
          objectFit="cover"
          src="https://personal-website-liam.s3.eu-west-1.amazonaws.com/public/microsoft-office-outside.jpg"
          {...getOverrideProps(overrides, "microsoft-office-outside")}
        ></Image>
      </Flex>
      <Flex
        gap="24px"
        direction="column"
        width="300px"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Frame 451")}
      >
        <Flex
          gap="16px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 460")}
        >
          <Text
            fontFamily="Inter"
            fontSize="13px"
            fontWeight="400"
            color="rgba(0,0,0,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="It was great to see how engaged the students were and we were impressed by the level of technical knowledge they had. "
            {...getOverrideProps(
              overrides,
              "It was great to see how engaged the students were and we were impressed by the level of technical knowledge they had."
            )}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="13px"
            fontWeight="400"
            color="rgba(0,0,0,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="I was pleased that Microsoft reached out and asked us to deliver the event alongside them - plus it helped both of us to brush up our in-person presenting skills, after being well-used to presenting virtually."
            {...getOverrideProps(
              overrides,
              "I was pleased that Microsoft reached out and asked us to deliver the event alongside them - plus it helped both of us to brush up our in-person presenting skills, after being well-used to presenting virtually."
            )}
          ></Text>
        </Flex>
        <Flex
          gap="16px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="30px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 461")}
        >
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="700"
            color="rgba(0,0,0,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0.01px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Read more:"
            {...getOverrideProps(overrides, "Read more:")}
          ></Text>
          <Image
            width="300px"
            height="129.06px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            borderRadius="10px"
            padding="0px 0px 0px 0px"
            objectFit="cover"
            src="https://personal-website-liam.s3.eu-west-1.amazonaws.com/public/teentech-article.png"
            onClick={() => {
              teentecharticleOnClick();
            }}
            {...getOverrideProps(overrides, "teentech-article")}
          ></Image>
        </Flex>
      </Flex>
    </Flex>
  );
}
