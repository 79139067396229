/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
  useNavigateAction,
} from "@aws-amplify/ui-react/internal";
import { Flex, Text, View, useBreakpointValue } from "@aws-amplify/ui-react";
export default function BidenFooterResponsive(props) {
  const { overrides: overridesProp, ...restProp } = props;
  const variants = [
    {
      variantValues: { breakpoint: "xxl" },
      overrides: {
        "Rectangle 738": { width: "1536px" },
        Attribution: { fontSize: "24px", letterSpacing: "0.09px" },
        "To find the source for each image, click on the links below:": {
          fontSize: "16px",
          letterSpacing: "0.01px",
        },
        "President Biden Shoulder": {
          fontSize: "16px",
          letterSpacing: "0.01px",
        },
        "President Biden Long": { fontSize: "16px", letterSpacing: "0.01px" },
        "Frame 474": {},
        "\u00A9 2023 Liam McCormick": {
          fontSize: "16px",
          letterSpacing: "0.01px",
        },
        "Frame 467": { gap: "30px", padding: "0px 70px 30px 70px" },
        BidenFooterResponsive: { width: "unset" },
      },
    },
    {
      variantValues: { breakpoint: "xl" },
      overrides: {
        "Rectangle 738": { width: "1280px" },
        Attribution: { fontSize: "24px", letterSpacing: "0.09px" },
        "To find the source for each image, click on the links below:": {
          fontSize: "16px",
          letterSpacing: "0.01px",
        },
        "President Biden Shoulder": {
          fontSize: "16px",
          letterSpacing: "0.01px",
        },
        "President Biden Long": { fontSize: "16px", letterSpacing: "0.01px" },
        "Frame 474": {},
        "\u00A9 2023 Liam McCormick": {
          fontSize: "16px",
          letterSpacing: "0.01px",
        },
        "Frame 467": { gap: "30px", padding: "0px 50px 30px 50px" },
        BidenFooterResponsive: { width: "unset" },
      },
    },
    {
      variantValues: { breakpoint: "large" },
      overrides: {
        "Rectangle 738": { width: "992px" },
        Attribution: {},
        "To find the source for each image, click on the links below:": {
          fontSize: "14px",
        },
        "President Biden Shoulder": { fontSize: "14px" },
        "President Biden Long": { fontSize: "14px" },
        "Frame 474": {},
        "\u00A9 2023 Liam McCormick": { fontSize: "14px" },
        "Frame 467": { gap: "25px", padding: "0px 60px 30px 60px" },
        BidenFooterResponsive: { width: "unset" },
      },
    },
    {
      variantValues: { breakpoint: "medium" },
      overrides: {
        "Rectangle 738": { width: "768px" },
        Attribution: {},
        "To find the source for each image, click on the links below:": {
          fontSize: "14px",
        },
        "President Biden Shoulder": { fontSize: "14px" },
        "President Biden Long": { fontSize: "14px" },
        "Frame 474": {},
        "\u00A9 2023 Liam McCormick": { fontSize: "14px" },
        "Frame 467": { gap: "25px", padding: "0px 40px 30px 40px" },
        BidenFooterResponsive: { width: "768px" },
      },
    },
    {
      variantValues: { breakpoint: "small" },
      overrides: {
        "Rectangle 738": { width: "480px" },
        Attribution: {},
        "To find the source for each image, click on the links below:": {
          fontSize: "14px",
        },
        "President Biden Shoulder": { fontSize: "14px" },
        "President Biden Long": { fontSize: "14px" },
        "Frame 474": {},
        "\u00A9 2023 Liam McCormick": { fontSize: "14px" },
        "Frame 467": { padding: "0px 30px 20px 30px" },
        BidenFooterResponsive: { width: "480px" },
      },
    },
    {
      variantValues: { breakpoint: "base" },
      overrides: {
        "Rectangle 738": {},
        Attribution: {},
        "To find the source for each image, click on the links below:": {},
        "President Biden Shoulder": {},
        "President Biden Long": {},
        "Frame 474": {},
        "\u00A9 2023 Liam McCormick": {},
        "Frame 467": {},
        BidenFooterResponsive: {},
      },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "base",
    small: "small",
    medium: "medium",
    large: "large",
    xl: "xl",
    xxl: "xxl",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  const presidentBidenShoulderOnClick = useNavigateAction({
    type: "url",
    url: "https://www.gettyimages.co.uk/detail/news-photo/attendees-listen-as-us-president-joe-biden-delivers-a-news-photo/1251746838",
    target: "_blank",
  });
  const presidentBidenLongOnClick = useNavigateAction({
    type: "url",
    url: "https://presseye.com/media/dqQsylTZTXIFq5FV96l-hA..a?ts=H_8VJt7qH7gVjSTjFCxWXj3PmrrhYYMIQ9RspCoJHiA.a",
    target: "_blank",
  });
  return (
    <Flex
      gap="10px"
      direction="column"
      width="380px"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(255,255,255,1)"
      display="flex"
      {...getOverrideProps(overrides, "BidenFooterResponsive")}
      {...rest}
    >
      <View
        width="unset"
        height="55px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(255,255,255,1)"
        {...getOverrideProps(overrides, "Rectangle 738")}
      ></View>
      <Flex
        gap="20px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 10px 20px 10px"
        display="flex"
        {...getOverrideProps(overrides, "Frame 467")}
      >
        <Text
          fontFamily="Inter"
          fontSize="20px"
          fontWeight="700"
          color="rgba(0,0,0,1)"
          lineHeight="24px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          letterSpacing="0.05px"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Attribution"
          {...getOverrideProps(overrides, "Attribution")}
        ></Text>
        <Text
          fontFamily="Inter"
          fontSize="13px"
          fontWeight="400"
          color="rgba(0,0,0,1)"
          lineHeight="24px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          letterSpacing="0px"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="To find the source for each image, click on the links below:"
          {...getOverrideProps(
            overrides,
            "To find the source for each image, click on the links below:"
          )}
        ></Text>
        <Flex
          gap="2px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 474")}
        >
          <Text
            fontFamily="Inter"
            fontSize="13px"
            fontWeight="400"
            color="rgba(191,64,191,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="President Biden photo (over shoulder) - Jim Watson"
            onClick={() => {
              presidentBidenShoulderOnClick();
            }}
            {...getOverrideProps(overrides, "President Biden Shoulder")}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="13px"
            fontWeight="400"
            color="rgba(191,64,191,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="President Biden photo (long shot) - Kelvin Boyes, Press Eye"
            onClick={() => {
              presidentBidenLongOnClick();
            }}
            {...getOverrideProps(overrides, "President Biden Long")}
          ></Text>
        </Flex>
        <Text
          fontFamily="Inter"
          fontSize="13px"
          fontWeight="400"
          color="rgba(0,0,0,1)"
          lineHeight="24px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          letterSpacing="0px"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="© 2023 Liam McCormick"
          {...getOverrideProps(overrides, "\u00A9 2023 Liam McCormick")}
        ></Text>
      </Flex>
    </Flex>
  );
}
