/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
  useNavigateAction,
} from "@aws-amplify/ui-react/internal";
import { Flex, Image, Text, useBreakpointValue } from "@aws-amplify/ui-react";
export default function NavigationBarResponsive(props) {
  const { overrides: overridesProp, ...restProp } = props;
  const variants = [
    {
      variantValues: { breakpoint: "large" },
      overrides: {
        About: { fontSize: "20px", letterSpacing: "0.05px" },
        "Frame 437": {},
        Blog: { fontSize: "20px", letterSpacing: "0.05px" },
        "Frame 438": {},
        "Frame 321": {},
        image: {},
        Projects: { fontSize: "20px", letterSpacing: "0.05px" },
        "Frame 439": {},
        Contact: { fontSize: "20px", letterSpacing: "0.05px" },
        "Frame 440": {},
        "Frame 322": { gap: "0" },
        NavigationBarResponsive: { width: "992px" },
      },
    },
    {
      variantValues: { breakpoint: "small" },
      overrides: {
        About: { fontSize: "16px", letterSpacing: "0.01px" },
        "Frame 437": {},
        Blog: { fontSize: "16px", letterSpacing: "0.01px" },
        "Frame 438": {},
        "Frame 321": {},
        image: {},
        Projects: { fontSize: "16px", letterSpacing: "0.01px" },
        "Frame 439": {},
        Contact: { fontSize: "16px", letterSpacing: "0.01px" },
        "Frame 440": {},
        "Frame 322": { gap: "0" },
        NavigationBarResponsive: {
          width: "480px",
          padding: "24px 0px 24px 0px",
        },
      },
    },
    {
      variantValues: { breakpoint: "medium" },
      overrides: {
        About: { fontSize: "20px", letterSpacing: "0.05px" },
        "Frame 437": {},
        Blog: { fontSize: "20px", letterSpacing: "0.05px" },
        "Frame 438": {},
        "Frame 321": {},
        image: {},
        Projects: { fontSize: "20px", letterSpacing: "0.05px" },
        "Frame 439": {},
        Contact: { fontSize: "20px", letterSpacing: "0.05px" },
        "Frame 440": {},
        "Frame 322": { gap: "0" },
        NavigationBarResponsive: {
          width: "768px",
          padding: "24px 0px 24px 0px",
        },
      },
    },
    {
      variantValues: { breakpoint: "xl" },
      overrides: {
        About: { fontSize: "20px", letterSpacing: "0.05px" },
        "Frame 437": {},
        Blog: { fontSize: "20px", letterSpacing: "0.05px" },
        "Frame 438": {},
        "Frame 321": {},
        image: {},
        Projects: { fontSize: "20px", letterSpacing: "0.05px" },
        "Frame 439": {},
        Contact: { fontSize: "20px", letterSpacing: "0.05px" },
        "Frame 440": {},
        "Frame 322": { gap: "0" },
        NavigationBarResponsive: { width: "1280px" },
      },
    },
    {
      variantValues: { breakpoint: "xxl" },
      overrides: {
        About: { fontSize: "20px", letterSpacing: "0.05px" },
        "Frame 437": {},
        Blog: { fontSize: "20px", letterSpacing: "0.05px" },
        "Frame 438": {},
        "Frame 321": {},
        image: {},
        Projects: { fontSize: "20px", letterSpacing: "0.05px" },
        "Frame 439": {},
        Contact: { fontSize: "20px", letterSpacing: "0.05px" },
        "Frame 440": {},
        "Frame 322": { gap: "0" },
        NavigationBarResponsive: { width: "1536px" },
      },
    },
    {
      variantValues: { breakpoint: "base" },
      overrides: {
        About: {},
        "Frame 437": {},
        Blog: {},
        "Frame 438": {},
        "Frame 321": {},
        image: {},
        Projects: {},
        "Frame 439": {},
        Contact: {},
        "Frame 440": {},
        "Frame 322": {},
        NavigationBarResponsive: {},
      },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "base",
    small: "small",
    medium: "medium",
    large: "large",
    xl: "xl",
    xxl: "xxl",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  const frameFourThreeSevenOnClick = useNavigateAction({
    type: "url",
    url: "/",
  });
  const frameFourThreeEightOnClick = useNavigateAction({
    type: "url",
    url: "/blog",
  });
  const imageOnClick = useNavigateAction({ type: "url", url: "/" });
  const frameFourThreeNineOnClick = useNavigateAction({
    type: "url",
    url: "/projects",
  });
  const frameFourFourZeroOnClick = useNavigateAction({ type: "url", url: "/" });
  return (
    <Flex
      gap="20px"
      direction="row"
      width="380px"
      height="unset"
      justifyContent="center"
      alignItems="center"
      position="relative"
      padding="24px 32px 24px 32px"
      backgroundColor="rgba(255,255,255,1)"
      display="flex"
      {...getOverrideProps(overrides, "NavigationBarResponsive")}
      {...rest}
    >
      <Flex
        gap="0"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        grow="1"
        shrink="1"
        basis="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Frame 321")}
      >
        <Flex
          gap="10px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          grow="1"
          shrink="1"
          basis="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          onClick={() => {
            frameFourThreeSevenOnClick();
          }}
          {...getOverrideProps(overrides, "Frame 437")}
        >
          <Text
            fontFamily="Inter"
            fontSize="14px"
            fontWeight="500"
            color="rgba(0,0,0,1)"
            lineHeight="24px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="About"
            {...getOverrideProps(overrides, "About")}
          ></Text>
        </Flex>
        <Flex
          gap="10px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          grow="1"
          shrink="1"
          basis="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          onClick={() => {
            frameFourThreeEightOnClick();
          }}
          {...getOverrideProps(overrides, "Frame 438")}
        >
          <Text
            fontFamily="Inter"
            fontSize="14px"
            fontWeight="500"
            color="rgba(0,0,0,1)"
            lineHeight="24px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Blog"
            {...getOverrideProps(overrides, "Blog")}
          ></Text>
        </Flex>
      </Flex>
      <Image
        width="40px"
        height="40px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        borderRadius="160px"
        padding="0px 0px 0px 0px"
        objectFit="cover"
        src="https://personal-website-liam.s3.eu-west-1.amazonaws.com/public/liam-portrait-photo.JPG"
        onClick={() => {
          imageOnClick();
        }}
        {...getOverrideProps(overrides, "image")}
      ></Image>
      <Flex
        gap="10px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        grow="1"
        shrink="1"
        basis="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Frame 322")}
      >
        <Flex
          gap="10px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          grow="1"
          shrink="1"
          basis="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          onClick={() => {
            frameFourThreeNineOnClick();
          }}
          {...getOverrideProps(overrides, "Frame 439")}
        >
          <Text
            fontFamily="Inter"
            fontSize="14px"
            fontWeight="500"
            color="rgba(0,0,0,1)"
            lineHeight="24px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Projects"
            {...getOverrideProps(overrides, "Projects")}
          ></Text>
        </Flex>
        <Flex
          gap="10px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          grow="1"
          shrink="1"
          basis="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          onClick={() => {
            frameFourFourZeroOnClick();
          }}
          {...getOverrideProps(overrides, "Frame 440")}
        >
          <Text
            fontFamily="Inter"
            fontSize="14px"
            fontWeight="500"
            color="rgba(0,0,0,1)"
            lineHeight="24px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Contact"
            {...getOverrideProps(overrides, "Contact")}
          ></Text>
        </Flex>
      </Flex>
    </Flex>
  );
}
