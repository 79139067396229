/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import {
  Flex,
  Image,
  Text,
  View,
  useBreakpointValue,
} from "@aws-amplify/ui-react";
export default function ExperienceResponsive(props) {
  const { overrides: overridesProp, ...restProp } = props;
  const variants = [
    {
      variantValues: { breakpoint: "medium" },
      overrides: {
        Experience: {},
        "Frame 445": {},
        image39093222: {},
        "Frame 449": {},
        iOS39093224: { fontSize: "22px" },
        "Developed a mobile telemetry app for the iPhone - this can automatically track journeys, detect rapid acceleration & deceleration, speeding and other driving behaviour.39093225":
          { fontSize: "14px" },
        "Frame 458": {},
        "Frame 37439093220": {},
        image39093228: {},
        "Frame 469": {},
        OpenAI39093230: { fontSize: "22px" },
        "Experience of using OpenAI\u2019s models and have wrote articles on how developers can utilise Large Language Models for their day-to-day work.39093231":
          { fontSize: "14px" },
        "Frame 468": {},
        "Frame 37339093226": { display: "flex" },
        "Frame 13639093219": {},
        image39093235: {},
        "Frame 450": {},
        iOS39093237: {},
        "Developed a mobile telemetry app for the iPhone - this can automatically track journeys, detect rapid acceleration & deceleration, speeding and other driving behaviour.39093238":
          {},
        "Frame 459": {},
        "Frame 37439093233": {},
        image39093241: {},
        "Frame 451": {},
        OpenAI39093243: {},
        "Experience of using OpenAI\u2019s models and have wrote articles on how developers can utilise Large Language Models for their day-to-day work.39093244":
          { fontSize: "12px" },
        "Frame 460": {},
        "Frame 37339093239": { display: "none" },
        "Frame 446": { display: "none" },
        image39093248: {},
        "Frame 452": {},
        AWS39093250: { fontSize: "22px" },
        "Architected multiple projects to use AWS services such as Rekognition, Lambdas and QuickSight - to name a few. Achieved the Developer Associate certification from AWS.39093251":
          { fontSize: "14px" },
        "Frame 461": {},
        "Frame 37439093246": {},
        image39093254: {},
        "Frame 470": {},
        Azure39093256: { fontSize: "22px" },
        "Designed and developed a solution to use Azure\u2019s Data Factory service, which is now a key product for the company and it\u2019s customers.39093257":
          { fontSize: "14px" },
        "Frame 471": {},
        "Frame 37339093252": { display: "flex" },
        "Frame 393": {},
        image39093261: {},
        "Frame 453": {},
        AWS39093263: {},
        "Architected multiple projects to use AWS services such as Rekognition, Lambdas and QuickSight - to name a few. Achieved the Developer Associate certification from AWS.39093264":
          {},
        "Frame 462": {},
        "Frame 37439093259": {},
        image39093267: {},
        "Frame 454": {},
        Azure39093269: {},
        "Designed and developed a solution to use Azure\u2019s Data Factory service, which is now a key product for the company and it\u2019s customers.39093270":
          { fontSize: "12px" },
        "Frame 463": {},
        "Frame 37339093265": {},
        "Frame 447": { display: "none" },
        image39093274: {},
        "Frame 455": {},
        Python39093276: { fontSize: "22px" },
        "Extensive experience of using Python, which spans a wide range of projects - from Computer Vision to analysing satellite imagery.39093277":
          { fontSize: "14px" },
        "Frame 464": {},
        "Frame 37439093272": {},
        image39093280: {},
        "Frame 472": {},
        "C# and Xamarin39093282": { fontSize: "22px" },
        "Proficient in using C# - spent over a year developing safety software for power plants. Developed a mobile app for Foyle Search & Rescue, which allows them to more effectively search for missing people.39093283":
          { fontSize: "14px" },
        "Frame 473": {},
        "Frame 37339093278": { display: "flex" },
        "Frame 394": {},
        image39093287: {},
        "Frame 456": {},
        Python39093289: {},
        "Extensive experience of using Python, which spans a wide range of projects - from Computer Vision to analysing satellite imagery.39093290":
          {},
        "Frame 465": {},
        "Frame 37439093285": {},
        image39093293: {},
        "Frame 457": {},
        "C# and Xamarin39093295": {},
        "Proficient in using C# - spent over a year developing safety software for power plants. Developed a mobile app for Foyle Search & Rescue, which allows them to more effectively search for missing people.39093296":
          { fontSize: "12px" },
        "Frame 466": {},
        "Frame 37339093291": { display: "none" },
        "Frame 448": { display: "none" },
        "Frame 13639093216": {},
        ExperienceResponsive: {
          width: "768px",
          padding: "50px 100px 50px 100px",
        },
      },
    },
    {
      variantValues: { breakpoint: "large" },
      overrides: {
        Experience: { fontSize: "36px" },
        "Frame 445": {},
        image39093222: {},
        "Frame 449": {},
        iOS39093224: { fontSize: "24px" },
        "Developed a mobile telemetry app for the iPhone - this can automatically track journeys, detect rapid acceleration & deceleration, speeding and other driving behaviour.39093225":
          { fontSize: "14px" },
        "Frame 458": {},
        "Frame 37439093220": {},
        image39093228: {},
        "Frame 469": {},
        OpenAI39093230: { fontSize: "24px" },
        "Experience of using OpenAI\u2019s models and have wrote articles on how developers can utilise Large Language Models for their day-to-day work.39093231":
          { fontSize: "14px" },
        "Frame 468": {},
        "Frame 37339093226": { display: "flex" },
        "Frame 13639093219": {},
        image39093235: {},
        "Frame 450": {},
        iOS39093237: {},
        "Developed a mobile telemetry app for the iPhone - this can automatically track journeys, detect rapid acceleration & deceleration, speeding and other driving behaviour.39093238":
          {},
        "Frame 459": {},
        "Frame 37439093233": {},
        image39093241: {},
        "Frame 451": {},
        OpenAI39093243: {},
        "Experience of using OpenAI\u2019s models and have wrote articles on how developers can utilise Large Language Models for their day-to-day work.39093244":
          { fontSize: "12px" },
        "Frame 460": {},
        "Frame 37339093239": { display: "none" },
        "Frame 446": { display: "none" },
        image39093248: {},
        "Frame 452": {},
        AWS39093250: { fontSize: "24px" },
        "Architected multiple projects to use AWS services such as Rekognition, Lambdas and QuickSight - to name a few. Achieved the Developer Associate certification from AWS.39093251":
          { fontSize: "14px" },
        "Frame 461": {},
        "Frame 37439093246": {},
        image39093254: {},
        "Frame 470": {},
        Azure39093256: { fontSize: "24px" },
        "Designed and developed a solution to use Azure\u2019s Data Factory service, which is now a key product for the company and it\u2019s customers.39093257":
          { fontSize: "14px" },
        "Frame 471": {},
        "Frame 37339093252": { display: "flex" },
        "Frame 393": {},
        image39093261: {},
        "Frame 453": {},
        AWS39093263: {},
        "Architected multiple projects to use AWS services such as Rekognition, Lambdas and QuickSight - to name a few. Achieved the Developer Associate certification from AWS.39093264":
          {},
        "Frame 462": {},
        "Frame 37439093259": {},
        image39093267: {},
        "Frame 454": {},
        Azure39093269: {},
        "Designed and developed a solution to use Azure\u2019s Data Factory service, which is now a key product for the company and it\u2019s customers.39093270":
          { fontSize: "12px" },
        "Frame 463": {},
        "Frame 37339093265": {},
        "Frame 447": { display: "none" },
        image39093274: {},
        "Frame 455": {},
        Python39093276: { fontSize: "24px" },
        "Extensive experience of using Python, which spans a wide range of projects - from Computer Vision to analysing satellite imagery.39093277":
          { fontSize: "14px" },
        "Frame 464": {},
        "Frame 37439093272": {},
        image39093280: {},
        "Frame 472": {},
        "C# and Xamarin39093282": { fontSize: "24px" },
        "Proficient in using C# - spent over a year developing safety software for power plants. Developed a mobile app for Foyle Search & Rescue, which allows them to more effectively search for missing people.39093283":
          { fontSize: "14px" },
        "Frame 473": {},
        "Frame 37339093278": { display: "flex" },
        "Frame 394": {},
        image39093287: {},
        "Frame 456": {},
        Python39093289: {},
        "Extensive experience of using Python, which spans a wide range of projects - from Computer Vision to analysing satellite imagery.39093290":
          {},
        "Frame 465": {},
        "Frame 37439093285": {},
        image39093293: {},
        "Frame 457": {},
        "C# and Xamarin39093295": {},
        "Proficient in using C# - spent over a year developing safety software for power plants. Developed a mobile app for Foyle Search & Rescue, which allows them to more effectively search for missing people.39093296":
          { fontSize: "12px" },
        "Frame 466": {},
        "Frame 37339093291": { display: "none" },
        "Frame 448": { display: "none" },
        "Frame 13639093216": {},
        ExperienceResponsive: {
          width: "992px",
          padding: "50px 150px 50px 150px",
        },
      },
    },
    {
      variantValues: { breakpoint: "xl" },
      overrides: {
        Experience: { fontSize: "40px" },
        "Frame 445": {},
        image39093222: {},
        "Frame 449": {},
        iOS39093224: { fontSize: "24px" },
        "Developed a mobile telemetry app for the iPhone - this can automatically track journeys, detect rapid acceleration & deceleration, speeding and other driving behaviour.39093225":
          { fontSize: "16px", letterSpacing: "0.01px" },
        "Frame 458": {},
        "Frame 37439093220": {},
        image39093228: {},
        "Frame 469": {},
        OpenAI39093230: { fontSize: "24px" },
        "Experience of using OpenAI\u2019s models and have wrote articles on how developers can utilise Large Language Models for their day-to-day work.39093231":
          { fontSize: "16px", letterSpacing: "0.01px" },
        "Frame 468": {},
        "Frame 37339093226": { display: "flex" },
        "Frame 13639093219": {},
        image39093235: {},
        "Frame 450": {},
        iOS39093237: {},
        "Developed a mobile telemetry app for the iPhone - this can automatically track journeys, detect rapid acceleration & deceleration, speeding and other driving behaviour.39093238":
          {},
        "Frame 459": {},
        "Frame 37439093233": {},
        image39093241: {},
        "Frame 451": {},
        OpenAI39093243: {},
        "Experience of using OpenAI\u2019s models and have wrote articles on how developers can utilise Large Language Models for their day-to-day work.39093244":
          { fontSize: "12px" },
        "Frame 460": {},
        "Frame 37339093239": { display: "none" },
        "Frame 446": { display: "none" },
        image39093248: {},
        "Frame 452": {},
        AWS39093250: { fontSize: "24px" },
        "Architected multiple projects to use AWS services such as Rekognition, Lambdas and QuickSight - to name a few. Achieved the Developer Associate certification from AWS.39093251":
          { fontSize: "16px", letterSpacing: "0.01px" },
        "Frame 461": {},
        "Frame 37439093246": {},
        image39093254: {},
        "Frame 470": {},
        Azure39093256: { fontSize: "24px" },
        "Designed and developed a solution to use Azure\u2019s Data Factory service, which is now a key product for the company and it\u2019s customers.39093257":
          { fontSize: "16px", letterSpacing: "0.01px" },
        "Frame 471": {},
        "Frame 37339093252": { display: "flex" },
        "Frame 393": {},
        image39093261: {},
        "Frame 453": {},
        AWS39093263: {},
        "Architected multiple projects to use AWS services such as Rekognition, Lambdas and QuickSight - to name a few. Achieved the Developer Associate certification from AWS.39093264":
          {},
        "Frame 462": {},
        "Frame 37439093259": {},
        image39093267: {},
        "Frame 454": {},
        Azure39093269: {},
        "Designed and developed a solution to use Azure\u2019s Data Factory service, which is now a key product for the company and it\u2019s customers.39093270":
          { fontSize: "12px" },
        "Frame 463": {},
        "Frame 37339093265": {},
        "Frame 447": { display: "none" },
        image39093274: {},
        "Frame 455": {},
        Python39093276: { fontSize: "24px" },
        "Extensive experience of using Python, which spans a wide range of projects - from Computer Vision to analysing satellite imagery.39093277":
          { fontSize: "16px", letterSpacing: "0.01px" },
        "Frame 464": {},
        "Frame 37439093272": {},
        image39093280: {},
        "Frame 472": {},
        "C# and Xamarin39093282": { fontSize: "24px" },
        "Proficient in using C# - spent over a year developing safety software for power plants. Developed a mobile app for Foyle Search & Rescue, which allows them to more effectively search for missing people.39093283":
          { fontSize: "16px", letterSpacing: "0.01px" },
        "Frame 473": {},
        "Frame 37339093278": { display: "flex" },
        "Frame 394": {},
        image39093287: {},
        "Frame 456": {},
        Python39093289: {},
        "Extensive experience of using Python, which spans a wide range of projects - from Computer Vision to analysing satellite imagery.39093290":
          {},
        "Frame 465": {},
        "Frame 37439093285": {},
        image39093293: {},
        "Frame 457": {},
        "C# and Xamarin39093295": {},
        "Proficient in using C# - spent over a year developing safety software for power plants. Developed a mobile app for Foyle Search & Rescue, which allows them to more effectively search for missing people.39093296":
          { fontSize: "12px" },
        "Frame 466": {},
        "Frame 37339093291": { display: "none" },
        "Frame 448": { display: "none" },
        "Frame 13639093216": {},
        ExperienceResponsive: {
          width: "1280px",
          padding: "50px 240px 50px 240px",
        },
      },
    },
    {
      variantValues: { breakpoint: "xxl" },
      overrides: {
        Experience: { fontSize: "40px" },
        "Frame 445": {},
        image39093222: {},
        "Frame 449": {},
        iOS39093224: { fontSize: "24px" },
        "Developed a mobile telemetry app for the iPhone - this can automatically track journeys, detect rapid acceleration & deceleration, speeding and other driving behaviour.39093225":
          { fontSize: "16px", letterSpacing: "0.01px" },
        "Frame 458": {},
        "Frame 37439093220": {},
        image39093228: {},
        "Frame 469": {},
        OpenAI39093230: { fontSize: "24px" },
        "Experience of using OpenAI\u2019s models and have wrote articles on how developers can utilise Large Language Models for their day-to-day work.39093231":
          { fontSize: "16px", letterSpacing: "0.01px" },
        "Frame 468": {},
        "Frame 37339093226": { display: "flex" },
        "Frame 13639093219": {},
        image39093235: {},
        "Frame 450": {},
        iOS39093237: {},
        "Developed a mobile telemetry app for the iPhone - this can automatically track journeys, detect rapid acceleration & deceleration, speeding and other driving behaviour.39093238":
          {},
        "Frame 459": {},
        "Frame 37439093233": {},
        image39093241: {},
        "Frame 451": {},
        OpenAI39093243: {},
        "Experience of using OpenAI\u2019s models and have wrote articles on how developers can utilise Large Language Models for their day-to-day work.39093244":
          { fontSize: "12px" },
        "Frame 460": {},
        "Frame 37339093239": { display: "none" },
        "Frame 446": { display: "none" },
        image39093248: {},
        "Frame 452": {},
        AWS39093250: { fontSize: "24px" },
        "Architected multiple projects to use AWS services such as Rekognition, Lambdas and QuickSight - to name a few. Achieved the Developer Associate certification from AWS.39093251":
          { fontSize: "16px", letterSpacing: "0.01px" },
        "Frame 461": {},
        "Frame 37439093246": {},
        image39093254: {},
        "Frame 470": {},
        Azure39093256: { fontSize: "24px" },
        "Designed and developed a solution to use Azure\u2019s Data Factory service, which is now a key product for the company and it\u2019s customers.39093257":
          { fontSize: "16px", letterSpacing: "0.01px" },
        "Frame 471": {},
        "Frame 37339093252": { display: "flex" },
        "Frame 393": {},
        image39093261: {},
        "Frame 453": {},
        AWS39093263: {},
        "Architected multiple projects to use AWS services such as Rekognition, Lambdas and QuickSight - to name a few. Achieved the Developer Associate certification from AWS.39093264":
          {},
        "Frame 462": {},
        "Frame 37439093259": {},
        image39093267: {},
        "Frame 454": {},
        Azure39093269: {},
        "Designed and developed a solution to use Azure\u2019s Data Factory service, which is now a key product for the company and it\u2019s customers.39093270":
          { fontSize: "12px" },
        "Frame 463": {},
        "Frame 37339093265": {},
        "Frame 447": { display: "none" },
        image39093274: {},
        "Frame 455": {},
        Python39093276: { fontSize: "24px" },
        "Extensive experience of using Python, which spans a wide range of projects - from Computer Vision to analysing satellite imagery.39093277":
          { fontSize: "16px", letterSpacing: "0.01px" },
        "Frame 464": {},
        "Frame 37439093272": {},
        image39093280: {},
        "Frame 472": {},
        "C# and Xamarin39093282": { fontSize: "24px" },
        "Proficient in using C# - spent over a year developing safety software for power plants. Developed a mobile app for Foyle Search & Rescue, which allows them to more effectively search for missing people.39093283":
          { fontSize: "16px", letterSpacing: "0.01px" },
        "Frame 473": {},
        "Frame 37339093278": { display: "flex" },
        "Frame 394": {},
        image39093287: {},
        "Frame 456": {},
        Python39093289: {},
        "Extensive experience of using Python, which spans a wide range of projects - from Computer Vision to analysing satellite imagery.39093290":
          {},
        "Frame 465": {},
        "Frame 37439093285": {},
        image39093293: {},
        "Frame 457": {},
        "C# and Xamarin39093295": {},
        "Proficient in using C# - spent over a year developing safety software for power plants. Developed a mobile app for Foyle Search & Rescue, which allows them to more effectively search for missing people.39093296":
          { fontSize: "12px" },
        "Frame 466": {},
        "Frame 37339093291": { display: "none" },
        "Frame 448": { display: "none" },
        "Frame 13639093216": {},
        ExperienceResponsive: {
          width: "1536px",
          padding: "50px 240px 50px 240px",
        },
      },
    },
    {
      variantValues: { breakpoint: "small" },
      overrides: {
        Experience: {},
        "Frame 445": {},
        image39093222: {},
        "Frame 449": {},
        iOS39093224: {},
        "Developed a mobile telemetry app for the iPhone - this can automatically track journeys, detect rapid acceleration & deceleration, speeding and other driving behaviour.39093225":
          {},
        "Frame 458": {},
        "Frame 37439093220": {},
        image39093228: {},
        "Frame 469": {},
        OpenAI39093230: {},
        "Experience of using OpenAI\u2019s models and have wrote articles on how developers can utilise Large Language Models for their day-to-day work.39093231":
          {},
        "Frame 468": {},
        "Frame 37339093226": {},
        "Frame 13639093219": {},
        image39093235: {},
        "Frame 450": {},
        iOS39093237: {},
        "Developed a mobile telemetry app for the iPhone - this can automatically track journeys, detect rapid acceleration & deceleration, speeding and other driving behaviour.39093238":
          {},
        "Frame 459": {},
        "Frame 37439093233": {},
        image39093241: {},
        "Frame 451": {},
        OpenAI39093243: {},
        "Experience of using OpenAI\u2019s models and have wrote articles on how developers can utilise Large Language Models for their day-to-day work.39093244":
          {},
        "Frame 460": {},
        "Frame 37339093239": {},
        "Frame 446": {},
        image39093248: {},
        "Frame 452": {},
        AWS39093250: {},
        "Architected multiple projects to use AWS services such as Rekognition, Lambdas and QuickSight - to name a few. Achieved the Developer Associate certification from AWS.39093251":
          {},
        "Frame 461": {},
        "Frame 37439093246": {},
        image39093254: {},
        "Frame 470": {},
        Azure39093256: {},
        "Designed and developed a solution to use Azure\u2019s Data Factory service, which is now a key product for the company and it\u2019s customers.39093257":
          {},
        "Frame 471": {},
        "Frame 37339093252": {},
        "Frame 393": {},
        image39093261: {},
        "Frame 453": {},
        AWS39093263: {},
        "Architected multiple projects to use AWS services such as Rekognition, Lambdas and QuickSight - to name a few. Achieved the Developer Associate certification from AWS.39093264":
          {},
        "Frame 462": {},
        "Frame 37439093259": {},
        image39093267: {},
        "Frame 454": {},
        Azure39093269: {},
        "Designed and developed a solution to use Azure\u2019s Data Factory service, which is now a key product for the company and it\u2019s customers.39093270":
          {},
        "Frame 463": {},
        "Frame 37339093265": {},
        "Frame 447": {},
        image39093274: {},
        "Frame 455": {},
        Python39093276: {},
        "Extensive experience of using Python, which spans a wide range of projects - from Computer Vision to analysing satellite imagery.39093277":
          {},
        "Frame 464": {},
        "Frame 37439093272": {},
        image39093280: {},
        "Frame 472": {},
        "C# and Xamarin39093282": {},
        "Proficient in using C# - spent over a year developing safety software for power plants. Developed a mobile app for Foyle Search & Rescue, which allows them to more effectively search for missing people.39093283":
          {},
        "Frame 473": {},
        "Frame 37339093278": {},
        "Frame 394": {},
        image39093287: {},
        "Frame 456": {},
        Python39093289: {},
        "Extensive experience of using Python, which spans a wide range of projects - from Computer Vision to analysing satellite imagery.39093290":
          {},
        "Frame 465": {},
        "Frame 37439093285": {},
        image39093293: {},
        "Frame 457": {},
        "C# and Xamarin39093295": {},
        "Proficient in using C# - spent over a year developing safety software for power plants. Developed a mobile app for Foyle Search & Rescue, which allows them to more effectively search for missing people.39093296":
          {},
        "Frame 466": {},
        "Frame 37339093291": {},
        "Frame 448": {},
        "Frame 13639093216": {},
        ExperienceResponsive: { width: "480px" },
      },
    },
    {
      variantValues: { breakpoint: "base" },
      overrides: {
        Experience: {},
        "Frame 445": {},
        image39093222: {},
        "Frame 449": {},
        iOS39093224: {},
        "Developed a mobile telemetry app for the iPhone - this can automatically track journeys, detect rapid acceleration & deceleration, speeding and other driving behaviour.39093225":
          {},
        "Frame 458": {},
        "Frame 37439093220": {},
        image39093228: {},
        "Frame 469": {},
        OpenAI39093230: {},
        "Experience of using OpenAI\u2019s models and have wrote articles on how developers can utilise Large Language Models for their day-to-day work.39093231":
          {},
        "Frame 468": {},
        "Frame 37339093226": {},
        "Frame 13639093219": {},
        image39093235: {},
        "Frame 450": {},
        iOS39093237: {},
        "Developed a mobile telemetry app for the iPhone - this can automatically track journeys, detect rapid acceleration & deceleration, speeding and other driving behaviour.39093238":
          {},
        "Frame 459": {},
        "Frame 37439093233": {},
        image39093241: {},
        "Frame 451": {},
        OpenAI39093243: {},
        "Experience of using OpenAI\u2019s models and have wrote articles on how developers can utilise Large Language Models for their day-to-day work.39093244":
          {},
        "Frame 460": {},
        "Frame 37339093239": {},
        "Frame 446": {},
        image39093248: {},
        "Frame 452": {},
        AWS39093250: {},
        "Architected multiple projects to use AWS services such as Rekognition, Lambdas and QuickSight - to name a few. Achieved the Developer Associate certification from AWS.39093251":
          {},
        "Frame 461": {},
        "Frame 37439093246": {},
        image39093254: {},
        "Frame 470": {},
        Azure39093256: {},
        "Designed and developed a solution to use Azure\u2019s Data Factory service, which is now a key product for the company and it\u2019s customers.39093257":
          {},
        "Frame 471": {},
        "Frame 37339093252": {},
        "Frame 393": {},
        image39093261: {},
        "Frame 453": {},
        AWS39093263: {},
        "Architected multiple projects to use AWS services such as Rekognition, Lambdas and QuickSight - to name a few. Achieved the Developer Associate certification from AWS.39093264":
          {},
        "Frame 462": {},
        "Frame 37439093259": {},
        image39093267: {},
        "Frame 454": {},
        Azure39093269: {},
        "Designed and developed a solution to use Azure\u2019s Data Factory service, which is now a key product for the company and it\u2019s customers.39093270":
          {},
        "Frame 463": {},
        "Frame 37339093265": {},
        "Frame 447": {},
        image39093274: {},
        "Frame 455": {},
        Python39093276: {},
        "Extensive experience of using Python, which spans a wide range of projects - from Computer Vision to analysing satellite imagery.39093277":
          {},
        "Frame 464": {},
        "Frame 37439093272": {},
        image39093280: {},
        "Frame 472": {},
        "C# and Xamarin39093282": {},
        "Proficient in using C# - spent over a year developing safety software for power plants. Developed a mobile app for Foyle Search & Rescue, which allows them to more effectively search for missing people.39093283":
          {},
        "Frame 473": {},
        "Frame 37339093278": {},
        "Frame 394": {},
        image39093287: {},
        "Frame 456": {},
        Python39093289: {},
        "Extensive experience of using Python, which spans a wide range of projects - from Computer Vision to analysing satellite imagery.39093290":
          {},
        "Frame 465": {},
        "Frame 37439093285": {},
        image39093293: {},
        "Frame 457": {},
        "C# and Xamarin39093295": {},
        "Proficient in using C# - spent over a year developing safety software for power plants. Developed a mobile app for Foyle Search & Rescue, which allows them to more effectively search for missing people.39093296":
          {},
        "Frame 466": {},
        "Frame 37339093291": {},
        "Frame 448": {},
        "Frame 13639093216": {},
        ExperienceResponsive: {},
      },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "base",
    small: "small",
    medium: "medium",
    large: "large",
    xl: "xl",
    xxl: "xxl",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  return (
    <Flex
      gap="0"
      direction="column"
      width="380px"
      height="unset"
      justifyContent="center"
      alignItems="center"
      position="relative"
      padding="50px 50px 50px 50px"
      backgroundColor="rgba(239,240,240,1)"
      display="flex"
      {...getOverrideProps(overrides, "ExperienceResponsive")}
      {...rest}
    >
      <Flex
        gap="24px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Frame 13639093216")}
      >
        <Flex
          gap="10px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="20px 0px 20px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 445")}
        >
          <Text
            fontFamily="Inter"
            fontSize="32px"
            fontWeight="600"
            color="rgba(0,0,0,1)"
            lineHeight="20px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            grow="1"
            shrink="1"
            basis="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Experience"
            {...getOverrideProps(overrides, "Experience")}
          ></Text>
        </Flex>
        <Flex
          gap="24px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="10px 0px 10px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 13639093219")}
        >
          <Flex
            gap="0"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            grow="1"
            shrink="1"
            basis="0"
            alignSelf="stretch"
            position="relative"
            borderRadius="20px"
            padding="30px 40px 30px 40px"
            backgroundColor="rgba(255,255,255,1)"
            display="flex"
            {...getOverrideProps(overrides, "Frame 37439093220")}
          >
            <Flex
              gap="10px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 449")}
            >
              <Image
                width="140px"
                height="83.24px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                objectFit="cover"
                src="https://personal-website-liam.s3.eu-west-1.amazonaws.com/public/apple-logo.png"
                {...getOverrideProps(overrides, "image39093222")}
              ></Image>
            </Flex>
            <Flex
              gap="16px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="20px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 458")}
            >
              <Text
                fontFamily="Inter"
                fontSize="20px"
                fontWeight="600"
                color="rgba(0,0,0,1)"
                lineHeight="30px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="iOS"
                {...getOverrideProps(overrides, "iOS39093224")}
              ></Text>
              <Text
                fontFamily="Inter"
                fontSize="13px"
                fontWeight="400"
                color="rgba(0,0,0,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Developed a mobile telemetry app for the iPhone - this can automatically track journeys, detect rapid acceleration & deceleration, speeding and other driving behaviour."
                {...getOverrideProps(
                  overrides,
                  "Developed a mobile telemetry app for the iPhone - this can automatically track journeys, detect rapid acceleration & deceleration, speeding and other driving behaviour.39093225"
                )}
              ></Text>
            </Flex>
          </Flex>
          <Flex
            gap="0"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            grow="1"
            shrink="1"
            basis="0"
            alignSelf="stretch"
            position="relative"
            borderRadius="20px"
            padding="30px 40px 30px 40px"
            backgroundColor="rgba(255,255,255,1)"
            display="none"
            {...getOverrideProps(overrides, "Frame 37339093226")}
          >
            <Flex
              gap="10px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 469")}
            >
              <Image
                width="80px"
                height="80px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                objectFit="cover"
                src="https://personal-website-liam.s3.eu-west-1.amazonaws.com/public/openai-logomark.png"
                {...getOverrideProps(overrides, "image39093228")}
              ></Image>
            </Flex>
            <Flex
              gap="16px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="20px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 468")}
            >
              <Text
                fontFamily="Inter"
                fontSize="20px"
                fontWeight="600"
                color="rgba(0,0,0,1)"
                lineHeight="30px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="OpenAI"
                {...getOverrideProps(overrides, "OpenAI39093230")}
              ></Text>
              <Text
                fontFamily="Inter"
                fontSize="12px"
                fontWeight="400"
                color="rgba(0,0,0,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Experience of using OpenAI’s models and have wrote articles on how developers can utilise Large Language Models for their day-to-day work."
                {...getOverrideProps(
                  overrides,
                  "Experience of using OpenAI\u2019s models and have wrote articles on how developers can utilise Large Language Models for their day-to-day work.39093231"
                )}
              ></Text>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          gap="24px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="10px 0px 10px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 446")}
        >
          <Flex
            gap="16px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            grow="1"
            shrink="1"
            basis="0"
            position="relative"
            borderRadius="20px"
            padding="60px 40px 40px 40px"
            backgroundColor="rgba(255,255,255,1)"
            display="none"
            {...getOverrideProps(overrides, "Frame 37439093233")}
          >
            <Flex
              gap="10px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 450")}
            >
              <Image
                width="200px"
                height="118.92px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                objectFit="cover"
                {...getOverrideProps(overrides, "image39093235")}
              ></Image>
            </Flex>
            <View
              width="200px"
              height="262px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 459")}
            >
              <Text
                fontFamily="Inter"
                fontSize="24px"
                fontWeight="600"
                color="rgba(0,0,0,1)"
                lineHeight="30px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="200px"
                height="unset"
                gap="unset"
                alignItems="unset"
                position="absolute"
                top="0px"
                left="0px"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="iOS"
                {...getOverrideProps(overrides, "iOS39093237")}
              ></Text>
              <Text
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(0,0,0,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.01px"
                width="200px"
                height="unset"
                gap="unset"
                alignItems="unset"
                position="absolute"
                top="46px"
                left="0px"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Developed a mobile telemetry app for the iPhone - this can automatically track journeys, detect rapid acceleration & deceleration, speeding and other driving behaviour."
                {...getOverrideProps(
                  overrides,
                  "Developed a mobile telemetry app for the iPhone - this can automatically track journeys, detect rapid acceleration & deceleration, speeding and other driving behaviour.39093238"
                )}
              ></Text>
            </View>
          </Flex>
          <Flex
            gap="0"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            grow="1"
            shrink="1"
            basis="0"
            alignSelf="stretch"
            position="relative"
            borderRadius="20px"
            padding="30px 40px 30px 40px"
            backgroundColor="rgba(255,255,255,1)"
            display="flex"
            {...getOverrideProps(overrides, "Frame 37339093239")}
          >
            <Flex
              gap="10px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 451")}
            >
              <Image
                width="80px"
                height="80px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                objectFit="cover"
                src="https://personal-website-liam.s3.eu-west-1.amazonaws.com/public/openai-logomark.png"
                {...getOverrideProps(overrides, "image39093241")}
              ></Image>
            </Flex>
            <Flex
              gap="16px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="20px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 460")}
            >
              <Text
                fontFamily="Inter"
                fontSize="20px"
                fontWeight="600"
                color="rgba(0,0,0,1)"
                lineHeight="30px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="OpenAI"
                {...getOverrideProps(overrides, "OpenAI39093243")}
              ></Text>
              <Text
                fontFamily="Inter"
                fontSize="13px"
                fontWeight="400"
                color="rgba(0,0,0,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Experience of using OpenAI’s models and have wrote articles on how developers can utilise Large Language Models for their day-to-day work."
                {...getOverrideProps(
                  overrides,
                  "Experience of using OpenAI\u2019s models and have wrote articles on how developers can utilise Large Language Models for their day-to-day work.39093244"
                )}
              ></Text>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          gap="24px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 393")}
        >
          <Flex
            gap="0"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            grow="1"
            shrink="1"
            basis="0"
            alignSelf="stretch"
            position="relative"
            borderRadius="20px"
            padding="30px 40px 30px 40px"
            backgroundColor="rgba(255,255,255,1)"
            display="flex"
            {...getOverrideProps(overrides, "Frame 37439093246")}
          >
            <Flex
              gap="10px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="10px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 452")}
            >
              <Image
                width="100px"
                height="59.2px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                objectFit="cover"
                src="https://personal-website-liam.s3.eu-west-1.amazonaws.com/public/aws-logo.png"
                {...getOverrideProps(overrides, "image39093248")}
              ></Image>
            </Flex>
            <Flex
              gap="16px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="20px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 461")}
            >
              <Text
                fontFamily="Inter"
                fontSize="20px"
                fontWeight="600"
                color="rgba(13,26,38,1)"
                lineHeight="30px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="AWS"
                {...getOverrideProps(overrides, "AWS39093250")}
              ></Text>
              <Text
                fontFamily="Inter"
                fontSize="13px"
                fontWeight="400"
                color="rgba(0,0,0,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Architected multiple projects to use AWS services such as Rekognition, Lambdas and QuickSight - to name a few. Achieved the Developer Associate certification from AWS."
                {...getOverrideProps(
                  overrides,
                  "Architected multiple projects to use AWS services such as Rekognition, Lambdas and QuickSight - to name a few. Achieved the Developer Associate certification from AWS.39093251"
                )}
              ></Text>
            </Flex>
          </Flex>
          <Flex
            gap="16px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            grow="1"
            shrink="1"
            basis="0"
            alignSelf="stretch"
            position="relative"
            borderRadius="20px"
            padding="30px 40px 30px 40px"
            backgroundColor="rgba(255,255,255,1)"
            display="none"
            {...getOverrideProps(overrides, "Frame 37339093252")}
          >
            <View
              width="140px"
              height="83.24px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 470")}
            >
              <Image
                width="140px"
                height="83.24px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0px"
                left="0px"
                padding="0px 0px 0px 0px"
                objectFit="cover"
                src="https://personal-website-liam.s3.eu-west-1.amazonaws.com/public/azure-logo.png"
                {...getOverrideProps(overrides, "image39093254")}
              ></Image>
            </View>
            <Flex
              gap="16px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="20px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 471")}
            >
              <Text
                fontFamily="Inter"
                fontSize="20px"
                fontWeight="600"
                color="rgba(13,26,38,1)"
                lineHeight="30px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Azure"
                {...getOverrideProps(overrides, "Azure39093256")}
              ></Text>
              <Text
                fontFamily="Inter"
                fontSize="12px"
                fontWeight="400"
                color="rgba(0,0,0,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Designed and developed a solution to use Azure’s Data Factory service, which is now a key product for the company and it’s customers."
                {...getOverrideProps(
                  overrides,
                  "Designed and developed a solution to use Azure\u2019s Data Factory service, which is now a key product for the company and it\u2019s customers.39093257"
                )}
              ></Text>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          gap="24px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 447")}
        >
          <Flex
            gap="16px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            grow="1"
            shrink="1"
            basis="0"
            alignSelf="stretch"
            position="relative"
            borderRadius="20px"
            padding="60px 40px 40px 40px"
            backgroundColor="rgba(255,255,255,1)"
            display="none"
            {...getOverrideProps(overrides, "Frame 37439093259")}
          >
            <Flex
              gap="10px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 453")}
            >
              <Image
                width="174px"
                height="103px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                objectFit="cover"
                {...getOverrideProps(overrides, "image39093261")}
              ></Image>
            </Flex>
            <View
              width="200px"
              height="238px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 462")}
            >
              <Text
                fontFamily="Inter"
                fontSize="24px"
                fontWeight="600"
                color="rgba(13,26,38,1)"
                lineHeight="30px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="200px"
                height="unset"
                gap="unset"
                alignItems="unset"
                position="absolute"
                top="0px"
                left="0px"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="AWS"
                {...getOverrideProps(overrides, "AWS39093263")}
              ></Text>
              <Text
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(0,0,0,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.01px"
                width="200px"
                height="unset"
                gap="unset"
                alignItems="unset"
                position="absolute"
                top="46px"
                left="0px"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Architected multiple projects to use AWS services such as Rekognition, Lambdas and QuickSight - to name a few. Achieved the Developer Associate certification from AWS."
                {...getOverrideProps(
                  overrides,
                  "Architected multiple projects to use AWS services such as Rekognition, Lambdas and QuickSight - to name a few. Achieved the Developer Associate certification from AWS.39093264"
                )}
              ></Text>
            </View>
          </Flex>
          <Flex
            gap="0"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            grow="1"
            shrink="1"
            basis="0"
            alignSelf="stretch"
            position="relative"
            borderRadius="20px"
            padding="30px 40px 30px 40px"
            backgroundColor="rgba(255,255,255,1)"
            display="flex"
            {...getOverrideProps(overrides, "Frame 37339093265")}
          >
            <Flex
              gap="10px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 454")}
            >
              <Image
                width="140px"
                height="83.24px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                objectFit="cover"
                src="https://www.i-finity.co.uk/media/qs4nkxbk/azure-new.png?width=1200&height=630"
                {...getOverrideProps(overrides, "image39093267")}
              ></Image>
            </Flex>
            <Flex
              gap="16px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="20px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 463")}
            >
              <Text
                fontFamily="Inter"
                fontSize="20px"
                fontWeight="600"
                color="rgba(13,26,38,1)"
                lineHeight="30px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Azure"
                {...getOverrideProps(overrides, "Azure39093269")}
              ></Text>
              <Text
                fontFamily="Inter"
                fontSize="13px"
                fontWeight="400"
                color="rgba(0,0,0,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Designed and developed a solution to use Azure’s Data Factory service, which is now a key product for the company and it’s customers."
                {...getOverrideProps(
                  overrides,
                  "Designed and developed a solution to use Azure\u2019s Data Factory service, which is now a key product for the company and it\u2019s customers.39093270"
                )}
              ></Text>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          gap="24px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 394")}
        >
          <Flex
            gap="0"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            grow="1"
            shrink="1"
            basis="0"
            alignSelf="stretch"
            position="relative"
            borderRadius="20px"
            padding="30px 40px 30px 40px"
            backgroundColor="rgba(255,255,255,1)"
            display="flex"
            {...getOverrideProps(overrides, "Frame 37439093272")}
          >
            <Flex
              gap="10px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 455")}
            >
              <Image
                width="80px"
                height="80px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                objectFit="cover"
                src="https://personal-website-liam.s3.eu-west-1.amazonaws.com/public/python-logo.png"
                {...getOverrideProps(overrides, "image39093274")}
              ></Image>
            </Flex>
            <Flex
              gap="16px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="20px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 464")}
            >
              <Text
                fontFamily="Inter"
                fontSize="20px"
                fontWeight="600"
                color="rgba(13,26,38,1)"
                lineHeight="30px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Python"
                {...getOverrideProps(overrides, "Python39093276")}
              ></Text>
              <Text
                fontFamily="Inter"
                fontSize="13px"
                fontWeight="400"
                color="rgba(0,0,0,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Extensive experience of using Python, which spans a wide range of projects - from Computer Vision to analysing satellite imagery."
                {...getOverrideProps(
                  overrides,
                  "Extensive experience of using Python, which spans a wide range of projects - from Computer Vision to analysing satellite imagery.39093277"
                )}
              ></Text>
            </Flex>
          </Flex>
          <Flex
            gap="0"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            grow="1"
            shrink="1"
            basis="0"
            alignSelf="stretch"
            position="relative"
            borderRadius="20px"
            padding="30px 40px 30px 40px"
            backgroundColor="rgba(255,255,255,1)"
            display="none"
            {...getOverrideProps(overrides, "Frame 37339093278")}
          >
            <View
              width="140px"
              height="83.24px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 472")}
            >
              <Image
                width="140px"
                height="83.24px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0px"
                left="0px"
                padding="0px 0px 0px 0px"
                objectFit="cover"
                src="https://personal-website-liam.s3.eu-west-1.amazonaws.com/public/c-sharp-logo.png"
                {...getOverrideProps(overrides, "image39093280")}
              ></Image>
            </View>
            <Flex
              gap="16px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="20px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 473")}
            >
              <Text
                fontFamily="Inter"
                fontSize="20px"
                fontWeight="600"
                color="rgba(13,26,38,1)"
                lineHeight="30px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="C# and Xamarin"
                {...getOverrideProps(overrides, "C# and Xamarin39093282")}
              ></Text>
              <Text
                fontFamily="Inter"
                fontSize="12px"
                fontWeight="400"
                color="rgba(0,0,0,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Proficient in using C# - spent over a year developing safety software for power plants. Developed a mobile app for Foyle Search & Rescue, which allows them to more effectively search for missing people."
                {...getOverrideProps(
                  overrides,
                  "Proficient in using C# - spent over a year developing safety software for power plants. Developed a mobile app for Foyle Search & Rescue, which allows them to more effectively search for missing people.39093283"
                )}
              ></Text>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          gap="24px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 448")}
        >
          <Flex
            gap="16px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            grow="1"
            shrink="1"
            basis="0"
            alignSelf="stretch"
            position="relative"
            borderRadius="20px"
            padding="60px 40px 40px 40px"
            backgroundColor="rgba(255,255,255,1)"
            display="none"
            {...getOverrideProps(overrides, "Frame 37439093285")}
          >
            <Flex
              gap="10px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 456")}
            >
              <Image
                width="200px"
                height="118.92px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                objectFit="cover"
                src="https://personal-website-liam.s3.eu-west-1.amazonaws.com/public/python-logo.png"
                {...getOverrideProps(overrides, "image39093287")}
              ></Image>
            </Flex>
            <View
              width="48px"
              height="724px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 465")}
            >
              <Text
                fontFamily="Inter"
                fontSize="24px"
                fontWeight="600"
                color="rgba(13,26,38,1)"
                lineHeight="30px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="48px"
                height="unset"
                gap="unset"
                alignItems="unset"
                position="absolute"
                top="0px"
                left="0px"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Python"
                {...getOverrideProps(overrides, "Python39093289")}
              ></Text>
              <Text
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(0,0,0,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.01px"
                width="48px"
                height="unset"
                gap="unset"
                alignItems="unset"
                position="absolute"
                top="76px"
                left="0px"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Extensive experience of using Python, which spans a wide range of projects - from Computer Vision to analysing satellite imagery."
                {...getOverrideProps(
                  overrides,
                  "Extensive experience of using Python, which spans a wide range of projects - from Computer Vision to analysing satellite imagery.39093290"
                )}
              ></Text>
            </View>
          </Flex>
          <Flex
            gap="0"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            grow="1"
            shrink="1"
            basis="0"
            alignSelf="stretch"
            position="relative"
            borderRadius="20px"
            padding="30px 40px 30px 40px"
            backgroundColor="rgba(255,255,255,1)"
            display="flex"
            {...getOverrideProps(overrides, "Frame 37339093291")}
          >
            <Flex
              gap="10px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 457")}
            >
              <Image
                width="140px"
                height="83.24px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                objectFit="cover"
                src="https://upload.wikimedia.org/wikipedia/commons/4/4f/Csharp_Logo.png"
                {...getOverrideProps(overrides, "image39093293")}
              ></Image>
            </Flex>
            <Flex
              gap="16px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="20px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 466")}
            >
              <Text
                fontFamily="Inter"
                fontSize="20px"
                fontWeight="600"
                color="rgba(13,26,38,1)"
                lineHeight="30px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="C# and Xamarin"
                {...getOverrideProps(overrides, "C# and Xamarin39093295")}
              ></Text>
              <Text
                fontFamily="Inter"
                fontSize="13px"
                fontWeight="400"
                color="rgba(0,0,0,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Proficient in using C# - spent over a year developing safety software for power plants. Developed a mobile app for Foyle Search & Rescue, which allows them to more effectively search for missing people."
                {...getOverrideProps(
                  overrides,
                  "Proficient in using C# - spent over a year developing safety software for power plants. Developed a mobile app for Foyle Search & Rescue, which allows them to more effectively search for missing people.39093296"
                )}
              ></Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
