import React, { useEffect } from 'react';
import { NavigationBarResponsive, WorkInProgressResponsive, BlogFooterResponsive, MicrosoftResponsive, MicrosoftFooterResponsive }  from '../ui-components';

const MicrosoftPage = () => {
    useEffect(() => {
        document.title = 'Microsoft\'s UK Office - Liam McCormick';
    }, []);

    return (
        <div>
            <NavigationBarResponsive width={"100%"}/>
            <MicrosoftResponsive width={"100%"}/>
            <MicrosoftFooterResponsive width={"100%"}/>
        </div>
    );
}

export default MicrosoftPage;